import { SchedulesType } from '../../../entities/Schedules/types';

export const saveCurrentScheduleChanges = (
  changes: SchedulesType[],
  saveAction: (data: Array<any>) => any,
) => {
  const collectionsdata: Array<any> = [];

  changes.forEach((item) => {
    if (item.isCreated) {
      collectionsdata.push({ value: item.value, description: item.description } as any);
    } else if (item.isDeleted) {
      collectionsdata.push({ ...item, _destroy: '1' } as any);
    } else if (item.isEdited) {
      collectionsdata.push({ ...item } as any);
    }
  });

  collectionsdata.length > 0 && saveAction(collectionsdata);
};

export const convertToSchedulesType = ({
  id,
  isCreated,
  isEdited,
  isDeleted,
  value,
  description,
} : SchedulesType) => {
  return {
    id,
    isCreated,
    isEdited,
    isDeleted,
    value,
    description,
  };
};

