import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import selectEntities from '../../modules/entities/selectors';
import InsulationInstanceSchema from './schema';
import EK from '../keys';
import { formValueSelector } from 'redux-form/immutable';
import { selectSidebarIsFiltering } from '../../routes/Dashboard/selectors';
import { newInsulationInstanceRows } from './constants';

export const selectNormalizedInsulationInstances = () => createSelector(
  selectEntities(),
  entities => entities.get('insulationInstances')
);

export const selectCurrentInsulationInstances = (familyId) => createSelector(
  selectNormalizedInsulationInstances(),
  (instances) => instances.toList().filter(item => item.insulationFamilyId == familyId)
);
export const selectInsulationInstanceCount = (familyId) => createSelector(
  selectCurrentInsulationInstances(familyId),
  (instances) => instances.size
);

const insulationInstanceFilterForm = formValueSelector(`filters.${EK.INSULATION_INSTANCES.state}`);

export const selectCurrentFilteredInsulationInstances = (familyId) => createSelector(
  selectCurrentInsulationInstances(familyId),
  selectSidebarIsFiltering(),
  state => insulationInstanceFilterForm(state, 'sizeProperty', 'radius'),
  selectEntities(),
  (bends, isFiltering, query, entities) => {
    const instances = !isFiltering ? bends : bends.filter(instance => instance.doesMatchQuery(query));

    return denormalize(instances, [InsulationInstanceSchema], entities.delete(EK.INSULATIONS.state));
  }
);
export const selectCurrentInsulationInstancesForPage = (familyId) => createSelector(
  selectCurrentFilteredInsulationInstances(familyId),
  selectSidebarIsFiltering(),
  (instances, isFiltering) => isFiltering ? instances.toArray() : instances.toArray().concat(newInsulationInstanceRows)
);