import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../entities/keys';

import selectEntities from '../../modules/entities/selectors';

import { selectIsShowingArchived } from '../../modules/query/selectors.js';

import { selectCurrentPipeId } from '../../routes/PipesEditSwitch/selectors.js';

import { selectSidebarIsFiltering } from '../../routes/Dashboard/selectors.js';

const bendFilterForm = formValueSelector(`filters.${EK.BENDS.state}`);

export const selectNormalizedBendFamilies = () =>  createSelector(
  selectEntities(),
  entities => entities.get('bendFamilies')
);
export const selectNormalizedBendFamiliesList = () => createSelector(
  selectNormalizedBendFamilies(),
  bendFamilies => bendFamilies.toList()
);

export const selectCurrentPipesBendsCount = () => createSelector(
  selectCurrentPipeId(),
  selectNormalizedBendFamiliesList(),
  (pipeId, bends) => bends
    .filter(bend => bend.pipeFamilyId === pipeId)
    .reduce(({ unarchived, total }, bend) => ({
      unarchived: !bend.archivedFlag ? unarchived + 1 : unarchived,
      total: total + 1,
    }), {
      unarchived: 0,
      total: 0,
    })
);

export const selectCurrentFilteredPipesBends = () => createSelector(
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectCurrentPipeId(),
  selectNormalizedBendFamiliesList(),
  state => bendFilterForm(state, 'name'),
  (isFiltering, isShowingArchived, pipeId, bends, name) => isFiltering ?
    bends.filter(bend => (isShowingArchived || !bend.archivedFlag) && bend.pipeFamilyId === pipeId && bend.doesMatchQuery({ name })) :
    bends.filter(bend => !bend.archivedFlag && bend.pipeFamilyId === pipeId)
);

export const selectCurrentBendFamily = (bendFamilyId) => createSelector(
  selectEntities(),
  (entities) => entities.hasIn([EK.BENDS.state, bendFamilyId]) && entities.getIn([EK.BENDS.state, bendFamilyId])
);