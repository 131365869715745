import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import selectEntities from '../../modules/entities/selectors';
import BendSpringbackInstanceSchema from './schema';
import EK from '../keys';
import { formValueSelector } from 'redux-form/immutable';
import { selectSidebarIsFiltering } from '../../routes/Dashboard/selectors';
import { newBendSpringbackInstances } from './constants';

export const selectNormalizedBendSpringbackInstances = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.BEND_SPRINGBACK_INSTANCES.state)
);

export const selectCurrentBendSpringbackInstances = (springbackId) => createSelector(
  selectNormalizedBendSpringbackInstances(),
  (instances) => instances.toList().filter(instance => instance.bendSpringbackFamilyId == springbackId)
);

const springBackInstanceFilter = formValueSelector(`filters.${EK.BEND_SPRINGBACK_INSTANCES.state}`);

export const selectCurrentDenormalizedFilteredBendSpringBackInstances = (springbackId) => createSelector(
  selectCurrentBendSpringbackInstances(springbackId),
  selectSidebarIsFiltering(),
  state => springBackInstanceFilter(state, 'sizeProperty', 'angle'),
  selectEntities(),
  (bends, isFiltering, query, entities) => {
    const instances = !isFiltering ? bends : bends.toList().filter(instance => instance.doesMatchQuery(query));

    return denormalize(instances, [BendSpringbackInstanceSchema], entities.delete(EK.BEND_SPRINGBACKS.state)).toList();
  }
);
export const selectBendSpringbackInstanceCount = (springbackId) => createSelector(
  selectCurrentBendSpringbackInstances(springbackId),
  (instances) => instances && instances.size || 0
);

export const selectCurrentFilteredBendSpringbackInstances = (springbackId) => createSelector(
  selectSidebarIsFiltering(),
  selectCurrentDenormalizedFilteredBendSpringBackInstances(springbackId),
  (isFiltering, instances) =>
    isFiltering ? instances.toArray() : instances.toArray().concat(newBendSpringbackInstances)
);