import React, { useState, useEffect } from 'react';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// custom hooks
import { useOnCellEditComplete } from '../../../hooks/useOnCellEditComplete';

//Grid
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { rowStyles } from '../../../components/primeGrid/RowStyles';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

// cell editors/bodies
import EntityRowTools from '../../../components/common/EntityTools/RowStartTools';
import { Dropdown } from 'primereact/dropdown';
import Text from '../../../components/common/Text';
import { InputNumber } from 'primereact/inputnumber';
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import UnitValueCellRenderer from '../../../components/grid/UnitValueCellRenderer';
import { InputText } from 'primereact/inputtext';
import { ALLOWANCES, INSULATION_TYPE_OPTIONS } from '../../../entities/InsulationInstances/model';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

// Header imports
import Options from '../../templates/Structures/Options';
import Header from '../../templates/Structures/Header';
import { pluralize } from '../../../components/common/Header';
import Skeleton from 'react-loading-skeleton';
import Flex from '../../../components/common/Flex';
import { PipingModuleInsulationsEditPath } from '../../../paths';

//actions
import {
  processFetchIndividualInsulationFamily,
  processEditInsulationFamily,
  processArchiveInsulationFamilies,
  processUnarchiveInsulationFamilies,
} from '../../../entities/InsulationFamilies/actions';
import { processFetchAllSizes } from '../../../entities/Sizes/actions';
import { processSaveInsulationInstances } from '../../../entities/InsulationInstances/actions';
import { processFetchAllFittingCategories } from '../../../entities/FittingCategories/actions';
import { processFetchAllMaterials } from '../../../entities/Materials/actions';

// Bend Family Dialog
import { doRequiredFieldsExist, areFieldsValid } from '../Insulations/components';
import { VALIDATION_FIELDS } from '../../../entities/InsulationFamilies/model';
import { InsulationDialog } from '../../../containers/dialog/templates/InsulationDialog';

// misc.
import { getUnitLength } from '../../../entities/UnitSystems/model';
import { convertToInsulationInstanceType, saveCurrentIsulationInstanceChanges } from './actions';
import colors from '../../../assets/themes/base/colors';

// Selectors
import { selectPipingModulePermissionsAndState } from '../../Dashboard/selectors';
import { selectNormalizedSizes } from '../../../entities/Sizes/selectors';
import { selectSidebarIsFiltering } from '../../Dashboard/selectors';
import { selectCurrentInsulationFamily } from '../../../entities/InsulationFamilies/selectors';
import { selectCurrentInsulationInstancesForPage, selectInsulationInstanceCount } from '../../../entities/InsulationInstances/selectors';
import { selectMasterCatalogUnitSystem } from '../../../entities/PipingCatalogs/selectors';
import { selectInsulationMaterialsOptions } from '../../../entities/Materials/selectors';
import { selectNormalizedFittingCategories } from '../../../entities/FittingCategories/selectors';

const mapStateToProps = (familyId) => createSelector(
  selectPipingModulePermissionsAndState(),
  selectCurrentInsulationFamily(familyId),
  selectCurrentInsulationInstancesForPage(familyId),
  selectNormalizedSizes(),
  selectInsulationMaterialsOptions(),
  selectNormalizedFittingCategories(),
  selectInsulationInstanceCount(familyId),
  selectSidebarIsFiltering(),
  selectMasterCatalogUnitSystem(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    insulation,
    data,
    sizes,
    materials,
    fittingCategories,
    instanceCount,
    isFiltering,
    defaultUnitSystem,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || instanceCount == 0));
    return {
      ...rest,
      isLoading,
      data: !isLoading && data || [],
      editable: canCollaborate && hasValidLicense && insulation && !insulation.archivedFlag,
      insulation,
      insulationId: insulation && insulation.id || null,
      defaultUnitSystem: insulation?.unitSystem || defaultUnitSystem,
      instanceCount,
      canCollaborate,
      hasValidLicense,
      sizes: sizes?.toList().toArray() || [],
      materials: materials?.toList().toArray() || [],
      fittingCategories: fittingCategories?.toList().toArray() || [],
      isFiltering,
    };
  },
);

const contentStyle = { width: 'fit-content' };

function InsulationsEditGrid({ match }) {
  const reduxProps = useSelector(mapStateToProps(match.params.id));
  const dispatch = useDispatch();

  // dialog state
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [validation, setValidation] = useState(VALIDATION_FIELDS);
  const [editedFamily, setEditedFamily] = useState(reduxProps.insulation);



  // table state
  const [editedRows, resetEditedRows, currentEditingRow, isEditing, onBeforeCellEditShow, onCellChange, onCellEditComplete, handleRowAction, onDropdownComplete] = useOnCellEditComplete(reduxProps.data, convertToInsulationInstanceType, reduxProps.editable, true);

  //dialog methods
  const handleEditedDialogChange = (newValue, field) => {
    // called on every edit, needs to be made into a custom hook of some kind
    let _editedRow = { ...editedFamily };
    _editedRow[`${field}`] = newValue;
    setEditedFamily(_editedRow);
  };

  const saveInsulationAction = () => {
    // translated here to a format that can be sent to the api, replacing all object fields with their Id.
    setValidation(areFieldsValid(editedFamily));
    if (!doRequiredFieldsExist(editedFamily)) {
      return;
    }

    const editedRow = {
      id: editedFamily.id,
      name: editedFamily.name,
      description: editedFamily.description,
      unitSystem: editedFamily.unitSystem
    };

    dispatch(processEditInsulationFamily(reduxProps.insulation.id, editedRow));
    setIsDialogOpen(false);
  };

  //Cell Bodies
  const textValueBody = (rowdata, field, placeholder = '') => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToInsulationInstanceType(rowdata);

    return curRow && curRow[`${field}`] ?
      <Text >{curRow[`${field}`]}</Text> :
      <Text style={{ color: colors.gray[5], whiteSpace: 'nowrap' }} >{placeholder}</Text>;
  };

  const unitValueBody = (rowdata, field) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToInsulationInstanceType(rowdata);

    return (
      <UnitValueCellRenderer data={curRow[field] || ''} unitSystem={reduxProps.defaultUnitSystem} />
    );
  };

  const sBody = (rowdata, field, placeholder) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToInsulationInstanceType(rowdata);

    return curRow && curRow[field] ?
      <ComplexCellRenderer value={curRow[field]} isCapitalized={true} paddingLeft='0px' /> :
      <Text className='text-cell-body' style={{ color: colors.gray[5] }}>{placeholder}</Text>;
  };

  const fittingCatBody = (rowdata) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToInsulationInstanceType(rowdata);

    if(curRow.insulationType != 'fitting') {
      return (<>
        <Popup trigger={() => <Text style={{ color: colors.gray[5], whiteSpace: 'nowrap' }} >Fitting Category</Text>} position='bottom center' on={['hover']} {...{ contentStyle }} >
          Only Available to &apos;fitting&apos; Insulation Types
        </Popup>
      </>);
    }

    return curRow && curRow.fittingCategory ?
      <ComplexCellRenderer value={curRow.fittingCategory} isCapitalized={true} paddingLeft='0px' /> :
      <Text style={{ color: colors.gray[5], whiteSpace: 'nowrap' }} >Fitting Category</Text>;
  };

  // Cell Editors
  const unitNumberCellEditor = (field) =>
    <span className='p-input-icon-right' >
      <Text as='i' >{reduxProps.defaultUnitSystem.unitLength.abbreviation || ''}</Text>
      <InputNumber value={currentEditingRow && getUnitLength(currentEditingRow[field], reduxProps.defaultUnitSystem)} onChange={(e) => onCellChange(e.value, field)} minFractionDigits={1} />
    </span>;

  const textCellEditor = (field) =>
    <InputText value={currentEditingRow[field]} keyfilter={ALLOWANCES.STOCKNO} style={{ width: '100%' }} onChange={(e) => onCellChange(e.target.value, field)} />;

  const sizeDropdown = () => {
    return <Dropdown
      value={currentEditingRow && currentEditingRow.sizeProperty}
      options={reduxProps.sizes}
      optionLabel='name'
      filter={true}

      itemTemplate={(item) => ComplexCellRenderer({ value: item, isCapitalized: true })}
      valueTemplate={(item) => ComplexCellRenderer({ value: item, isCapitalized: true })}

      onChange={(e) => onDropdownComplete.current(e.value, 'sizeProperty', currentEditingRow)}
      style={{ width: '100%' }}
    />;
  };

  const fittingCatDropdown = () => {
    // fittingCategory can only be shown if insulationType == 'fitting'
    if(currentEditingRow.insulationType == 'fitting') {
      return (
        <Dropdown
          value={currentEditingRow.fittingCategory}
          options={reduxProps.fittingCategories}
          optionLabel='name'
          filter={true}

          itemTemplate={(item) => ComplexCellRenderer({ value: item, isCapitalized: true })}
          valueTemplate={(item) => ComplexCellRenderer({ value: item, isCapitalized: true })}

          onChange={(e) => onDropdownComplete.current(e.value, 'fittingCategory', currentEditingRow)}
          style={{ width: '100%' }}
        />
      );
    }
  };

  const insulationTypeDropdown = () =>
    <Dropdown
      value={currentEditingRow.insulationType}
      options={INSULATION_TYPE_OPTIONS}
      optionLabel='label'
      optionValue='value'
      filter={true}

      onChange={(e) => onDropdownComplete.current(e.value, 'insulationType', currentEditingRow)}
      style={{ width: '100%' }}
    />;

  const materialDropdown = () =>
    <Dropdown
      value={currentEditingRow.material}
      options={reduxProps.materials}
      optionLabel='name'
      filter={true}

      itemTemplate={(item) => ComplexCellRenderer({ value: item, isCapitalized: true })}
      valueTemplate={(item) => ComplexCellRenderer({ value: item, isCapitalized: true })}

      onChange={(e) => onDropdownComplete.current(e.value, 'material', currentEditingRow)}
      style={{ width: '100%' }}
    />;

  const getRowTool = (rowdata) => {
    const data = editedRows && editedRows.find(row => row.id == rowdata.id) || reduxProps.data.find((item) => item.id == rowdata.id);
    return (
      <EntityRowTools rowdata={data} handleRowAction={handleRowAction} />
    );
  };

  useEffect(() => {
    dispatch(processFetchIndividualInsulationFamily(match.params.id));
    dispatch(processFetchAllSizes());
    dispatch(processFetchAllFittingCategories());
    dispatch(processFetchAllMaterials());
  }, []);

  return (
    <>
      {!reduxProps.isLoading && <Flex flexDirection="row" mb={4} >
        <Header
          isLoading={reduxProps.isLoading}
          title={(reduxProps.insulation?.name) || PipingModuleInsulationsEditPath.defaultTitle}
          subtitle={reduxProps.isFiltering ?
            `Showing ${reduxProps.data.length}/${reduxProps.instanceCount} ${pluralize('filtered Insulation Instance', reduxProps.instanceCount)}` :
            `Showing ${reduxProps.instanceCount} ${pluralize('Insulation Instance', reduxProps.instanceCount)} Total`}
        />
        <Options
          updateEntities={() => saveCurrentIsulationInstanceChanges(
            editedRows,
            (data) => { dispatch(processSaveInsulationInstances(reduxProps.insulationId, data)); resetEditedRows(); },
            reduxProps.defaultUnitSystem
          )}
          isEditingGrid={isEditing}
          isLoading={reduxProps.isLoading}
          pendingValidChanges={editedRows.length > 0}

          editEntity={() => {setEditedFamily({id: reduxProps.insulation.id, name: reduxProps.insulation.name, description: reduxProps.insulation.description, unitSystem: reduxProps.insulation.unitSystem}); setIsDialogOpen(true);}}
          isArchived={reduxProps.insulation?.archivedFlag}
          archiveEntity={() => dispatch(processArchiveInsulationFamilies([reduxProps.insulationId]))}
          unarchiveEntity={() => dispatch(processUnarchiveInsulationFamilies([reduxProps.insulationId]))}

          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
          canFilter={true}
        />
      </Flex> || <Skeleton style={{ height: '2rem', marginBottom: '1rem' }} />
      }
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable
          reorderableColumns
          value={reduxProps.data}
          tableStyle={{ minWidth: '55rem' }}
          size='normal'
          editMode='cell'
          rowClassName={(data) => data && rowStyles(data, editedRows)}

          scrollable
          scrollHeight='flex'

          removableSort
        >
          <Column header='' style={{ width: '2%' }} body={(rowdata) => getRowTool(rowdata)} ></Column>
          <Column field='sizeProperty' header='Size' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => sizeDropdown()} body={(rowdata) => sBody(rowdata, 'sizeProperty', 'Select Size')} style={{ width: '16%' }} ></Column>
          <Column field='insulationType' header='Insulation Type' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => insulationTypeDropdown()} body={(rowdata) => textValueBody(rowdata, 'insulationType', 'Select Type')} style={{ width: '16%' }} ></Column>
          <Column field='material' header='Material' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => materialDropdown()} body={(rowdata) => sBody(rowdata, 'material', 'Select Material')} style={{ width: '16%' }} ></Column>
          <Column field='fittingCategory' header='Fitting Category' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => fittingCatDropdown()} body={(rowdata) => fittingCatBody(rowdata)} style={{ width: '16%' }} ></Column>
          <Column field='matlThickness' style={{ width: '16%' }} header='Thickness' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => unitNumberCellEditor('matlThickness')} onCellEditComplete={(e) => onCellEditComplete.current(e)} body={(rowdata) => unitValueBody(rowdata, 'matlThickness', '')} ></Column>
          <Column field='stockno' style={{ width: '16%' }} header='Stock No' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => textCellEditor('stockno')} onCellEditComplete={(e) => onCellEditComplete.current(e)} body={(rowdata) => textValueBody(rowdata, 'stockno', 'Stock No')} sortable ></Column>
        </DataTable>
      </Flex>
      <InsulationDialog
        currentEditingRow={editedFamily}
        editable={reduxProps.editable}
        getDialogHeader={() => `Edit ${reduxProps.insulation?.name || 'Insulation Family'}`}
        handleEditedRowChange={handleEditedDialogChange}
        isDialogOpen={isDialogOpen}
        saveAction={saveInsulationAction}
        cancelDialogs={() => setIsDialogOpen(false)}
        validation={validation}
        defaultUnitSystem={reduxProps.defaultUnitSystem}
      />
    </>);
}

export default withRouter(InsulationsEditGrid);