import { List } from 'immutable';
import pluralize from 'pluralize';

import EntityRecord, { FILTERS } from '../../modules/entities/model';

export const convertListToString = (list) =>
  `[${list.map((fam, i) => `${fam}`).toArray()}]`;

export const ALLOWANCES = {
  SPEC_NAME: /^(.{0,255})$/,
  MNEMONIC: /^(.{0,255})$/,
  LAYER_NAME: /^(.{0,255})$/,
};

export const VALIDATION_FIELDS = {
  name: true,
  appearance: true,
};

export default class Spec extends EntityRecord({
  specName: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  mnemonic: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  layerName: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  archivedFlag: {
    value: false,
  },
  appearance: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'appearanceId',
    filter: FILTERS.ENTITY,
  },
  prioritizedPipeFamilies: {
    value: null,
    handleCopy: true,
    handleSerialize: (entity) =>
      (entity.prioritizedPipeFamilies &&
        convertListToString(entity.prioritizedPipeFamilies)) ||
      null,
  },
  prioritizedFittingFamilies: {
    value: null,
    handleCopy: true,
    handleSerialize: (entity) =>
      (entity.prioritizedFittingFamilies &&
        convertListToString(entity.prioritizedFittingFamilies)) ||
      null,
  },
  specPipeInstances: {
    value: List(),
    isEntity: true,
    handleCopy: true,
    handleCopyDeep: true,
    handleCompare: true,
    handleCompareDeep: true,
    handleSerializeCollection: true,
    handleSerializeDeep: true,
    serializeOutputField: 'specPipeInstancesAttributes',
  },
  specFittingInstances: {
    value: List(),
    isEntity: true,
    handleCopy: true,
    handleCopyDeep: true,
    handleCompare: true,
    handleCompareDeep: true,
    handleSerializeCollection: true,
    handleSerializeDeep: true,
    serializeOutputField: 'specFittingInstancesAttributes',
  },
  defaultInsulationSpec: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'defaultInsulationSpecId',
    filter: FILTERS.ENTITY,
  },
}) {
  constructor({
    prioritizedPipeFamilies,
    prioritizedFittingFamilies,
    ...other
  } = {}) {
    super({
      prioritizedPipeFamilies:
        (typeof prioritizedPipeFamilies === 'string' &&
          JSON.parse(prioritizedPipeFamilies)) ||
        prioritizedPipeFamilies,
      prioritizedFittingFamilies:
        (typeof prioritizedFittingFamilies === 'string' &&
          JSON.parse(prioritizedFittingFamilies).map((i) => `${i}`)) ||
        prioritizedFittingFamilies,
      ...other,
    });
  }

  get primary() {
    return this.specName;
  }
  get primaryPlaceholder() {
    return 'No Name';
  }
  get secondary() {
    return this.instancesCount;
  }
  get secondaryPlaceholder() {
    return '--';
  }

  get instancesCount() {
    return `${this.specPipeInstances.size} ${pluralize(
      'pipe instance',
      this.specPipeInstances.size
    )}, ${this.specFittingInstances.size} ${pluralize(
      'fitting instance',
      this.specFittingInstances.size
    )}`;
  }

  get summary() {
    return {
      primary: `${this.specName}`,
      secondary: this.instancesCount,
    };
  }
}
