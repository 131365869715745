import { matchPath } from 'react-router-dom';
import { Set } from 'immutable';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../../entities/keys';

import { ENTITY_STATUS } from '../../../modules/entities/constants';

import selectEntities from '../../../modules/entities/selectors';
import selectDrafts from '../../../modules/drafts/selectors';

import { selectRouteLocationPathname } from '../../../modules/router/selectors';

import { selectSidebarIsFiltering } from '../../Dashboard/selectors';

import { selectNormalizedParameterProfiles } from '../../../entities/ParameterProfiles/selectors';

import ParameterProfileParameterSchema from '../../../entities/ParameterProfileParameters/schema';
import {
  selectNormalizedParameterProfileParameters,
  selectNormalizedDraftParameterProfileParameters,
} from '../../../entities/ParameterProfileParameters/selectors';

import {
  ParameterProfilesEditPath,
} from '../../../paths';

export const selectCurrentParameterProfileId = () => createSelector(
  selectRouteLocationPathname(),
  pathname => {
    const match = matchPath(pathname, { path: ParameterProfilesEditPath.url });
    return match && match.params.id || null;
  }
);

export const selectDraftDenormalizeSelector = () => createSelector(
  selectEntities(),
  entities => entities.delete(EK.PARAMETER_PROFILES.state)
);

export const selectCurrentParameterProfile = () => createSelector(
  selectCurrentParameterProfileId(),
  selectNormalizedParameterProfiles(),
  (parameterProfileId, parameterProfiles) => parameterProfiles.get(parameterProfileId)
);

export const selectOriginalNormalizedParameterProfileParameters = () => createSelector(
  selectNormalizedParameterProfileParameters(),
  selectCurrentParameterProfileId(),
  (parameters, parameterProfileId) => parameters.filter(parameter => parameter.parameterProfileId === parameterProfileId)
);

export const selectOriginalDenormalizedParameterProfileParameters = () => createSelector(
  selectOriginalNormalizedParameterProfileParameters(),
  selectEntities(),
  (parameters, entities) => denormalize(
    parameters,
    [ParameterProfileParameterSchema],
    entities.delete(EK.PARAMETER_PROFILES.state)
  )
);

const parameterProfileParameterFilterForm = formValueSelector(`filters.${EK.PARAMETER_PROFILE_PARAMETERS.state}`);

export const selectCurrentDraftParameterProfileParameters = () => createSelector(
  selectNormalizedDraftParameterProfileParameters(),
  selectEntities(),
  (parameters, entities) => denormalize(
    parameters,
    [ParameterProfileParameterSchema],
    entities.delete(EK.PARAMETER_PROFILES.state)
  )
);

export const selectCurrentFilteredDraftParameterProfileParameters = () => createSelector(
  selectSidebarIsFiltering(),
  selectNormalizedDraftParameterProfileParameters(),
  selectEntities(),
  state => parameterProfileParameterFilterForm(state, 'name', 'valueType', 'description'),
  (isFiltering, parameters, entities, query) => {
    const output = denormalize(
      parameters,
      [ParameterProfileParameterSchema],
      entities.delete(EK.PARAMETER_PROFILES.state)
    );

    return isFiltering ? output.filter(parameter => parameter.doesMatchQuery(query, { normalized: false })) : output;
  }
);

export const selectCurrentDraftParameterProfileParametersPendingValidChanges = () => createSelector(
  selectDrafts(),
  drafts => drafts.getIn([EK.PARAMETER_PROFILE_PARAMETERS.state, 'saveable']).size > 0
);

export const selectCurrentDraftParameterProfileParameterIds = () => createSelector(
  selectNormalizedDraftParameterProfileParameters(),
  (parameters) => parameters.reduce((ids, current) => ids.add(current.id), Set())
);

export const selectCurrentEditedDraftParameterProfileParametersCount = () => createSelector(
  selectNormalizedDraftParameterProfileParameters(),
  (parameters) => parameters.filter(draft => draft.status !== ENTITY_STATUS.LOCAL_DRAFT).size
);