import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';
import { Set } from 'immutable';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../entities/keys';

import selectDrafts from '../../modules/drafts/selectors';

import { selectRouteLocationPathname } from '../../modules/router/selectors';

import { selectSidebarIsFiltering } from '../Dashboard/selectors';

import {
  selectNormalizedSpecs,
} from '../../entities/Specs/selectors';

import { selectNormalizedProjects } from '../../entities/Projects/selectors';

import { PipingModuleProjectsEditPath } from '../../paths';

export const selectCurrentProjectId = () =>
  createSelector(selectRouteLocationPathname(), (pathname) => {
    const match = matchPath(pathname, {
      path: PipingModuleProjectsEditPath.url,
    });
    return (match && match.params.id) || null;
  });

export const selectCurrentProject = (altProjId = null) =>
  createSelector(
    selectCurrentProjectId(),
    selectNormalizedProjects(),
    (projectId, projects) => {
      const projIdToGet = projectId || altProjId || null;
      return projIdToGet && projects.get(projIdToGet) || undefined;
    }
  );

export const selectCurrentProjectSpecsIds = () =>
  createSelector(
    selectCurrentProject(),
    (project) =>
      (project && project.specs && project.specs.toSet()) || Set()
  );

export const selectCurrentProjectNormalizedUnarchivedSpecs = () =>
  createSelector(
    selectNormalizedSpecs(),
    selectCurrentProject(),
    (specs, project) => {
      const specsSet = project.specs.toSet();

      return specs
        .filter((spec) => !spec.archivedFlag)
        .map((spec) =>
          spec.set('isSelectedForId', specsSet.has(spec.id))
        );
    }
  );

const specFilterForm = formValueSelector(
  `filters.${EK.SPECS.state}`
);

export const selectCurrentFilteredDraftSpecs = () => // NO LONGER USES DRAFT SPECS, WHICH HAVE BEEN REMOVED
  createSelector(
    selectSidebarIsFiltering(),
    selectNormalizedSpecs(),
    (state) => specFilterForm(state, 'specName', 'mnemonic'),
    (isFiltering, specs, query) =>
      isFiltering
        ? specs.filter((spec) => spec.doesMatchQuery(query))
        : specs
  );

export const selectDraftSpecsSelectedForIds = () =>
  createSelector(selectDrafts(), (drafts) => {
    const records = drafts.getIn([EK.SPECS.state, 'records']);
    return records.reduce((idsSet, record) => {
      if (record.isSelectedForId) {
        return idsSet.add(record.id);
      } else {
        return idsSet;
      }
    }, Set());
  });

export const selectDraftSpecsSelectedForIdsPendingValidChanges = () =>
  createSelector(
    selectCurrentProjectSpecsIds(),
    selectDraftSpecsSelectedForIds(),
    (currentSpecs, draftSpecs) =>
      currentSpecs.size !== draftSpecs.size ||
      currentSpecs.reduce(
        (isDifferent, specId) =>
          isDifferent || !draftSpecs.has(specId),
        false
      )
  );

export const selectSerializedDraftSpecsSelectedForIds = () =>
  createSelector(selectDraftSpecsSelectedForIds(), (ids) =>
    ids.toArray()
  );
