import baseSchema from '../../modules/entities/utils/schema';

import EK from '../keys';

import FittingCategorySchema from '../FittingCategories/schema';
import MaterialSchema from '../Materials/schema';
import SizeSchema from '../Sizes/schema';
import UnitSystemSchema from '../UnitSystems/schema';

const insulationFamilySchema = baseSchema(EK.INSULATIONS.state, {
  insulationInstances: { isId: true, idField: 'insulationInstanceIds', isList: true },
  unitSystem: { schema: UnitSystemSchema, isId: true },
});

export const insulationInstanceSchema = baseSchema(EK.INSULATION_INSTANCES.state, {
  fittingCategory: { schema: FittingCategorySchema, isId: true },
  material: { schema: MaterialSchema, isId: true },
  sizeProperty: { schema: SizeSchema, isId: true, originalField: 'size' },
  matlThickness: { isComplexUnit: true },
  insulationFamily: { schema: insulationFamilySchema, isId: true },
});

insulationFamilySchema.define({ insulationInstances: [ insulationInstanceSchema ] });

export default insulationFamilySchema;
