import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

import EK from '../../../entities/keys';

import Grid from '../../../containers/grid/Grid';

import injectSaga from '../../../utils/sagas/injectSaga';

import {
  selectPipingModulePermissionsAndState,
  selectShowHiddenGridColumns,
} from '../../Dashboard/selectors';

import { selectCurrentSpec } from '../../SpecsEditSwitch/selectors';

import { editSpecFittingInstanceDrafts } from './actions';

import {
  selectCurrentDefaultFittingSelectionName,
  selectCurrentFilteredSpecFittingInstancesForFamily,
  selectCurrentFittingCode,
} from './selectors';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectShowHiddenGridColumns(),
  selectCurrentSpec(),
  selectCurrentFilteredSpecFittingInstancesForFamily(),
  selectCurrentFittingCode(),
  selectCurrentDefaultFittingSelectionName(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    showHiddenGridColumns,
    spec,
    data,
    currentFittingCode,
    defaultSelectionName,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      data: (!isLoading && data.toList().toArray()) || [],
      editable: canCollaborate &&
        hasValidLicense &&
        spec &&
        !spec.archivedFlag,
      currentFittingCode,
      defaultSelectionName,
      showHiddenGridColumns,
    };
  },
);

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    { editInstances: editSpecFittingInstanceDrafts },
    (
      {
        data,
        editable,
        isLoading,
        currentFittingCode,
        defaultSelectionName,
        showHiddenGridColumns,
      },
      dispatchProps,
    ) => ({
      data,
      columnDefs: [
        ...((editable && [
          { type: ['entitySelectionCell'] },
          { type: ['entitySaveStatusCell'] },
        ]) ||
          []),
        {
          headerName: 'Summary',
          field: '',
          type: ['complexCell'],
          valueGetter: ({ data }) => data.summary,
        },
        ...((!isLoading && [
          {
            headerName: 'Schedule',
            field: 'fittingInstance.schedule',
            type: ['entityCell'],
            cellRendererParams: {
              isDropdown: false,
              placeholder: { primary: 'No Schedule', secondary: '--' },
              ek: EK.SCHEDULES,
            },
          },
        ]) ||
          []),
        ...(currentFittingCode && !isLoading
          ? (currentFittingCode.changesSize && [
            ...((currentFittingCode.endCount === 1 && [
              {
                headerName: 'Port Size',
                field: 'fittingInstance.port0Size',
                type: ['entityCell'],
                cellRendererParams: {
                  isDropdown: false,
                  placeholder: { primary: 'No Size', secondary: '--' },
                  ek: EK.SIZES,
                },
              },
            ]) || [
              {
                headerName: currentFittingCode.endCount === 2
                  ? 'Port 0 Size'
                  : 'Ports 0/1 Size',
                field: 'fittingInstance.port0Size',
                type: ['entityCell'],
                cellRendererParams: {
                  isDropdown: false,
                  placeholder: { primary: 'No Size', secondary: '--' },
                  ek: EK.SIZES,
                },
              },
              {
                headerName: currentFittingCode.endCount === 2
                  ? 'Port 1 Size'
                  : 'Port 2 Size',
                field: currentFittingCode.endCount === 2
                  ? 'fittingInstance.port1Size'
                  : 'fittingInstance.port2Size',
                type: ['entityCell'],
                cellRendererParams: {
                  isDropdown: false,
                  placeholder: { primary: 'No Size', secondary: '--' },
                  ek: EK.SIZES,
                },
              },
            ]),
          ]) || [
            {
              headerName: 'Port Size(s)',
              field: 'fittingInstance.port0Size',
              type: ['entityCell'],
              cellRendererParams: {
                isDropdown: false,
                placeholder: { primary: 'No Size', secondary: '--' },
                ek: EK.SIZES,
              },
            },
          ]
          : []),
        {
          headerName: 'Stock No',
          field: 'editableStockno',
          hide: !showHiddenGridColumns,
          editable,
          type: ['basicCell', 'basicEditor'],
          valueSetter: ({ data, oldValue, newValue, context }) => {
            oldValue !== newValue &&
              context.editInstances &&
              context.editInstances(data.setEditableStockno(newValue));
            return false;
          },
          cellRendererParams: {
            placeholder: 'Stockno',
          },
        },
        {
          headerName: 'MCCS Code',
          field: 'editableMccsCode',
          hide: !showHiddenGridColumns,
          editable,
          type: ['basicCell', 'basicEditor'],
          valueSetter: ({ data, oldValue, newValue, context }) => {
            oldValue !== newValue &&
              context.editInstances &&
              context.editInstances(data.setEditableMccsCode(newValue));
            return false;
          },
          cellRendererParams: {
            placeholder: 'MCCS Code',
          },
        },
        {
          headerName: 'Selection Name',
          field: 'selectionName',
          hide: !showHiddenGridColumns,
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: defaultSelectionName,
          },
        },
        {
          headerName: 'Bolt Nut',
          field: 'boltNutFamily',
          editable,
          type: ['entityCell', 'dropdownEditor'],
          cellRendererParams: {
            placeholder: { primary: 'Select Bolt Nut' },
            ek: EK.BOLT_NUTS,
          },
          cellEditorParams: { ek: EK.BOLT_NUTS, isNullable: true },
        },
      ],
      ...((editable && dispatchProps) || {}),
    }),
  ),
);

export default enhance(Grid);
