import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';

import { PipingModuleSpecsEditPath } from '../../../paths';

import { selectIsShowingArchived } from '../../../modules/query/selectors';

import {
  processFetchAllSpecs,
  processArchiveSpecs,
  processUnarchiveSpecs,
  processCreateSpec,
  processCopySpec,
  processEditSpec,
  processDeleteSpec
} from '../../../entities/Specs/actions';

import { processFetchAllAppearances } from '../../../entities/Appearances/actions.js';
import { processFetchAllInsulationSpecs } from '../../../entities/InsulationSpecs/actions.js';
import { selectNormalizedAppearances } from '../../../entities/Appearances/selectors';
import { selectNormalizedInsulationSpecs } from '../../../entities/InsulationSpecs/selectors';

import { VALIDATION_FIELDS } from '../../../entities/Specs/model';

import {
  selectPipingModulePermissionsAndState,
  selectSidebarIsFiltering,
} from '../../Dashboard/selectors';

import { selectCurrentFilteredDraftSpecs } from './selectors';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Options from '../../templates/Structures/Options';
import Flex from '../../../components/common/Flex';
import Text from '../../../components/common/Text';
import ColorSwatch from '../../../components/common/ColorSwatch';


import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import { InputText } from 'primereact/inputtext';
import {
  dialogFooter,
  deleteDialogFooter,
  nameBody,
  areFieldsValid,
  doRequiredFieldsExist
} from './components';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';

import Header from './Header';

import RowEndEntityTools from '../../../components/common/EntityTools/RowEndTools';
import TextCell from '../../../components/grid/TextCell';

const BLANK_ROW = {
  id: '',
  specName: '',
  mnemonic: '',
  layerName: '',
  appearance: '',
  defaultInsulationSpec: '',
};

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectNormalizedAppearances(),
  selectNormalizedInsulationSpecs(),
  selectCurrentFilteredDraftSpecs(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isShowingArchived,
    appearances,
    insulationSpecs,
    data,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size == 0));
    return {
      ...rest,
      isLoading,
      appearances: appearances.toList().toArray() || [],
      insulationSpecs: insulationSpecs && insulationSpecs.toList().toArray() || [],
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      data: (!isLoading && data.toList().toArray()) || [],
      editable: canCollaborate && hasValidLicense,
    };
  },
);

function SpecsGrid(props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const [currentEditingRow, setCurrentEditingRow] = useState(BLANK_ROW);

  // dialog state
  const [isDialogOpen, setIsDialogOpen] = useState(false);                 // controls if the create/edit/copy dialog is open
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);     // used to determine header text and select "save" action
  const [isCopyDialogOpen, setIsCopyDialogOpen] = useState(false);         // used to determine header text and select "save" action
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);     // controls if the delete Dialog is open
  const [validation, setValidation] = useState(VALIDATION_FIELDS);


  const openCreateDialog = () => {
    setCurrentEditingRow(BLANK_ROW);
    setIsCreateDialogOpen(true);
    setIsDialogOpen(true);
  };

  // Dialog Actions
  const cancelDialogs = () => {
    // used to reset the dialogs
    setCurrentEditingRow(BLANK_ROW);
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsCopyDialogOpen(false);
    setIsDeleteDialogOpen(false);
  };

  const getDialogHeader = () => {
    if (isCreateDialogOpen) {
      return 'Create Piping Spec';
    } else if (isCopyDialogOpen) {
      return `Create Copy of '${currentEditingRow.specName}'`;
    } else {
      return `Edit '${currentEditingRow.specName}'`;
    }
  };

  const deleteAction = () => {
    // this action is PERMANENT, never call directly off of a button, always use the modal
    dispatch(processDeleteSpec(currentEditingRow.id));
    setCurrentEditingRow(BLANK_ROW);
    setIsDeleteDialogOpen(false);
  };

  const openDeleteDialog = () => {
    // closes the other dialogs and opens the delete Dialog
    setIsDialogOpen(false);
    setIsDeleteDialogOpen(true);
    setIsCreateDialogOpen(false);
    setIsCopyDialogOpen(false);
  };


  const saveAction = () => {
    // translated here to a format that can be sent to the api, replacing all object fields with their Id.
    setValidation(areFieldsValid(currentEditingRow));
    if (!doRequiredFieldsExist(currentEditingRow)) {
      return;
    }

    const editedRow = {
      id: currentEditingRow.id,
      specName: currentEditingRow.specName,
      mnemonic: currentEditingRow?.mnemonic,
      layerName: currentEditingRow?.layerName,
      appearanceId: currentEditingRow.appearance.id,
      defaultInsulationSpecId: currentEditingRow?.defaultInsulationSpec?.id
    };

    if (isCreateDialogOpen) {
      dispatch(processCreateSpec(editedRow));
    } else if (isCopyDialogOpen) {
      dispatch(processCopySpec(editedRow));
    } else {
      dispatch(processEditSpec(editedRow.id, editedRow));
    }
    cancelDialogs();
  };

  const goToPipingSpecsEditPath = (insulationId) => {
    const link = PipingModuleSpecsEditPath.generate({ id: insulationId });
    dispatch(push(link));
  };

  const editRowAction = (rowdata) => {
    // Immutable.Entity's properties prevent the object from being used directly, so the object is converted here
    const currentNonEntityRow = {
      id: rowdata.id,
      specName: rowdata.specName,
      mnemonic: rowdata.mnemonic,
      layerName: rowdata.layerName,
      appearance: rowdata.appearance,
      defaultInsulationSpec: rowdata.defaultInsulationSpec
    };
    setCurrentEditingRow(currentNonEntityRow);
    setIsDialogOpen(true);
  };

  const copyRowAction = (rowdata) => {
    setCurrentEditingRow(rowdata);
    setIsCopyDialogOpen(true);
    setIsDialogOpen(true);
  };

  const archiveAction = (rowdata) => {
    dispatch(processArchiveSpecs([rowdata.id]));
  };

  const unarchiveAction = (rowdata) => {
    dispatch(processUnarchiveSpecs([rowdata.id]));
  };

  const handleEditedRowChange = (newValue, field) => {
    // called on every edit
    let _editedRow = { ...currentEditingRow };
    _editedRow[`${field}`] = newValue;
    setCurrentEditingRow(_editedRow);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setCurrentEditingRow(BLANK_ROW);
  };

  useEffect( () => {
    dispatch(processFetchAllSpecs());
    dispatch(processFetchAllAppearances());
    dispatch(processFetchAllInsulationSpecs());
  }, []);

  return (
    <>
      <Flex flexDirection='row' mb={4}>
        <Header/>
        <Options
          canFilter={true}
          isLoading={reduxProps.isLoading}
          createEntity={openCreateDialog}
          shouldHaveLicense={false}
          canCollaborate={reduxProps.canCollaborate}
        />
      </Flex>
      <Flex
        style={{
          border: '1px solid #DEE2E6',
          borderRadius: '5px',
          height: '86%',
        }}
        flexDirection='column'
      >
        <DataTable
          reorderableColumns
          value={reduxProps.data}
          tableStyle={{ minWidth: '50rem' }}
          size='normal'

          scrollable
          scrollHeight='flex'
        >
          <Column field='name' header='Name' body={(rowData) => nameBody(rowData, goToPipingSpecsEditPath)}></Column>
          <Column field='instancesCount' header='Instances' style={{fontSize: '14px'}} body={(rowData=>
            <>
              {TextCell(rowData.instancesCount.split(',')[0])}
              {TextCell(rowData.instancesCount.split(',')[1])}
            </>
          )}></Column>
          <Column field='mnemonic' header='Mnemonic' body={(rowData=>TextCell(rowData.mnemonic))} style={{fontSize: '14px'}}></Column>
          <Column header=''
            style={{textAlign: 'right' }}
            body={(rowData) =>
              <RowEndEntityTools
                rowdata={rowData}
                editAction={editRowAction}
                copyAction={copyRowAction}
                listAction={(data) => goToPipingSpecsEditPath(data.id)}
                archiveAction={archiveAction}
                unarchiveAction={unarchiveAction}
              />}
          ></Column>
        </DataTable>
      </Flex>
      <Dialog visible={reduxProps.editable && isDialogOpen} style={{ width: '75%' }} header={()=>getDialogHeader()} footer={() => dialogFooter(isCreateDialogOpen, isCopyDialogOpen, openDeleteDialog, saveAction, cancelDialogs)} modal className='p-fluid' closable={false} >
        <div>
          <label>Name</label>
          <InputText value={currentEditingRow.specName} onChange={(e) => handleEditedRowChange(e.target.value, 'specName')} />
        </div>
        <div>
          <label>Mnemonic</label>
          <InputText value={currentEditingRow.mnemonic} onChange={(e) => handleEditedRowChange(e.target.value, 'mnemonic')} />
        </div>
        <div>
          <label>Layer Name</label>
          <InputText value={currentEditingRow.layerName} onChange={(e) => handleEditedRowChange(e.target.value, 'layerName')} />
        </div>
        <div>
          <label>Appearance</label>
          <Dropdown
            optionLabel='name'
            value={currentEditingRow.appearance}
            options={reduxProps.appearances}
            onChange={(e) => handleEditedRowChange(e.target.value, 'appearance')}
            itemTemplate={(item) => <Flex flexDirection='row' ><ColorSwatch color={item.tertiary} /><ComplexCellRenderer value={{ primary: item.primary, secondary: item.secondary }} isCapitalized={false} placeholder='' /></Flex>}
            filter
          />
        </div>
        <div>
          <label>Default Insulation Spec</label>
          <Dropdown
            optionLabel='primary'
            optionValue='id'
            value={currentEditingRow.defaultInsulationSpec && currentEditingRow.defaultInsulationSpec.id ? currentEditingRow.defaultInsulationSpec.id : ''}
            options={reduxProps.insulationSpecs}
            onChange={(e) => handleEditedRowChange(reduxProps.insulationSpecs.find(item => item.id == e.target.value), 'defaultInsulationSpec')}
            itemTemplate={(item) => ComplexCellRenderer({
              value: { primary: item.primary, secondary: `${item.secondary ? item.secondary : '--'}` }, placeholder: '', isCapitalized: false
            })}
            filter
          />
        </div>
      </Dialog>
      <Dialog visible={reduxProps.editable && isDeleteDialogOpen} style={{ width: '32rem' }} header={`Delete Piping Spec '${currentEditingRow.specName}'`} footer={() => deleteDialogFooter(closeDeleteDialog, deleteAction)} closable={false} >
        <div>
          <Text>Are you sure you want to delete {currentEditingRow.specName}?</Text>
          <Text style={{ color: 'red' }}>This action will be PERMANENT and CANNOT BE UNDONE.</Text>
          <Text><strong>Only delete this if you are certain that it needs to be removed from everywhere</strong></Text>
        </div>
      </Dialog>
    </>
  );
}

export default withRouter(SpecsGrid);
