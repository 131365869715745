import React, { useState, useEffect } from 'react';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// custom hooks
import { useOnCellEditComplete } from '../../../hooks/useOnCellEditComplete';

//Grid
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { rowStyles } from '../../../components/primeGrid/RowStyles';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

// cell editors/bodies
import EntityRowTools from '../../../components/common/EntityTools/RowStartTools';
import { Dropdown } from 'primereact/dropdown';
import Text from '../../../components/common/Text';
import { InputNumber } from 'primereact/inputnumber';
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import UnitValueCellRenderer from '../../../components/grid/UnitValueCellRenderer';

// Header imports
import Options from '../../templates/Structures/Options';
import Header from '../../templates/Structures/Header';
import { pluralize } from '../../../components/common/Header';
import Skeleton from 'react-loading-skeleton';
import Flex from '../../../components/common/Flex';
import { PipingModulePipesBendsEditPath } from '../../../paths';

//actions
import {
  processDeleteBendFamilyForPipeFamily,
  processFetchIndividualBendFamilyForPipeFamily,
  processEditBendFamilyForPipeFamily,
} from '../../../entities/BendFamilies/actions';
import { processFetchIndividualPipeFamily } from '../../../entities/PipeFamilies/actions';
import { processFetchAllSizes } from '../../../entities/Sizes/actions';
import { processSaveBendInstances } from '../../../entities/BendInstances/actions';

// Bend Family Dialog
import { doRequiredFieldsExist, areFieldsValid } from '../PipesBends/components';
import { VALIDATION_FIELDS } from '../PipesBends/Body';
import { BendDialog, DeleteBendDialog } from '../../../containers/dialog/templates/BendDialog';

// misc.
import { getUnitLength } from '../../../entities/UnitSystems/model';
import { convertToBendInstanceType, saveCurrentBendInstanceChanges } from './actions';
import colors from '../../../assets/themes/base/colors';

// Selectors
import {
  selectCurrentBendFamily,
} from '../../../entities/BendFamilies/selectors';
import { selectCurrentPipeUnitSystem } from '../../PipesEditSwitch/selectors';
import { selectPipingModulePermissionsAndState } from '../../Dashboard/selectors';
import { selectNormalizedSizes } from '../../../entities/Sizes/selectors';
import { selectBendInstanceCount, selectCurrentFilteredBendInstances } from '../../../entities/BendInstances/selectors';
import { selectSidebarIsFiltering } from '../../Dashboard/selectors';

const mapStateToProps = (bendFamilyId, pipeFamilyId) => createSelector(
  selectPipingModulePermissionsAndState(),
  selectCurrentBendFamily(bendFamilyId),
  selectCurrentFilteredBendInstances(bendFamilyId),
  selectNormalizedSizes(),
  selectCurrentPipeUnitSystem(pipeFamilyId),
  selectBendInstanceCount(bendFamilyId),
  selectSidebarIsFiltering(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    bend,
    data,
    sizes,
    defaultUnitSystem,
    instanceCount,
    isFiltering,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || instanceCount == 0));
    return {
      ...rest,
      isLoading,
      data: !isLoading && data || [],
      editable: canCollaborate && hasValidLicense && bend && !bend.archivedFlag,
      bend,
      bendId: bend && bend.id || null,
      defaultUnitSystem,
      instanceCount,
      canCollaborate,
      hasValidLicense,
      sizes: sizes && sizes.toList().toArray() || [],
      isFiltering,
    };
  },
);

function BendsEditGrid ({match}) {
  const reduxProps = useSelector(mapStateToProps(match.params.familyId, match.params.id));
  const dispatch = useDispatch();

  // dialog state
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [validation, setValidation] = useState(VALIDATION_FIELDS);
  const [editedBendFamily, setEditedBendFamily] = useState(reduxProps.bend);

  // table state
  const [editedRows, resetEditedRows, currentEditingRow, isEditing, onBeforeCellEditShow, onCellChange, onCellEditComplete, handleRowAction, onDropdownComplete] = useOnCellEditComplete(reduxProps.data,  convertToBendInstanceType, reduxProps.editable, true);

  //dialog methods
  const handleEditedDialogChange = (newValue, field) => {
    // called on every edit, needs to be made into a custom hook of some kind
    let _editedRow = { ...editedBendFamily };
    _editedRow[`${field}`] = newValue;
    setEditedBendFamily(_editedRow);
  };

  const saveBendAction = () => {
    // translated here to a format that can be sent to the api, replacing all object fields with their Id.
    setValidation(areFieldsValid(editedBendFamily));
    if (!doRequiredFieldsExist(editedBendFamily)) {
      return;
    }

    const editedRow = {
      id: editedBendFamily.id,
      name: editedBendFamily.name,
    };

    dispatch(processEditBendFamilyForPipeFamily(match.params.id, editedRow.id, editedRow));
    setIsDialogOpen(false);
  };

  //Cell Bodies
  const textValueBody = (rowdata, field, placeholder = '') => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) ||  convertToBendInstanceType(rowdata);

    return curRow && curRow[`${field}`] ?
      <Text >{curRow[`${field}`]}</Text> :
      <Text style={{ color: colors.gray[5] }} >{placeholder}</Text>;
  };

  const unitValueBody = (rowdata, field) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) ||  convertToBendInstanceType(rowdata);

    return (
      <UnitValueCellRenderer data={curRow[field] || ''} unitSystem={reduxProps.defaultUnitSystem} />
    );
  };

  const sBody = (rowdata, field, placeholder) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) ||  convertToBendInstanceType(rowdata);

    return curRow && curRow[field] ?
      <ComplexCellRenderer value={curRow[field]} isCapitalized={true} paddingLeft='0px' /> :
      <Text className='text-cell-body' style={{ color: colors.gray[5] }}>{placeholder}</Text>;
  };

  // Cell Editors
  const unitNumberCellEditor = (field) =>
    <span className='p-input-icon-right' >
      <Text as='i' >{reduxProps.defaultUnitSystem.unitLength.abbreviation || ''}</Text>
      <InputNumber value={currentEditingRow && getUnitLength(currentEditingRow[field], reduxProps.defaultUnitSystem)} onChange={(e) => onCellChange(e.value, field)} minFractionDigits={1} />
    </span>;

  const numberCellEditor = (field) =>
    <InputNumber value={currentEditingRow && currentEditingRow[field]} onChange={(e) => onCellChange(e.value, field)} minFractionDigits={1} />;

  const sizeDropdown = () => {
    return <Dropdown
      value={currentEditingRow && currentEditingRow.sizeProperty}
      options={reduxProps.sizes}
      optionLabel='name'
      filter={true}

      itemTemplate={(item) => ComplexCellRenderer({ value: item, isCapitalized: true })}
      valueTemplate={(item) => ComplexCellRenderer({ value: item, isCapitalized: true })}

      onChange={(e) => onDropdownComplete.current(e.value, 'sizeProperty', currentEditingRow)}
      style={{ width: '100%' }}
    />;
  };

  const getRowTool = (rowdata) => {
    const data = editedRows && editedRows.find(row => row.id == rowdata.id) || reduxProps.data.find((item) => item.id == rowdata.id);
    return (
      <EntityRowTools rowdata={data} handleRowAction={handleRowAction} />
    );
  };

  useEffect(() => {
    dispatch(processFetchIndividualBendFamilyForPipeFamily(match.params.id, match.params.familyId));
    dispatch(processFetchIndividualPipeFamily(match.params.id));
    dispatch(processFetchAllSizes());
  }, []);

  return (
    <>
      {!reduxProps.isLoading && <Flex flexDirection="row" mb={4} >
        <Header
          isLoading={reduxProps.isLoading}
          title={(reduxProps.bend && reduxProps.bend.name) || PipingModulePipesBendsEditPath.defaultTitle}
          subtitle={reduxProps.isFiltering ?
            `Showing ${reduxProps.data.length}/${reduxProps.instanceCount} ${pluralize('filtered Bend Instance', reduxProps.instanceCount)}` :
            `Showing ${reduxProps.instanceCount} ${pluralize('Bend Instance', reduxProps.instanceCount)} Total`}
        />
        <Options
          updateEntities={() => saveCurrentBendInstanceChanges(
            editedRows,
            (data) => { dispatch(processSaveBendInstances(reduxProps.bendId, data)); resetEditedRows(); },
            reduxProps.defaultUnitSystem
          )}
          isEditingGrid={isEditing}
          isLoading={reduxProps.isLoading}
          pendingValidChanges={editedRows.length > 0}

          editEntity={() => setIsDialogOpen(true)}
          isArchived={reduxProps.bend?.archivedFlag}
          archiveEntity={() => dispatch(processArchiveBendFamiliesForPipeFamily(match.params.id, [reduxProps.bendId]))}
          unarchiveEntity={() => dispatch(processUnarchiveBendFamiliesForPipeFamily(match.params.id, [reduxProps.bendId]))}

          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
          canFilter={true}
        />
      </Flex> || <Skeleton style={{ height: '2rem', marginBottom: '1rem' }} />
      }
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable
          reorderableColumns
          value={reduxProps.data}
          tableStyle={{ minWidth: '55rem' }}
          size='normal'
          editMode='cell'
          rowClassName={(data) => data && rowStyles(data, editedRows)}

          scrollable
          scrollHeight='flex'

          removableSort
        >
          <Column header='' style={{ width: '2%' }} body={(rowdata) => getRowTool(rowdata)} ></Column>
          <Column field='sizeProperty' header='Size' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => sizeDropdown()} body={(rowdata) => sBody(rowdata, 'sizeProperty', 'Select Size')} style={{ width: '14%' }} ></Column>
          <Column field='ratio' header='Ratio' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => numberCellEditor('ratio')} onCellEditComplete={(e) => onCellEditComplete.current(e)} body={(rowdata) => textValueBody(rowdata, 'ratio', 'Ratio')} style={{ width: '14%' }} sortable ></Column>
          <Column field='radius' style={{ width: '14%' }} header='Radius' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => unitNumberCellEditor('radius')} onCellEditComplete={(e) => onCellEditComplete.current(e)} body={(rowdata) => unitValueBody(rowdata, 'radius', '')} ></Column>
          <Column field='minRadius' style={{ width: '14%' }} header='Min Radius' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => unitNumberCellEditor('minRadius')} onCellEditComplete={(e) => onCellEditComplete.current(e)} body={(rowdata) => unitValueBody(rowdata, 'minRadius', 'Min Radius')} ></Column>
          <Column field='maxRadius' style={{ width: '14%' }} header='Max Radius' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => unitNumberCellEditor('maxRadius')} onCellEditComplete={(e) => onCellEditComplete.current(e)} body={(rowdata) => unitValueBody(rowdata, 'maxRadius', 'Max Radius')} ></Column>
          <Column field='minAngle' style={{ width: '14%' }} header='Min Angle' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => numberCellEditor('minAngle')} onCellEditComplete={(e) => onCellEditComplete.current(e)} body={(rowdata) => textValueBody(rowdata, 'minAngle', 'Min Angle')} sortable ></Column>
          <Column field='maxAngle' style={{ width: '14%' }} header='Max Angle' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => numberCellEditor('maxAngle')} onCellEditComplete={(e) => onCellEditComplete.current(e)} body={(rowdata) => textValueBody(rowdata, 'maxAngle', 'Max Angle')} sortable ></Column>
        </DataTable>
        <BendDialog
          currentEditingRow={editedBendFamily || reduxProps.bend}
          editable={reduxProps.editable}
          handleEditedRowChange={handleEditedDialogChange}
          isDialogOpen={isDialogOpen}
          saveAction={saveBendAction}
          cancelDialogs={() => {setIsDialogOpen(false); setEditedBendFamily(reduxProps.bend);}}
          validation={validation}
        />
      </Flex>
    </>);
}

export default withRouter(BendsEditGrid);