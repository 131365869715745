import React from 'react';
import { withRouter, matchPath } from 'react-router-dom';
import { useSelector } from 'react-redux';

import BreadcrumbTemplate from '../../templates/Structures/Breadcrumb';

import { selectCurrentMiterFamily } from '../../../entities/MiterFamilies/selectors';

import { PipingModulePipesMitersEditPath } from '../../../paths';

function BendEditBread({ location }) {
  const match = matchPath(location.pathname, { path: PipingModulePipesMitersEditPath.url });
  const miterId = (match && match.params.familyId) || null;
  const bendFamily = useSelector(selectCurrentMiterFamily(miterId));

  return <BreadcrumbTemplate title={(bendFamily && bendFamily.name) || PipingModulePipesBendsEditPath.defaultTitle} />;
}

export default withRouter(BendEditBread);
