import { SpecFittingFamilyType } from '../../../entities/FittingFamilies/types';
import { SpecFittingInstancesType } from '../../../entities/SpecFittingInstances/types';


export function convertToSpecFittingFamilyType ({
  id,
  name,
  selectionName,
  archivedFlag,
  material,
  fittingCode,
  fittingCategory,
  boltNutFamily,
  fittingInstances,
  fittingInstancesCount,
  selectedFittingInstancesForSpec,
  isSelectableForPriority,
  isSelectedForPriority,
  prioritizationState,
}: SpecFittingFamilyType) {
  return {
    id,
    name,
    selectionName,
    archivedFlag,
    material,
    fittingCode,
    fittingCategory,
    boltNutFamily,
    fittingInstances,
    fittingInstancesCount,
    selectedFittingInstancesForSpec,
    isSelectableForPriority,
    isSelectedForPriority,
    prioritizationState,
  };
}

export function convertToSpecFittingInstanceType ({
  id,
  boltNutFamily,
  fittingInstance,
  fittingFamilyId,
  spec,
  stockno,
  mccsCode,
  selectionName,
}: SpecFittingInstancesType) {
  return {
    id,
    boltNutFamily,
    fittingInstance,
    fittingFamilyId,
    spec,
    stockno,
    mccsCode,
    selectionName,
  };
}

export const saveCurrentPipingSpecPipeFamiliesChanges = (
  changes: any,
  saveAction: any,
) => {
  console.log(changes);
  return;
};
