import { SpecPipeInstancesType } from '../../../entities/SpecPipeInstances/types';
import { SpecPipeFamilyType } from '../../../entities/PipeFamilies/types';
import { PipeInstancesType } from '../../../entities/PipeInstances/types';
import { List } from 'immutable';

export function convertToSpecPipeInstanceType ({
  id,
  pipeInstance,
  pipeFamilyId,
  spec,
  stockno,
  mccsCode,
  isEdited = false,
  isDeleted = false,
  isCreated = false,
}: SpecPipeInstancesType): SpecPipeInstancesType {
  return {
    id,
    pipeInstance,
    pipeFamilyId,
    spec,
    stockno,
    mccsCode,
    isEdited,
    isDeleted,
    isCreated,
  };
}

export function convertToSpecPipeFamilyType ({
  id,
  isSelectedForSpec,
  isPrioritized,
  name,
  material,
  pipeInstances,
  selectedPipeInstancesForSpec,
  index,
  isEdited = false,
  isDeleted = false,
  isCreated = false,
}: SpecPipeFamilyType) : SpecPipeFamilyType {
  return {
    id,
    isSelectedForSpec,
    isPrioritized,
    name,
    material,
    pipeInstances,
    selectedPipeInstancesForSpec,
    index,
    isEdited,
    isDeleted,
    isCreated,
  };
}

// editedOrder will be handles in the 'saveaction' method in the body.js file to simplify matters since it will just be getting filtered and JSON.stringify'd
// spec_pipe_instances_attributes: [
//   : id,
//   : pipe_instance_id,
//   : stockno,
//   : mccs_code,
//   : _destroy
// ]})[: spec_pipe_instances_attributes]
export const saveCurrentPipingSpecPipeFamiliesChanges = (
  editedRows: Record<string, SpecPipeFamilyType>,
  instances: Array<PipeInstancesType>,
  specPipeInstances: Array<SpecPipeInstancesType>,
  saveAction: any,
) => {
  let collectionsdata: any = [];

  // if item is selectedFor spec
  //   add all
  for (const [key, value] of Object.entries(editedRows)) {
    if(value.isSelectedForSpec) {
      collectionsdata = collectionsdata.concat(instances.filter(({pipeFamilyId}) => pipeFamilyId == key).map(instance => ({pipe_instance_id: instance.id, stockno: instance.stockno, mccsCode: instance.mccsCode})));
    } else {
      collectionsdata = collectionsdata.concat(specPipeInstances.filter(({ pipeFamilyId }) => pipeFamilyId.id == key).map(instance => ({ id: instance.id, _destroy: '1' })));
    }
  }
  console.log('data', editedRows);

  saveAction(collectionsdata);
};
