import { getUnitLength } from '../../../entities/UnitSystems/model';

export const convertToBendSpringbackInstanceType = ({
  id,
  sizeProperty,
  radius,
  angle,
  elongationLength,
  springBackLength,
  isCreated=false,
  isEdited=false,
  isDeleted=false,
}) => ({
  id,
  sizeProperty,
  radius,
  angle,
  elongationLength,
  springBackLength,
  isCreated,
  isEdited,
  isDeleted,
});

export const saveCurrentBendSpringbackInstanceChanges = (
  changes,
  saveAction,
  currentUnitSystem,
) => {
  const collectionsdata = [];

  changes.forEach((item) => {
    if (item.isCreated) {
      collectionsdata.push({
        sizeId: item.sizeProperty && item.sizeProperty.id,
        radius: getUnitLength(item.radius, currentUnitSystem),
        angle: item.angle,
        elongationLength: getUnitLength(item.elongationLength, currentUnitSystem),
        springBackLength: getUnitLength(item.springBackLength, currentUnitSystem),
      });
    } else if (item.isDeleted) {
      collectionsdata.push({ id: item.id, _destroy: '1' });
    } else if (item.isEdited) {
      collectionsdata.push({
        id: item.id,
        sizeId: item.sizeProperty && item.sizeProperty.id,
        radius: getUnitLength(item.radius, currentUnitSystem),
        angle: item.angle,
        elongationLength: getUnitLength(item.elongationLength, currentUnitSystem),
        springBackLength: getUnitLength(item.springBackLength, currentUnitSystem),
      });
    }
  });

  collectionsdata.length > 0 && saveAction(collectionsdata);
};