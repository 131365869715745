import { createSelector } from 'reselect';
import { Set } from 'immutable';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../../entities/keys';

import { ENTITY_STATUS } from '../../../modules/entities/constants';

import selectDrafts from '../../../modules/drafts/selectors';

import { selectNormalizedDraftNotes } from '../../../entities/Notes/selectors';

import { selectSidebarIsFiltering } from '../../Dashboard/selectors';

export const selectCurrentDraftNotesPendingValidChanges = () => createSelector(
  selectDrafts(),
  drafts => drafts.getIn([EK.NOTES.state, 'saveable']).size > 0
);

export const selectCurrentDraftNoteIds = () => createSelector(
  selectNormalizedDraftNotes(),
  drafts => drafts.reduce((ids, draft) => ids.add(draft.id), Set())
);

export const selectCurrentEditedDraftNotesCount = () => createSelector(
  selectNormalizedDraftNotes(),
  drafts => drafts.filter(draft => draft.status !== ENTITY_STATUS.LOCAL_DRAFT).size
);

const noteFilterForm = formValueSelector(`filters.${EK.NOTES.state}`);

export const selectCurrentFilteredNormalizedDraftNotes = () => createSelector(
  selectSidebarIsFiltering(),
  selectNormalizedDraftNotes(),
  state => noteFilterForm(state, 'name', 'noteFeatureName', 'noteBody', 'description'),
  (isFiltering, notes, query) => isFiltering ? notes.filter(note => note.doesMatchQuery(query)) : notes
);