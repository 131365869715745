import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import colors from '../../../assets/themes/base/colors';
import radii from '../../../assets/themes/base/radii';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';

import EK from '../../../entities/keys';

import injectReducer from '../../../utils/reducers/injectReducer';
import injectSaga from '../../../utils/sagas/injectSaga';

import reducer from './reducer';
import saga from './sagas';

import Flex from '../../../components/common/Flex';
import Text from '../../../components/common/Text';

import NoteBlock from './components/NoteBlock';

import { selectStandardizeModulePermissionsAndState } from '../../Dashboard/selectors';

import { processFetchIndividualNoteProfile } from '../../../entities/NoteProfiles/actions';

import {
  selectCurrentNoteProfile,
  selectCurrentNoteBlocks,
} from './selectors';

const BodyContainer = styled(Flex)`
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.gray[3]};
  border-radius: ${radii[1]};
  border: 1px solid ${colors.gray[4]};
`;

const BodyContainerItem = styled(Flex)`
  flex-grow: 0;
  flex-direction: column;
  width: 49%;
`;

const NoteProfilesEditDataBody = ({ profileId, ...rest }) =>
  (profileId && (
    <NoteProfilesEditBody profileId={profileId} {...rest} />
  )) ||
  null;

const NoteProfilesEditBody = ({
  fetchProfile,
  isLoading,
  editable,
  profileId,
  blocks,
}) => {
  useEffect(() => {
    fetchProfile(profileId);
  }, []);

  return (
    <DndProvider backend={Backend}>
      <BodyContainer p={3}>
        {(!isLoading &&
          ((blocks &&
            blocks.size > 0 &&
            blocks.map(
              (block) =>
                (
                  <BodyContainerItem key={block.id}>
                    <NoteBlock
                      profileId={profileId}
                      noteBlock={block}
                      editable={editable}
                    />
                  </BodyContainerItem>
                ) || null
            )) || (
            <Text
              fontSize={6}
              fontWeight={400}
              color="gray.6"
              mr="auto"
              ml="auto"
            >
              Add sections to get started
            </Text>
          ))) ||
          null}
      </BodyContainer>
    </DndProvider>
  );
};

const mapStateToProps = createSelector(
  selectStandardizeModulePermissionsAndState(),
  selectCurrentNoteProfile(),
  selectCurrentNoteBlocks(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    profile,
    blocks
  ) => {
    const isLoading = isLoadingInitialData || isFetching;
    return {
      ...rest,
      isLoading,
      profileId: (profile && profile.id) || null,
      blocks: (!isLoading && blocks) || null,
      editable:
        canCollaborate && hasValidLicense && profile && !profile.archivedFlag,
    };
  }
);

const mapDispatchToProps = (dispatch) => ({
  fetchProfile(profileId) {
    dispatch(processFetchIndividualNoteProfile(profileId));
  },
});

const enhance = compose(
  withRouter,
  injectReducer({ key: `${EK.NOTE_PROFILES.state}Edit`, reducer }),
  injectSaga({ key: `${EK.NOTE_PROFILES.state}Edit`, saga }),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(NoteProfilesEditDataBody);
