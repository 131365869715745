import { createSelector } from 'reselect';
import { List } from 'immutable';
import { denormalize } from 'normalizr';

import selectEntities from '../../modules/entities/selectors';

import EK from '../keys';

import { formValueSelector } from 'redux-form/immutable';
import { selectIsShowingArchived } from '../../modules/query/selectors';
import { selectSidebarIsFiltering } from '../../routes/Dashboard/selectors';
import BoltNutFamilySchema from './schema';

export const selectNormalizedBoltNutFamilies = () =>  createSelector(
  selectEntities(),
  entities => entities.get(EK.BOLT_NUTS.state)
);
export const selectNormalizedBoltNutFamiliesList = () => createSelector(
  selectNormalizedBoltNutFamilies(),
  boltNutFamilies => boltNutFamilies.toList()
);
export const selectAllBoltNutsOptions = () => createSelector(
  selectNormalizedBoltNutFamilies(),
  boltNuts => boltNuts.reduce((output, boltNut) => !boltNut.archivedFlag ? output.push(boltNut.toOption()) : output, List())
);
export const selectBoltNutFamiliesCounts = () => createSelector(
  selectNormalizedBoltNutFamilies(),
  boltNuts => boltNuts.reduce(({ unarchived, total }, boltNut) => ({
    unarchived: !boltNut.archivedFlag ? unarchived + 1 : unarchived,
    total: total + 1,
  }), {
    unarchived: 0,
    total: 0,
  })
);

export const selectBoltNutFamilies = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get(EK.BOLT_NUTS.state), [BoltNutFamilySchema], entities.delete('boltNutInstances'))
);

const boltNutFilterForm = formValueSelector(`filters.${EK.BOLT_NUTS.state}`);

export const selectCurrentFilteredBoltNuts = () => createSelector(
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectBoltNutFamilies(),
  state => boltNutFilterForm(state, 'name', 'description'),
  (isFiltering, isShowingArchived, boltNuts, query) => isFiltering ?
    boltNuts.filter(boltNut => (isShowingArchived || !boltNut.archivedFlag) && boltNut.doesMatchQuery(query)) :
    boltNuts.filter(boltNut => !boltNut.archivedFlag)
);

export const selectCurrentBoltNutFamily = (familyId) => createSelector(
  selectEntities(),
  entities => entities.hasIn([EK.BOLT_NUTS.state, familyId]) && entities.getIn([EK.BOLT_NUTS.state, familyId])
);

export const selectCurrentUnarchivedBoltNutFamilyOptions = () => createSelector(
  selectNormalizedBoltNutFamilies(),
  bnfs => bnfs.filter(bnf => !bnf.archivedFlag)
);