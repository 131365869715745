import React from 'react';
import Text from '../../common/Text';
import Flex from '../../common/Flex';
import { getUnitLength } from '../../../entities/UnitSystems/model';
import colors from '../../../assets/themes/base/colors';

export default function ({data, unitSystem}) {
  return (
    <div style={{ minWidth: '5rem', maxWidth: '10rem', width: '100%', }} >
      <Text style={{ float: 'left', width: '75%', textOverflow: 'ellipsis', overflow: 'hidden' }}>{data && getUnitLength(data, unitSystem) || ''}</Text>
      <span style={{ color: colors.gray[5], float: 'right' }} >{unitSystem.unitLength.abbreviation || ''}</span>
    </div>
  );
}