/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

// Grid Imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

// Hooks
import { useOnCellEditComplete } from '../../../hooks/useOnCellEditComplete';

// cell bodies/editors
import Text from '../../../components/common/Text';
import colors from '../../../assets/themes/base/colors';
import EntityRowTools from '../../../components/common/EntityTools/RowStartTools';
import { codeBody } from '../Fittings/components';
import { categoryBody } from '../Fittings/components';

// Header
import Flex from '../../../components/common/Flex';
import { PipingModuleSpecsPipesEditPath } from '../../../paths';
import Header from '../../templates/Structures/Header';
import Options from '../../SpecsEditSwitch/Options';
import Skeleton from 'react-loading-skeleton';
import { convertToSpecFittingFamilyType, convertToSpecFittingInstanceType } from './actions';
import { convertToPipeType } from '../Pipes/Body';
import { pluralize } from '../../../components/common/Header';

// validation
import { rowStyles } from '../../../components/primeGrid/RowStyles';

// actions
import { processSaveSpecFittingInstances } from '../../../entities/SpecFittingInstances/actions';
import { processEditSpec } from '../../../entities/Specs/actions';
import { processFetchAllMaterials } from '../../../entities/Materials/actions';
import { processFetchIndividualSpec } from '../../../entities/Specs/actions';
import { processFetchAllFittingFamilies } from '../../../entities/FittingFamilies/actions';

// selectors
import { selectPipingModulePermissionsAndState, selectSidebarIsFiltering } from '../../Dashboard/selectors';
import { selectOrderedFilteredUnarchivedFittingFamilies } from './selectors';
import { selectCurrentSpec } from '../../../entities/Specs/selectors';
import Button from '../../../components/common/Button';
import { selectCurrentEditableSpecFamilySorting } from '../../SpecsEditSwitch/selectors';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectOrderedFilteredUnarchivedFittingFamilies(),
  selectCurrentSpec(),
  selectCurrentEditableSpecFamilySorting(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    data,
    spec,
    isPrioritizedFamilySortingEnabled,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.length == 0));
    return {
      ...rest,
      isFiltering,
      isLoading,
      canCollaborate,
      hasValidLicense,
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense,
      count: 0,
      spec,
      isPrioritizedFamilySortingEnabled,
    };
  },
);

function SpecFittingFamiliesGrid({ match, location }) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  // editing state
  const [editedRows, setEditedRows] = useState({});
  const [orderedRows, setOrderedRows] = useState([]);
  const [pendingValidChanges, setPendingValidChanges] = useState(true);

  //Cell displays
  const textValueBody = (rowdata, field, placeholder = '') => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToPipeType(rowdata);

    return curRow && curRow[`${field}`] ?
      <Text >{curRow[`${field}`]}</Text> :
      <Text style={{ color: colors.gray[5], whiteSpace: 'nowrap' }} >{placeholder}</Text>;
  };

  // cell actions
  const handleOrderedRowsChange = (fittingFamily) => {
    let _orderedRows = [...orderedRows];
    const curItem = _orderedRows.findIndex(item => item.id == fittingFamily.id);
    switch (fittingFamily.isSelectedForPriority) {
    case true:
      curItem == -1 && _orderedRows.push(fittingFamily);
      break;
    case false:
      curItem != -1 && _orderedRows.splice(curItem, 1);
      break;
    }
  };

  // this editedRow uses an {id: fittingFamily} structure
  const toggleFamilyField = (fittingFamily, field) => {
    let _editedRows = { ...editedRows };
    let newFamily = convertToSpecFittingFamilyType(fittingFamily);
    newFamily[field] = !newFamily[field];
    _editedRows[fittingFamily.id] = newFamily;
    setEditedRows(_editedRows);
    handleOrderedRowsChange(newFamily);
    setPendingValidChanges(false);
  };

  // Cell Editors
  const SelectItemForPriorityCellBody = ({ fittingFamily }) => <Button icon='star' iconColor={fittingFamily.isSelectedForPriority ? colors.primary[4] : colors.gray[4]} onClick={() => toggleFamilyField(rowdata, 'isSelectedForPriority')} ></Button>;

  const PipingSpecsRowTool = (rowdata) => {
    const curRow = editedRows && editedRows[rowdata.id] || rowdata;

    return reduxProps.isPrioritizedFamilySortingEnabled ?
      // eslint-disable-next-line react/no-unknown-property
      <SelectItemForPriorityCellBody fittingFamily={curRow} /> :
      <Button icon={curRow.isSelectedForSpec ? 'checkbox' : 'checkbox-outline'} iconColor={fittingFamily.isSelectedForPriority ? colors.primary[4] : colors.gray[6]} onClick={() => toggleFamilyField(rowdata, 'isSelectedForSpec')} />;
  };

  const getRowTool = (rowdata) => {
    const data = editedRows && editedRows.find(row => row.id == rowdata.id) || reduxProps.data.find((item) => item.id == rowdata.id);
    return (
      <EntityRowTools rowdata={data} handleRowAction={handleRowAction} />
    );
  };

  useEffect(() => {
    dispatch(processFetchIndividualSpec(match.params.id));
    dispatch(processFetchAllMaterials());
    dispatch(processFetchAllFittingFamilies());
  }, []);

  return (
    <>
      {!reduxProps.isLoading && <Flex flexDirection="row" mb={4} >
        <Header
          isLoading={reduxProps.isLoading}
          title={(reduxProps.spec?.specName && `${reduxProps.spec.specName} ${PipingModuleSpecsPipesEditPath.defaultTitle}`) || 'Loading...'}
          subtitle={`${selectedIds.size} ${pluralize('Selected Pipe', selectedIds.size)} ${isFiltering ? `Showing ${filtered.size}/${data.size} ${pluralize('filtered Pipe', data.size)}` : ''}`}
        />
        <Options
          location={location}
          specId={(match.params.id)}
          updateEntities={() => console.log('clicked to update Entities')}
          pendingValidChanges={pendingValidChanges}
        />
      </Flex> || <Skeleton style={{ height: '2rem', marginBottom: '1rem' }} />
      }
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable
          reorderableColumns
          reorderableRows={reduxProps.isPrioritizedFamilySortingEnabled}
          onRowReorder={(e) => { setOrderedRows(e.value); console.log(e); }}
          value={reduxProps.isPrioritizedFamilySortingEnabled ? orderedRows : reduxProps.data}
          tableStyle={{ minWidth: '50rem' }}
          size='normal'
          rowClassName={(data) => !isPrioritizedFamilySortingEnabled && rowStyles(data, editedRows)}

          scrollable
          scrollHeight='flex'

          removableSort
        >
          {!reduxProps.isPrioritizedFamilySortingEnabled && <Column header='' style={{ width: '3rem' }} body={(rowdata) => getRowTool(rowdata)} ></Column>}
          {reduxProps.isPrioritizedFamilySortingEnabled && <Column rowReorder={(e) => console.log(e)} style={{ width: '3rem' }} ></Column>}
          <Column header='' style={{ width: '2%' }} body={(rowdata) => PipingSpecsRowTool(rowdata, reduxProps.isPrioritizedFamilySortingEnabled)} ></Column>
          <Column field='name' header='Name' style={{ width: '32%' }} body={(rowdata) => textValueBody(rowdata, 'name', '')} sortable ></Column>
          <Column field='instances' header={'Instances'} body={(rowdata) => instanceBody(rowdata)} style={{ width: '32%' }} ></Column>
          <Column field='fittingCode' header={'Fitting Code'} body={(rowdata) => codeBody(rowdata)} style={{ width: '32%' }} ></Column>
          <Column field='fittingCategory' header={'Fitting Category'} body={(rowdata) => categoryBody(rowdata)} style={{ width: '32%' }} ></Column>
        </DataTable>
      </Flex>
    </>
  );
}

export default withRouter(SpecFittingFamiliesGrid);