import { combineReducers } from 'redux-immutable';

import EK from '../../entities/keys';

import createEntitiesReducer from './utils/createEntitiesReducer';

import resetOnLogout from '../../utils/reducers/resetOnLogout';
import createFilteredReducer from '../../utils/reducers/createFilteredReducer';

import AppearanceModel from '../../entities/Appearances/model';
import BendFamilyModel from '../../entities/BendFamilies/model';
import BendInstanceModel from '../../entities/BendInstances/model';
import BendMachineFamilyModel from '../../entities/BendMachineFamilies/model';
import BendMachineInstanceModel from '../../entities/BendMachineInstances/model';
import BendSpringbackFamilyModel from '../../entities/BendSpringbackFamilies/model';
import BendSpringbackInstanceModel from '../../entities/BendSpringbackInstances/model';
import BoltNutFamilyModel from '../../entities/BoltNutFamilies/model';
import BoltNutInstanceModel from '../../entities/BoltNutInstances/model';
import CurrentUserModel from '../../entities/CurrentUser/model';
import EndTypeCompatibilityModel from '../../entities/EndTypeCompatibilities/model';
import EndTypeModel from '../../entities/EndTypes/model';
import FittingCategoryModel from '../../entities/FittingCategories/model';
import FittingCodeModel from '../../entities/FittingCodes/model';
import FittingFamilyModel from '../../entities/FittingFamilies/model';
import FittingInstanceModel from '../../entities/FittingInstances/model';
import InsulationFamilyModel from '../../entities/InsulationFamilies/model';
import InsulationSpecModel from '../../entities/InsulationSpecs/model';
import InsulationInstanceModel from '../../entities/InsulationInstances/model';
import MaterialModel from '../../entities/Materials/model';
import MiterFamilyModel from '../../entities/MiterFamilies/model';
import MiterInstanceModel from '../../entities/MiterInstances/model';
import OrganizationModel from '../../entities/Organizations/model';
import OrganizationUserInviteModel from '../../entities/OrganizationUserInvites/model';
import PipingCatalogModel from '../../entities/PipingCatalogs/model';
import PipeFamilyModel from '../../entities/PipeFamilies/model';
import PipeInstanceModel from '../../entities/PipeInstances/model';
import SpecFittingInstanceModel from '../../entities/SpecFittingInstances/model';
import SpecPipeInstanceModel from '../../entities/SpecPipeInstances/model';
import SpecModel from '../../entities/Specs/model';
import ScheduleModel from '../../entities/Schedules/model';
import LayerModel from '../../entities/Layers/model';
import NoteProfileModel from '../../entities/NoteProfiles/model';
import NoteBlockModel from '../../entities/NoteBlocks/model';
import NoteBlockNoteModel from '../../entities/NoteBlockNotes/model';
import NoteModel from '../../entities/Notes/model';
import OrientationModel from '../../entities/Orientations/model';
import ParameterProfileModel from '../../entities/ParameterProfiles/model';
import ParameterProfileParameterModel from '../../entities/ParameterProfileParameters/model';
import ParameterModel from '../../entities/Parameters/model';
import SectionModel from '../../entities/Sections/model';
import GlobalCadSearchRuleModel from '../../entities/GlobalCadSearchRules/model';
import GlobalCadSearchRuleCriterionModel from '../../entities/GlobalCadSearchRuleCriteria/model';
import SimplifiedRepRulesetRuleCriterionValueModel from '../../entities/SimplifiedRepRulesetRuleCriterionValues/model';
import SimplifiedRepRulesetRuleModel from '../../entities/SimplifiedRepRulesetRules/model';
import SimplifiedRepRulesetModel from '../../entities/SimplifiedRepRulesets/model';
import SimplifiedRepModel from '../../entities/SimplifiedReps/model';
import ModelTemplateModel from '../../entities/ModelTemplates/model';
import SettingModel from '../../entities/Settings/model';
import SizeModel from '../../entities/Sizes/model';
import ProjectModel from '../../entities/Projects/model';
import UnitModel from '../../entities/Units/model';
import UnitSystemModel from '../../entities/UnitSystems/model';
import UserModel from '../../entities/Users/model';
import ExplodeStateModel from '../../entities/ExplodeStates/model';
import CombinedStateModel from '../../entities/CombinedStates/model';
import LayerStateModel from '../../entities/LayerStates/model';
import AppearanceStateModel from '../../entities/AppearanceStates/model';
import StyleStateModel from '../../entities/StyleStates/model';

import MapkeyModel from '../../entities/Mapkeys/model';
import ConfigProfileModel from '../../entities/ConfigProfiles/model';
import ConfigProfileMapkeyModel from '../../entities/ConfigProfileMapkeys/model';
import ConfigProfileValueModel from '../../entities/ConfigProfileValues/model';
import ConfigSettingCategoryModel from '../../entities/ConfigSettingCategories/model';
import ConfigSettingModel from '../../entities/ConfigSettings/model';
import ProfileLibraryModel from '../../entities/ProfileLibraries/model';
import ProfileModel from '../../entities/Profiles/model';
import ProfileDimensionModel from '../../entities/ProfileDimensions/model';
import ProfileParameterModel from '../../entities/ProfileParameters/model';
import ProfileTablesModel from '../../entities/ProfileTables/model';
import ProfileTableColumnModel from '../../entities/ProfileTableColumns/model';
import ProfileTableItemModel from '../../entities/ProfileTableItems/model';
import ProfileTableRowModel from '../../entities/ProfileTableRows/model';

// the 'createEntityReducer is used here to split up the create, edit, and delete actions for each of these reducers
const entitiesReducer = combineReducers({
  [EK.CONFIG_PROFILES.state]: createEntitiesReducer(
    EK.CONFIG_PROFILES.state,
    ConfigProfileModel,
  ),
  [EK.CONFIG_PROFILE_MAPKEYS.state]: createEntitiesReducer(
    EK.CONFIG_PROFILE_MAPKEYS.state,
    ConfigProfileMapkeyModel,
  ),
  [EK.CONFIG_PROFILE_VALUES.state]: createEntitiesReducer(
    EK.CONFIG_PROFILE_VALUES.state,
    ConfigProfileValueModel,
  ),
  [EK.CONFIG_SETTING_CATEGORIES.state]: createEntitiesReducer(
    EK.CONFIG_SETTING_CATEGORIES.state,
    ConfigSettingCategoryModel,
  ),
  [EK.CONFIG_SETTINGS.state]: createEntitiesReducer(
    EK.CONFIG_SETTINGS.state,
    ConfigSettingModel,
  ),

  [EK.APPEARANCES.state]: createEntitiesReducer(
    EK.APPEARANCES.state,
    AppearanceModel,
  ),
  [EK.BENDS.state]: createEntitiesReducer(EK.BENDS.state, BendFamilyModel),
  [EK.BEND_INSTANCES.state]: createEntitiesReducer(
    EK.BEND_INSTANCES.state,
    BendInstanceModel,
  ),
  [EK.BEND_MACHINES.state]: createEntitiesReducer(
    EK.BEND_MACHINES.state,
    BendMachineFamilyModel,
  ),
  [EK.BEND_MACHINE_INSTANCES.state]: createEntitiesReducer(
    EK.BEND_MACHINE_INSTANCES.state,
    BendMachineInstanceModel,
  ),
  [EK.BEND_SPRINGBACKS.state]: createEntitiesReducer(
    EK.BEND_SPRINGBACKS.state,
    BendSpringbackFamilyModel,
  ),
  [EK.BEND_SPRINGBACK_INSTANCES.state]: createEntitiesReducer(
    EK.BEND_SPRINGBACK_INSTANCES.state,
    BendSpringbackInstanceModel,
  ),
  [EK.BOLT_NUTS.state]: createEntitiesReducer(
    EK.BOLT_NUTS.state,
    BoltNutFamilyModel,
  ),
  [EK.BOLT_NUT_INSTANCES.state]: createEntitiesReducer(
    EK.BOLT_NUT_INSTANCES.state,
    BoltNutInstanceModel,
  ),
  [EK.CURRENT_USER.state]: createEntitiesReducer(
    EK.CURRENT_USER.state,
    CurrentUserModel,
  ),
  [EK.END_TYPE_COMPATIBILITIES.state]: createEntitiesReducer(
    EK.END_TYPE_COMPATIBILITIES.state,
    EndTypeCompatibilityModel,
  ),
  [EK.END_TYPES.state]: createEntitiesReducer(EK.END_TYPES.state, EndTypeModel),
  [EK.FITTING_CATEGORIES.state]: createEntitiesReducer(
    EK.FITTING_CATEGORIES.state,
    FittingCategoryModel,
  ),
  [EK.FITTING_CODES.state]: createEntitiesReducer(
    EK.FITTING_CODES.state,
    FittingCodeModel,
  ),
  [EK.FITTING_FAMILIES.state]: createEntitiesReducer(
    EK.FITTING_FAMILIES.state,
    FittingFamilyModel,
  ),
  [EK.FITTING_INSTANCES.state]: createEntitiesReducer(
    EK.FITTING_INSTANCES.state,
    FittingInstanceModel,
  ),
  [EK.INSULATIONS.state]: createEntitiesReducer(
    EK.INSULATIONS.state,
    InsulationFamilyModel,
  ),
  [EK.INSULATION_SPECS.state]: createEntitiesReducer(
    EK.INSULATION_SPECS.state,
    InsulationSpecModel,
  ),
  [EK.INSULATION_INSTANCES.state]: createEntitiesReducer(
    EK.INSULATION_INSTANCES.state,
    InsulationInstanceModel,
  ),
  [EK.MATERIALS.state]: createEntitiesReducer(
    EK.MATERIALS.state,
    MaterialModel,
  ),
  [EK.MITERS.state]: createEntitiesReducer(EK.MITERS.state, MiterFamilyModel),
  [EK.MITER_INSTANCES.state]: createEntitiesReducer(
    EK.MITER_INSTANCES.state,
    MiterInstanceModel,
  ),
  [EK.ORGANIZATIONS.state]: createEntitiesReducer(
    EK.ORGANIZATIONS.state,
    OrganizationModel,
  ),
  [EK.ORGANIZATION_USER_INVITES.state]: createEntitiesReducer(EK.ORGANIZATION_USER_INVITES.state, OrganizationUserInviteModel),
  [EK.PIPING_CATALOGS.state]: createEntitiesReducer(EK.PIPING_CATALOGS.state, PipingCatalogModel),
  [EK.PIPES.state]: createEntitiesReducer(EK.PIPES.state, PipeFamilyModel),
  [EK.PIPE_INSTANCES.state]: createEntitiesReducer(EK.PIPE_INSTANCES.state, PipeInstanceModel),
  [EK.SPEC_FITTING_INSTANCES.state]: createEntitiesReducer(EK.SPEC_FITTING_INSTANCES.state, SpecFittingInstanceModel),
  [EK.SPEC_PIPE_INSTANCES.state]: createEntitiesReducer(EK.SPEC_PIPE_INSTANCES.state, SpecPipeInstanceModel),
  [EK.SPECS.state]: createEntitiesReducer(EK.SPECS.state, SpecModel),
  [EK.PROJECTS.state]: createEntitiesReducer(EK.PROJECTS.state, ProjectModel),
  [EK.SCHEDULES.state]: createEntitiesReducer(EK.SCHEDULES.state, ScheduleModel),
  [EK.LAYERS.state]: createEntitiesReducer(EK.LAYERS.state, LayerModel),
  [EK.MAPKEYS.state]: createEntitiesReducer(EK.MAPKEYS.state, MapkeyModel),
  [EK.NOTE_PROFILES.state]: createEntitiesReducer(EK.NOTE_PROFILES.state, NoteProfileModel),
  [EK.NOTE_BLOCKS.state]: createEntitiesReducer(EK.NOTE_BLOCKS.state, NoteBlockModel),
  [EK.NOTES.state]: createEntitiesReducer(EK.NOTES.state, NoteModel),
  [EK.NOTE_BLOCK_NOTES.state]: createEntitiesReducer(EK.NOTE_BLOCK_NOTES.state, NoteBlockNoteModel),
  [EK.ORIENTATIONS.state]: createEntitiesReducer(EK.ORIENTATIONS.state, OrientationModel),
  [EK.PARAMETER_PROFILES.state]: createEntitiesReducer(EK.PARAMETER_PROFILES.state, ParameterProfileModel),
  [EK.PARAMETER_PROFILE_PARAMETERS.state]: createEntitiesReducer(EK.PARAMETER_PROFILE_PARAMETERS.state, ParameterProfileParameterModel),
  [EK.PARAMETERS.state]: createEntitiesReducer(EK.PARAMETERS.state, ParameterModel),
  [EK.SECTIONS.state]: createEntitiesReducer(EK.SECTIONS.state, SectionModel),
  [EK.GLOBAL_CAD_SEARCH_RULES.state]: createEntitiesReducer(EK.GLOBAL_CAD_SEARCH_RULES.state, GlobalCadSearchRuleModel),
  [EK.GLOBAL_CAD_SEARCH_RULE_CRITERIA.state]: createEntitiesReducer(EK.GLOBAL_CAD_SEARCH_RULE_CRITERIA.state, GlobalCadSearchRuleCriterionModel),
  [EK.SIMPLIFIED_REP_RULESET_RULE_CRITERION_VALUES.state]: createEntitiesReducer(EK.SIMPLIFIED_REP_RULESET_RULE_CRITERION_VALUES.state, SimplifiedRepRulesetRuleCriterionValueModel),
  [EK.SIMPLIFIED_REP_RULESET_RULES.state]: createEntitiesReducer(EK.SIMPLIFIED_REP_RULESET_RULES.state, SimplifiedRepRulesetRuleModel),
  [EK.SIMPLIFIED_REP_RULESETS.state]: createEntitiesReducer(EK.SIMPLIFIED_REP_RULESETS.state, SimplifiedRepRulesetModel),
  [EK.SIMPLIFIED_REPS.state]: createEntitiesReducer(EK.SIMPLIFIED_REPS.state, SimplifiedRepModel),
  [EK.MODEL_TEMPLATES.state]: createEntitiesReducer(EK.MODEL_TEMPLATES.state, ModelTemplateModel),
  [EK.SETTINGS.state]: createEntitiesReducer(EK.SETTINGS.state, SettingModel),
  [EK.SIZES.state]: createEntitiesReducer(EK.SIZES.state, SizeModel),
  [EK.UNITS.state]: createEntitiesReducer(EK.UNITS.state, UnitModel),
  [EK.UNIT_SYSTEMS.state]: createEntitiesReducer(EK.UNIT_SYSTEMS.state, UnitSystemModel,),
  [EK.USERS.state]: createEntitiesReducer(EK.USERS.state, UserModel),
  [EK.EXPLODE_STATES.state]: createEntitiesReducer(EK.EXPLODE_STATES.state, ExplodeStateModel),
  [EK.LAYER_STATES.state]: createEntitiesReducer(EK.LAYER_STATES.state, LayerStateModel),
  [EK.COMBINED_STATES.state]: createEntitiesReducer(EK.COMBINED_STATES.state, CombinedStateModel),
  [EK.APPEARANCE_STATES.state]: createEntitiesReducer(EK.APPEARANCE_STATES.state, AppearanceStateModel),
  [EK.STYLE_STATES.state]: createEntitiesReducer(EK.STYLE_STATES.state, StyleStateModel),
  [EK.PROFILE_LIBRARIES.state]: createEntitiesReducer(EK.PROFILE_LIBRARIES.state, ProfileLibraryModel),
  [EK.PROFILES.state]: createEntitiesReducer(EK.PROFILES.state, ProfileModel),
  [EK.DIMENSIONS.state]: createEntitiesReducer(EK.DIMENSIONS.state, ProfileDimensionModel),
  [EK.PROFILE_PARAMETERS.state]: createEntitiesReducer(EK.PROFILE_PARAMETERS.state, ProfileParameterModel),
  [EK.PROFILE_TABLES.state]: createEntitiesReducer(EK.PROFILE_TABLES.state, ProfileTablesModel),
  [EK.PROFILE_TABLE_COLUMNS.state]: createEntitiesReducer(EK.PROFILE_TABLE_COLUMNS.state, ProfileTableColumnModel),
  [EK.PROFILE_TABLE_ROWS.state]: createEntitiesReducer(EK.PROFILE_TABLE_ROWS.state, ProfileTableRowModel),
  [EK.PROFILE_TABLE_ITEMS.state]: createEntitiesReducer(EK.PROFILE_TABLE_ITEMS.state, ProfileTableItemModel),
});

export default resetOnLogout(
  createFilteredReducer(
    entitiesReducer,
    (action) => !!((action || {}).payload || {}).entities,
  ),
);
