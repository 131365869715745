import { createSelector } from 'reselect';
import { Set } from 'immutable';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../../entities/keys';

import { ENTITY_STATUS } from '../../../modules/entities/constants';

import selectDrafts from '../../../modules/drafts/selectors';

import selectEntities from '../../../modules/entities/selectors';

import { selectNormalizedDraftSections } from '../../../entities/Sections/selectors';

import { selectSidebarIsFiltering } from '../../Dashboard/selectors';


export const selectSections = () =>
  createSelector(selectEntities(), (entities) =>
    entities.get('sections')
  );

export const selectCurrentDraftSectionsPendingValidChanges = () =>
  createSelector(
    selectDrafts(),
    (drafts) =>
      drafts.getIn([EK.SECTIONS.state, 'saveable']).size > 0
  );

export const selectCurrentDraftSectionIds = () =>
  createSelector(selectNormalizedDraftSections(), (drafts) =>
    drafts.reduce((ids, draft) => ids.add(draft.id), Set())
  );

export const selectCurrentEditedDraftSectionsCount = () =>
  createSelector(
    selectNormalizedDraftSections(),
    (drafts) =>
      drafts.filter((draft) => draft.status !== ENTITY_STATUS.LOCAL_DRAFT).size
  );

const sectionFilterForm = formValueSelector(
  `filters.${EK.SECTIONS.state}`
);

export const selectCurrentFilteredNormalizedDraftSections = () =>
  createSelector(
    selectSidebarIsFiltering(),
    selectNormalizedDraftSections(),
    (state) =>
      sectionFilterForm(
        state,
        'name',
        'sectionMethod',
        'datumFeatureName',
        'description'
      ),
    (isFiltering, sections, query) =>
      isFiltering
        ? sections.filter((section) => section.doesMatchQuery(query))
        : sections
  );
