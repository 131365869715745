import { processSaveSizes } from '../../../entities/Sizes/actions';
import { SizesType, SizesUpdateParamsType, SizesCollectionParamsType } from '../../../entities/Sizes/types';

export const saveCurrentSizeChanges = (
  changes: SizesType[],
  saveAction: (data: Array<SizesCollectionParamsType | SizesUpdateParamsType>) => any,
) => {
  const collectionsdata: Array< SizesCollectionParamsType | SizesUpdateParamsType>  = [];

  changes.forEach((item) => {
    if (item.isCreated) {
      collectionsdata.push({ name: item.name, code: item.code, description: item.description } as SizesUpdateParamsType);
    } else if (item.isDeleted) {
      collectionsdata.push({ ...item, _destroy: '1' } as SizesUpdateParamsType);
    } else if (item.isEdited) {
      collectionsdata.push({ ...item } as SizesUpdateParamsType);
    }
  });

  collectionsdata.length > 0 && saveAction(collectionsdata);
};

export const convertToSizesType = ({name, code, description, id, isEdited=false, isCreated=false, isDeleted=false }: any) => {
  return { name, code, description, id, isEdited, isDeleted, isCreated } as SizesType;
};