import { combineReducers } from 'redux-immutable';

import EK from '../../entities/keys';

import createDraftsReducer from './utils/createDraftsReducer';

import resetOnLogout from '../../utils/reducers/resetOnLogout';
import createFilteredReducer from '../../utils/reducers/createFilteredReducer';

import AppearanceModel from '../../entities/Appearances/model';
import BendInstanceModel from '../../entities/BendInstances/model';
import BendMachineInstanceModel from '../../entities/BendMachineInstances/model';
import BendSpringbackInstanceModel from '../../entities/BendSpringbackInstances/model';
import BoltNutInstanceModel from '../../entities/BoltNutInstances/model';
import EndTypeModel from '../../entities/EndTypes/model';
import EndTypeCompatibilityModel from '../../entities/EndTypeCompatibilities/model';
import FittingCategoryModel from '../../entities/FittingCategories/model';
import FittingFamilyModel from '../../entities/FittingFamilies/model';
import FittingInstanceModel from '../../entities/FittingInstances/model';
import InsulationSpecModel from '../../entities/InsulationSpecs/model';
import InsulationInstanceModel from '../../entities/InsulationInstances/model';
import MaterialModel from '../../entities/Materials/model';
import MiterInstanceModel from '../../entities/MiterInstances/model';
import PipeFamilyModel from '../../entities/PipeFamilies/model';
import PipeInstanceModel from '../../entities/PipeInstances/model';
import SpecFittingInstanceModel from '../../entities/SpecFittingInstances/model';
import SpecPipeInstanceModel from '../../entities/SpecPipeInstances/model';
import SpecModel from '../../entities/Specs/model';
import ScheduleModel from '../../entities/Schedules/model';
import LayerModel from '../../entities/Layers/model';
import NoteBlockModel from '../../entities/NoteBlocks/model';
import NoteBlockNoteModel from '../../entities/NoteBlockNotes/model';
import NoteModel from '../../entities/Notes/model';
import OrientationModel from '../../entities/Orientations/model';
import ParameterProfileParameterModel from '../../entities/ParameterProfileParameters/model';
import ParameterModel from '../../entities/Parameters/model';
import SectionModel from '../../entities/Sections/model';
import SettingModel from '../../entities/Settings/model';
import SizeModel from '../../entities/Sizes/model';

const draftsReducer = combineReducers({
  [EK.APPEARANCES.state]: createDraftsReducer(
    EK.APPEARANCES.state,
    AppearanceModel,
  ),
  [EK.BEND_INSTANCES.state]: createDraftsReducer(
    EK.BEND_INSTANCES.state,
    BendInstanceModel,
  ),
  [EK.BEND_MACHINE_INSTANCES.state]: createDraftsReducer(
    EK.BEND_MACHINE_INSTANCES.state,
    BendMachineInstanceModel,
  ),
  [EK.BEND_SPRINGBACK_INSTANCES.state]: createDraftsReducer(
    EK.BEND_SPRINGBACK_INSTANCES.state,
    BendSpringbackInstanceModel,
  ),
  [EK.BOLT_NUT_INSTANCES.state]: createDraftsReducer(
    EK.BOLT_NUT_INSTANCES.state,
    BoltNutInstanceModel,
  ),
  [EK.END_TYPES.state]: createDraftsReducer(EK.END_TYPES.state, EndTypeModel),
  [EK.END_TYPE_COMPATIBILITIES.state]: createDraftsReducer(
    EK.END_TYPE_COMPATIBILITIES.state,
    EndTypeCompatibilityModel,
  ),
  [EK.FITTING_CATEGORIES.state]: createDraftsReducer(
    EK.FITTING_CATEGORIES.state,
    FittingCategoryModel,
  ),
  [EK.FITTING_FAMILIES.state]: createDraftsReducer(
    EK.FITTING_FAMILIES.state,
    FittingFamilyModel,
  ),
  [EK.FITTING_INSTANCES.state]: createDraftsReducer(
    EK.FITTING_INSTANCES.state,
    FittingInstanceModel,
  ),
  [EK.INSULATION_SPECS.state]: createDraftsReducer(
    EK.INSULATION_SPECS.state,
    InsulationSpecModel,
  ),
  [EK.INSULATION_INSTANCES.state]: createDraftsReducer(
    EK.INSULATION_INSTANCES.state,
    InsulationInstanceModel,
  ),
  [EK.MATERIALS.state]: createDraftsReducer(EK.MATERIALS.state, MaterialModel),
  [EK.MITER_INSTANCES.state]: createDraftsReducer(
    EK.MITER_INSTANCES.state,
    MiterInstanceModel,
  ),
  [EK.PIPES.state]: createDraftsReducer(EK.PIPES.state, PipeFamilyModel),
  [EK.PIPE_INSTANCES.state]: createDraftsReducer(
    EK.PIPE_INSTANCES.state,
    PipeInstanceModel,
  ),
  [EK.SPEC_FITTING_INSTANCES.state]: createDraftsReducer(
    EK.SPEC_FITTING_INSTANCES.state,
    SpecFittingInstanceModel,
  ),
  [EK.SPEC_PIPE_INSTANCES.state]: createDraftsReducer(
    EK.SPEC_PIPE_INSTANCES.state,
    SpecPipeInstanceModel,
  ),
  [EK.SPECS.state]: createDraftsReducer(
    EK.SPECS.state,
    SpecModel,
  ),
  [EK.SCHEDULES.state]: createDraftsReducer(EK.SCHEDULES.state, ScheduleModel),
  [EK.LAYERS.state]: createDraftsReducer(
    EK.LAYERS.state,
    LayerModel,
  ),
  [EK.NOTE_BLOCKS.state]: createDraftsReducer(
    EK.NOTE_BLOCKS.state,
    NoteBlockModel,
  ),
  [EK.NOTE_BLOCK_NOTES.state]: createDraftsReducer(
    EK.NOTE_BLOCK_NOTES.state,
    NoteBlockNoteModel,
  ),
  [EK.NOTES.state]: createDraftsReducer(
    EK.NOTES.state,
    NoteModel,
  ),
  [EK.ORIENTATIONS.state]: createDraftsReducer(
    EK.ORIENTATIONS.state,
    OrientationModel,
  ),
  [EK.PARAMETER_PROFILE_PARAMETERS.state]: createDraftsReducer(
    EK.PARAMETER_PROFILE_PARAMETERS.state,
    ParameterProfileParameterModel,
  ),
  [EK.PARAMETERS.state]: createDraftsReducer(
    EK.PARAMETERS.state,
    ParameterModel,
  ),
  [EK.SECTIONS.state]: createDraftsReducer(
    EK.SECTIONS.state,
    SectionModel,
  ),
  [EK.SETTINGS.state]: createDraftsReducer(EK.SETTINGS.state, SettingModel),
  [EK.SIZES.state]: createDraftsReducer(EK.SIZES.state, SizeModel),
});

export default resetOnLogout(
  createFilteredReducer(
    draftsReducer,
    (action) => !!((action || {}).payload || {}).drafts,
  ),
);
