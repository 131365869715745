import { List } from 'immutable';
import pluralize from 'pluralize';

import EntityRecord, { FILTERS } from '../../modules/entities/model';

export const ALLOWANCES = {
  NAME: /^([a-zA-Z0-9_\- ]{0,255})$/,
  SELECTION_NAME: /^([a-zA-Z0-9_\-,'/ ]{0,31})$/,
};

export const VALIDATION_FIELDS = {
  name: true,
  selectionName: true,
  fittingCode: true,
  fittingCategory: true,
};

export const BlankFitting = {
  id: '',
  name: '',
  selectionName: '',
  material: '',
  fittingCodeId: '',
  fittingCategoryId: '',
  boltNutFamily: '',
};

export default class FittingFamily extends EntityRecord({
  name: {
    value: null,
    handleCopy: (entity) => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  selectionName: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  archivedFlag: {
    value: false,
  },
  material: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'materialId',
    filter: FILTERS.ENTITY,
  },
  fittingCode: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'fittingCodeId',
    filter: FILTERS.ENTITY,
  },
  fittingCategory: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'fittingCategoryId',
    filter: FILTERS.ENTITY,
  },
  boltNutFamily: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'boltNutFamilyId',
    filter: FILTERS.ENTITY,
  },
  // used to keep track of fitting instances count without having to denormalize them
  // because the denormalization function deletes the entries if it can't find the records
  fittingInstances: {
    value: List(),
    isEntity: true,
    handleCopy: true,
    handleDeepCopy: true,
    handleCompare: true,
    handleSerializeCollection: true,
    handleDeepSerialize: true,
    serializeOutputField: 'fittingInstancesAttributes',
  },
  fittingInstancesCount: {
    value: 0,
  },
  // used only for simplifying selector on piping specs page
  selectedFittingInstancesForSpec: {
    value: 0,
  },
  isSelectableForPriority: {
    value: false,
  },
  isSelectedForPriority: {
    value: false,
  },
  prioritizationState: {
    value: null,
  },
}) {
  get instancesCount() {
    const count = (this.fittingInstances && this.fittingInstances.size) || 0;
    return `${count} ${pluralize('instance', count)}`;
  }

  get summary() {
    return {
      primary: this.name,
      secondary: this.selectionName,
      tertiary: this.instancesCount,
    };
  }
}
