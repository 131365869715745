import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import selectEntities from '../../modules/entities/selectors';
import EK from '../keys';
import noteProfileSchema from './schema';

export const selectNormalizedNoteProfiles = () =>  createSelector(
  selectEntities(),
  entities => entities.get('noteProfiles')
);
export const selectNormalizedNoteProfilesList = () => createSelector(
  selectNormalizedNoteProfiles(),
  noteProfiles => noteProfiles.toList()
);
export const selectNoteProiles = () =>
  createSelector(selectEntities(), (entities) =>
    denormalize(
      entities.get(EK.NOTE_PROFILES.state),
      [noteProfileSchema],
      entities
    )
  );