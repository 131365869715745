/* eslint-disable prefer-const */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

// Grid Imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

// Hooks
import { useOnCellEditComplete } from '../../../hooks/useOnCellEditComplete';

// cell bodies/editors
import EntityRowTools from '../../../components/common/EntityTools/RowStartTools';
import Text from '../../../components/common/Text';
import { InputText } from 'primereact/inputtext';
import colors from '../../../assets/themes/base/colors';
import { ALLOWANCES } from '../../../entities/InsulationSpecs/model';
import { Dropdown } from 'primereact/dropdown';
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import ColorSwatch from '../../../components/common/ColorSwatch';

// Header
import Flex from '../../../components/common/Flex';
import { PipingModuleInsulationSpecsPath } from '../../../paths';
import Header from '../../templates/Structures/Header';
import Options from '../../templates/Structures/Options';
import Skeleton from 'react-loading-skeleton';
import { pluralize } from '../../../components/common/Header';
import { convertToInsulationSpecsType, saveCurrentInsulationSpecChanges } from './actions';

// validation
import { rowStyles } from '../../../components/primeGrid/RowStyles';

// actions
import { processFetchAllAppearances } from '../../../entities/Appearances/actions';
import { processFetchAllInsulationFamilies } from '../../../entities/InsulationFamilies/actions';
import { processFetchAllInsulationSpecs, processSaveInsulationSpecs } from '../../../entities/InsulationSpecs/actions';

// selectors
import { selectPipingModulePermissionsAndState, selectSidebarIsFiltering } from '../../Dashboard/selectors';
import { selectInsulationFamiliesOptions } from '../../../entities/InsulationFamilies/selectors';
import { selectNormalizedAppearances } from '../../../entities/Appearances/selectors';
import { selectFilteredInsulationSpecs, selectInsulationSpecCount } from '../../../entities/InsulationSpecs/selectors';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectFilteredInsulationSpecs(),
  selectInsulationSpecCount(),
  selectInsulationFamiliesOptions(),
  selectNormalizedAppearances(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    data,
    count,
    insulationFamilies,
    appearances,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.length == 0));
    return {
      ...rest,
      isFiltering,
      isLoading,
      canCollaborate,
      hasValidLicense,
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense,
      count,
      insulationFamilies: insulationFamilies?.toList().toArray() || [],
      appearances: appearances?.toList().toArray() || [],
    };
  },
);

function InsulationSpecGrid ({match}) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  // editing state
  const [editedRows, resetEditedRows, currentEditingRow, isEditing, onBeforeCellEditShow, onCellChange, onCellEditComplete, handleRowAction, onDropdownComplete] = useOnCellEditComplete(reduxProps.data, convertToInsulationSpecsType, reduxProps.editable, true);

  //Cell displays
  const getRowTool = (rowdata) => {
    const data = editedRows && editedRows.find(row => row.id == rowdata.id) || reduxProps.data.find((item) => item.id == rowdata.id);
    return (
      <EntityRowTools rowdata={data} handleRowAction={handleRowAction} />
    );
  };

  const sBody = (rowdata, field, placeholder) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToInsulationSpecsType(rowdata);

    return curRow && curRow[field] ?
      <ComplexCellRenderer value={curRow[field]} isCapitalized={true} paddingLeft='0px' /> :
      <Text className='text-cell-body' style={{ color: colors.gray[5] }}>{placeholder}</Text>;
  };

  const appearanceBody = (rowdata, field, placeholder) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToInsulationSpecsType(rowdata);

    return curRow && curRow[field] ?
      <Flex flexDirection='row' ><ColorSwatch color={curRow[field].tertiary} /><ComplexCellRenderer value={{ primary: curRow[field].primary, secondary: curRow[field].secondary }} isCapitalized={false} placeholder='' /></Flex> :
      <Text className='text-cell-body' style={{ color: colors.gray[5] }}>{placeholder}</Text>;
  };

  const textValueBody = (rowdata, field, placeholder = '') => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToInsulationSpecsType(rowdata);

    return curRow && curRow[`${field}`] ?
      <Text >{curRow[`${field}`]}</Text> :
      <Text style={{ color: colors.gray[5], whiteSpace: 'nowrap' }} >{placeholder}</Text>;
  };

  // Cell Editors
  const textCellEditor = (field) => <InputText value={currentEditingRow && currentEditingRow[field] || ''} onChange={(e) => onCellChange(e.target.value, field)} keyfilter={ALLOWANCES.CATEGORY_NAME} />;

  const insulationDropdown = () =>
    <Dropdown
      value={currentEditingRow.insulationFamily}
      options={reduxProps.insulationFamilies}
      optionLabel='name'
      filter={true}
      placeholder='Select Insulation Family'

      itemTemplate={(item) => ComplexCellRenderer({ value: item, isCapitalized: true })}
      valueTemplate={(item) => ComplexCellRenderer({ value: item, isCapitalized: true })}

      onChange={(e) => onDropdownComplete.current(e.value, 'insulationFamily', currentEditingRow)}
      style={{ width: '100%' }}
    />;

  const appearanceDropdown = () =>
    <Dropdown
      value={currentEditingRow.appearance}
      options={reduxProps.appearances}
      optionLabel='name'
      filter={true}
      placeholder='Select Appearance'

      itemTemplate={(item) => <Flex flexDirection='row' ><ColorSwatch color={item.tertiary} /><ComplexCellRenderer value={{ primary: item.primary, secondary: item.secondary }} isCapitalized={false} placeholder='' /></Flex>}
      valueTemplate={(item) => item && <Flex flexDirection='row' ><ColorSwatch color={item.tertiary} /><ComplexCellRenderer value={{ primary: item.primary, secondary: item.secondary }} isCapitalized={false} placeholder='' /></Flex> || ComplexCellRenderer({ value: item, isCapitalized: true })}

      onChange={(e) => onDropdownComplete.current(e.value, 'appearance', currentEditingRow)}
      style={{ width: '100%' }}
    />;

  useEffect(() => {
    dispatch(processFetchAllInsulationSpecs());
    dispatch(processFetchAllAppearances());
    dispatch(processFetchAllInsulationFamilies());
  }, []);

  return (
    <>
      {!reduxProps.isLoading && <Flex flexDirection="row" mb={4} >
        <Header
          isLoading={reduxProps.isLoading}
          title={PipingModuleInsulationSpecsPath.defaultTitle}
          subtitle={reduxProps.isFiltering ?
            `Showing ${reduxProps.data.length}/${reduxProps.count} ${pluralize('filtered Insulation Spec', reduxProps.count)}` :
            `Showing ${reduxProps.count} ${pluralize('Insulation Spec', reduxProps.count)} Total`}
        />
        <Options
          updateEntities={() => saveCurrentInsulationSpecChanges(
            editedRows,
            (data) => { dispatch(processSaveInsulationSpecs(data)); resetEditedRows(); },
          )}
          isEditingGrid={isEditing}
          isLoading={reduxProps.isLoading}
          pendingValidChanges={editedRows.length > 0}

          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
          canFilter={true}
        />
      </Flex> || <Skeleton style={{ height: '2rem', marginBottom: '1rem' }} />
      }
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable
          reorderableColumns
          value={reduxProps.data}
          tableStyle={{ minWidth: '50rem' }}
          size='normal'
          editMode='cell'
          rowClassName={(data) => rowStyles(data, editedRows)}

          scrollable
          scrollHeight='flex'

          removableSort
        >
          <Column header='' style={{ width: '2%' }} body={(rowdata) => getRowTool(rowdata)} ></Column>
          <Column field='insulationCode' style={{ width: '32%' }} header='Insulation Code' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => textCellEditor('insulationCode')} onCellEditComplete={(e) => onCellEditComplete.current(e)} body={(rowdata) => textValueBody(rowdata, 'insulationCode', 'Insulation Code')} sortable ></Column>
          <Column field='insulationFamily' header={'Insulation'} onBeforeCellEditShow={onBeforeCellEditShow} editor={() => insulationDropdown('insulationFamily')} body={(rowdata) => sBody(rowdata, 'insulationFamily', 'Select Insulation Family')} style={{ width: '32%' }} ></Column>
          <Column field='appearance' header={'Appearance'} onBeforeCellEditShow={onBeforeCellEditShow} editor={() => appearanceDropdown('appearance')} body={(rowdata) => appearanceBody(rowdata, 'appearance', 'Select Appearance')} style={{ width: '32%' }} ></Column>
        </DataTable>
      </Flex>
    </>
  );
}

export default withRouter(InsulationSpecGrid);
