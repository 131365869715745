import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import selectEntities from '../../modules/entities/selectors';
import SpecPipeInstanceSchema from './schema';

export const selectNormalizedSpecPipeInstances = () => createSelector(
  selectEntities(),
  entities => entities.get('specPipeInstances')
);

export const selectCurrentNormalizedSpecPipeInstances = (specId) =>
  createSelector(
    selectNormalizedSpecPipeInstances(),
    (specPipeInstances) =>
      specPipeInstances.filter(
        (instance) => instance.spec === specId
      )
  );