import baseSchema from '../../modules/entities/utils/schema';

import EK from '../keys';

import ScheduleSchema from '../Schedules/schema';
import SizeSchema from '../Sizes/schema';

const boltNutFamilySchema = baseSchema(EK.BOLT_NUTS.state, {
  boltNutInstances: { isId: true, idField: 'boltNutInstanceIds', isList: true },
});

export const boltNutInstanceSchema = baseSchema(EK.BOLT_NUT_INSTANCES.state, {
  schedule: { schema: ScheduleSchema, isId: true },
  sizeProperty: { schema: SizeSchema, isId: true, originalField: 'size' },
  boltNutFamily: { schema: boltNutFamilySchema, isId: true },
});

boltNutFamilySchema.define({ boltNutInstances: [ boltNutInstanceSchema ] });

export default boltNutFamilySchema;
