import React from 'react';

// themes
import colors from '../../../assets/themes/base/colors';

// components
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import Text from '../../../components/common/Text';
import Button from '../../../components/common/Button';
import RowEndEntityTools from '../../../components/common/EntityTools/RowEndTools';
import Icon from '../../../components/common/Icon';
import Flex from '../../../components/common/Flex';

// field validation
// VALIDATION_FIELDS:
//   name
//   selectionName
//   material
//   fittingCode
//   fittingCategory

export function areFieldsValid({ name, boltNutCode }) {
  // takes the currentEditedRow, which is similar to the FittingFamily type
  const _VALIDATION_FIELDS = {
    name: !!name,
    boltNutCode: !!boltNutCode,
  };
  return _VALIDATION_FIELDS;
}

export function doRequiredFieldsExist({ name, boltNutCode }) {
  // takws the VALIDATION_FIELDS type, so all values are booleans
  return !!name && !!boltNutCode;
}

// cell bodies
export const nameBody = (rowdata, onClickAction) => {
  const transformedData = {
    primary: rowdata.name,
    tertiary: rowdata.instancesCount,
    to: true
  };

  return <div onClick={() => onClickAction(rowdata.id)} style={{ cursor: 'pointer' }} ><ComplexCellRenderer value={transformedData} placeholder='' isCapitalized={true} paddingLeft='0px' /></div>;
};

export const codeBody = (rowdata) => <Text>{rowdata.boltNutCode}</Text>;

export const descriptionBody = (rowdata) => {
  if (rowdata.description) {
    return <div style={{ maxWidth: '300px', overflow: 'ellipsis' }} >
      <Text>{rowdata.description}</Text>
    </div>;
  }

  return <Text style={{ color: colors.gray[5] }} >No Description</Text>;
};

// Misc. Dialog Components
export const dialogFooter = (isCreateDialog, isCopyDialog, deleteAction, saveAction, cancelAction) => (
  <>
    {!isCreateDialog && !isCopyDialog && <Button error={true} onClick={deleteAction} style={{ float: 'left' }} >Delete</Button>}
    <Button secondary={true} onClick={cancelAction} >Cancel</Button>
    {!isCopyDialog ?
      <Button primary={true} onClick={saveAction} >Save</Button> :
      <Button primary={true} onClick={saveAction} >Copy</Button>}
  </>
);

export const deleteDialogFooter = (cancelAction, deleteAction) => (
  <>
    <Button secondary={true} onClick={cancelAction} >Cancel</Button>
    <Button error={true} onClick={deleteAction} >Delete</Button>
  </>
);