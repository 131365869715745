import React from 'react';

import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';

import EK from '../../../entities/keys';

import { INSULATION_TYPE_OPTIONS } from '../../../entities/InsulationInstances/model';

import { MATERIAL_FILTERS } from '../../../entities/Materials/model';

import BaseFormRow from '../../../components/form/BaseFormRow';

import FilterFormTemplate from '../../templates/Structures/FilterFormTemplate';

import RDXSearchSelect from '../../../containers/form/RDXSearchSelect';
import RDXTextInput from '../../../containers/form/RDXTextInput';

const FilterForm = props => (
  <FilterFormTemplate {...props}>
    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name='insulationCode'
        label='Insulation Code'
        type='text'
      />
    </BaseFormRow>
    <BaseFormRow>
      <Field
        component={RDXSearchSelect}
        name='insulationFamily'
        label='Insulation Family'
        ek={EK.INSULATIONS}
        clearable
      />
    </BaseFormRow>
    <BaseFormRow>
      <Field
        component={RDXSearchSelect}
        name='appearance'
        label='Appearance'
        ek={EK.APPEARANCES}
        clearable
      />
    </BaseFormRow>
  </FilterFormTemplate>
);

const enhance = compose(
  reduxForm({ form: `filters.${EK.INSULATION_SPECS.state}` })
);

export default enhance(FilterForm);
