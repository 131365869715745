import EK from '../keys';

import baseSchema from '../../modules/entities/utils/schema';

import AppearanceSchema from '../Appearances/schema';
import BoltNutFamilySchema from '../BoltNutFamilies/schema';
import FittingInstanceSchema from '../FittingInstances/schema';
import InsulationSpecSchema from '../InsulationSpecs/schema';
import PipeInstanceSchema from '../PipeInstances/schema';
import UserSchema from '../Users/schema';
import pipeFamilySchema from '../PipeFamilies/schema';

const specFittingInstanceSchema = baseSchema(EK.SPEC_FITTING_INSTANCES.state, {
  boltNutFamily: { schema: BoltNutFamilySchema, isId: true },
  fittingInstance: { schema: FittingInstanceSchema, isId: true },
  spec: { isId: true }, // don't define schema, because it needs to be defined later
});

const specPipeInstanceSchema = baseSchema(EK.SPEC_PIPE_INSTANCES.state, {
  pipeInstance: { schema: PipeInstanceSchema, isId: true },
  pipeFamilyId: { schema: pipeFamilySchema, isId: true },
  spec: { isId: true }, // don't define schema, because it needs to be defined later
});

const projectSchema = baseSchema(EK.PROJECTS.state, {
  createdBy: { schema: UserSchema },
});

const specDefaultAppearanceSchema = baseSchema(EK.APPEARANCES.state, {
  defaultAppearance: { schema: AppearanceSchema }
});

const specSchema = baseSchema(EK.SPECS.state, {
  specPipeInstances: { schema: [specPipeInstanceSchema], isId: true, idField: 'specPipeInstanceIds', isList: true },
  specFittingInstances: { schema: [specFittingInstanceSchema], isId: true, idField: 'specFittingInstanceIds', isList: true },
  prioritizedPipeFamilies: { schema: [pipeFamilySchema], isId: true, isList: true },
  appearance: { schema: specDefaultAppearanceSchema, isId: true },
  defaultInsulationSpec: { schema: InsulationSpecSchema, isId: true },
  projects: { schema: [projectSchema] },
});

specFittingInstanceSchema.define({ spec: specSchema });

specPipeInstanceSchema.define({ spec: specSchema });

projectSchema.define({ specs: [specSchema] });

export {
  specSchema,
  specFittingInstanceSchema,
  specPipeInstanceSchema,
};

export default projectSchema;
