import React from 'react';

import { withTheme } from 'styled-components';

import MaterialIconAdd from '../../icons/material/Add';
import MaterialIconApps from '../../icons/material/Apps';
import MaterialIconArchive from '../../icons/material/Archive';
import MaterialIconAssignment from '../../icons/material/Assignment';
import MaterialIconCheck from '../../icons/material/Check';
import MaterialIconCheckboxIndeterminate from '../../icons/material/CheckboxIndeterminate';
import MaterialIconCheckboxOutline from '../../icons/material/CheckboxOutline';
import MaterialIconCheckbox from '../../icons/material/Checkbox';
import MaterialIconChevronDown from '../../icons/material/ChevronDown';
import MaterialIconChevronLeft from '../../icons/material/ChevronLeft';
import MaterialIconChevronRight from '../../icons/material/ChevronRight';
import MaterialIconChevronUp from '../../icons/material/ChevronUp';
import MaterialIconClose from '../../icons/material/Close';
import MaterialIconCloudDone from '../../icons/material/CloudDone';
import MaterialIconCloudOff from '../../icons/material/CloudOff';
import MaterialIconCloudQueue from '../../icons/material/CloudQueue';
import MaterialIconCloudUpload from '../../icons/material/CloudUpload';
import MaterialIconCloud from '../../icons/material/Cloud';
import MaterialIconCopy from '../../icons/material/Copy';
import MaterialIconDelete from '../../icons/material/Delete';
import MaterialIconDynamicFeed from '../../icons/material/DynamicFeed';
import MaterialIconEdit from '../../icons/material/Edit';
import MaterialIconEnter from '../../icons/material/Enter';
import MaterialIconErrorOutline from '../../icons/material/ErrorOutline';
import MaterialIconFilter from '../../icons/material/Filter';
import MaterialIconGetApp from '../../icons/material/GetApp';
import MaterialIconHelp from '../../icons/material/Help';
import MaterialIconHistory from '../../icons/material/History';
import MaterialIconHome from '../../icons/material/Home';
import MaterialIconInbox from '../../icons/material/Inbox';
import MaterialIconInfoOutline from '../../icons/material/InfoOutline';
import MaterialIconList from '../../icons/material/List';
import MaterialIconLock from '../../icons/material/Lock';
import MaterialIconM from '../../icons/material/M';
import MaterialIconMailOutline from '../../icons/material/MailOutline';
import MaterialIconMenu from '../../icons/material/Menu';
import MaterialIconMoreVert from '../../icons/material/MoreVert';
import MaterialIconMoveToInbox from '../../icons/material/MoveToInbox';
import MaterialIconNoteAdd from '../../icons/material/NoteAdd';
import MaterialIconOrganization from '../../icons/material/Organization';
import MaterialIconPowerSettings from '../../icons/material/PowerSettings';
import MaterialIconRadioOutline from '../../icons/material/RadioOutline';
import MaterialIconRadio from '../../icons/material/Radio';
import MaterialIconReset from '../../icons/material/Reset';
import MaterialIconSearch from '../../icons/material/Search';
import MaterialIconSettings from '../../icons/material/Settings';
import MaterialIconSortAsc from '../../icons/material/SortAsc';
import MaterialIconSortDesc from '../../icons/material/SortDesc';
import MaterialIconStar from '../../icons/material/Star';
import MaterialIconSupervisorAccount from '../../icons/material/SupervisorAccount';
import MaterialIconUnarchive from '../../icons/material/Unarchive';
import MaterialIconUndo from '../../icons/material/Undo';
import MaterialIconVerifiedUser from '../../icons/material/VerifiedUser';
import MaterialIconViewList from '../../icons/material/ViewList';
import MaterialIconVisibleOff from '../../icons/material/VisibleOff';
import MaterialIconVisible from '../../icons/material/Visible';
import MaterialIconWork from '../../icons/material/Work';
import PtcIconFitAssembly from '../../icons/ptc/FitAssembly';
import PtcIconFitBranch from '../../icons/ptc/FitBranch';
import PtcIconFitElbow from '../../icons/ptc/FitElbow';
import PtcIconFitFlange from '../../icons/ptc/FitFlange';
import PtcIconFitGeneral from '../../icons/ptc/FitGeneral';
import PtcIconFitHoseElb from '../../icons/ptc/FitHoseElb';
import PtcIconFitHoseStr from '../../icons/ptc/FitHoseStr';
import PtcIconFitNobreak from '../../icons/ptc/FitNobreak';
import PtcIconFitRed from '../../icons/ptc/FitRed';
import PtcIconFitValve from '../../icons/ptc/FitValve';
import ToggleSwitch from '../../icons/material/SwitchIcon';
import ToggleFlag from '../../icons/material/ChangeHistory';
import MaterialFolder from '../../icons/material/Folder';
import MaterialFolderOpen from '../../icons/material/FolderOpen';
import CircleIcon from '../../icons/material/CircleIcon';


const ICONS_BY_NAME = {
  ptc: {
    fit_assembly: PtcIconFitAssembly,
    fit_branch: PtcIconFitBranch,
    fit_elbow: PtcIconFitElbow,
    fit_flange: PtcIconFitFlange,
    fit_general: PtcIconFitGeneral,
    fit_hose_elb: PtcIconFitHoseElb,
    fit_hose_str: PtcIconFitHoseStr,
    fit_nobreak: PtcIconFitNobreak,
    fit_red: PtcIconFitRed,
    fit_valve: PtcIconFitValve,
  },
  material: {
    add: MaterialIconAdd,
    apps: MaterialIconApps,
    archive: MaterialIconArchive,
    assignment: MaterialIconAssignment,
    check: MaterialIconCheck,
    ['checkbox-indeterminate']: MaterialIconCheckboxIndeterminate,
    ['checkbox-outline']: MaterialIconCheckboxOutline,
    checkbox: MaterialIconCheckbox,
    ['chevron-down']: MaterialIconChevronDown,
    ['chevron-left']: MaterialIconChevronLeft,
    ['chevron-right']: MaterialIconChevronRight,
    ['chevron-up']: MaterialIconChevronUp,
    toggleFlag: ToggleFlag,
    close: MaterialIconClose,
    ['cloud-done']: MaterialIconCloudDone,
    ['cloud-off']: MaterialIconCloudOff,
    ['cloud-queue']: MaterialIconCloudQueue,
    ['cloud-upload']: MaterialIconCloudUpload,
    cloud: MaterialIconCloud,
    copy: MaterialIconCopy,
    delete: MaterialIconDelete,
    ['dynamic-feed']: MaterialIconDynamicFeed,
    edit: MaterialIconEdit,
    enter: MaterialIconEnter,
    ['error-outline']: MaterialIconErrorOutline,
    filter: MaterialIconFilter,
    ['get-app']: MaterialIconGetApp,
    help: MaterialIconHelp,
    history: MaterialIconHistory,
    home: MaterialIconHome,
    inbox: MaterialIconInbox,
    ['info-outline']: MaterialIconInfoOutline,
    list: MaterialIconList,
    lock: MaterialIconLock,
    m: MaterialIconM,
    ['mail-outline']: MaterialIconMailOutline,
    menu: MaterialIconMenu,
    ['more-vert']: MaterialIconMoreVert,
    ['move-to-inbox']: MaterialIconMoveToInbox,
    ['note-add']: MaterialIconNoteAdd,
    organization: MaterialIconOrganization,
    ['power-settings']: MaterialIconPowerSettings,
    ['radio-outline']: MaterialIconRadioOutline,
    radio: MaterialIconRadio,
    reset: MaterialIconReset,
    search: MaterialIconSearch,
    settings: MaterialIconSettings,
    ['sort-asc']: MaterialIconSortAsc,
    ['sort-desc']: MaterialIconSortDesc,
    star: MaterialIconStar,
    ['supervisor-account']: MaterialIconSupervisorAccount,
    unarchive: MaterialIconUnarchive,
    undo: MaterialIconUndo,
    ['verified-user']: MaterialIconVerifiedUser,
    ['view-list']: MaterialIconViewList,
    ['visible-off']: MaterialIconVisibleOff,
    visible: MaterialIconVisible,
    work: MaterialIconWork,
    toggleSwitch: ToggleSwitch,
    folder: MaterialFolder,
    folderOpen: MaterialFolderOpen,
    circle: CircleIcon
  },
};


function getFillColor(colorQuery, theme) {
  if (!colorQuery) return undefined;
  const [colorKey, shadeKey] = colorQuery?.split('.');
  return theme.colors[colorKey][shadeKey];
}

function Icon({ name, style = 'material', className, theme, color = 'gray.6', fontSize: themeFontSize, value, iconStyle = {}, ...props }, ref) {
  const IconComponent = ICONS_BY_NAME[style][name];
  // TODO: do something less surgical
  const fill = getFillColor(color, theme);
  const fontSize = theme.fontSizes[themeFontSize];

  return (
    <IconComponent ref={ref} {...props} style={{fontSize, fill, ...iconStyle}} value={value} />
  );
}

export default React.memo(withTheme(React.forwardRef(Icon)));