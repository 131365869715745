import { createAction } from 'redux-actions';

import { generateCreateDraftsAction, generateEditDraftsAction } from '../../../modules/drafts/actions';

import {
  CREATE_PARAMETER_PROFILE_PARAMETER_DRAFTS,
  EDIT_PARAMETER_PROFILE_PARAMETER_DRAFTS,
  UPDATE_PARAMETER_PROFILE_PARAMETERS_COLLECTION,
} from './constants';

export const createParameterProfileParameterDrafts = generateCreateDraftsAction(CREATE_PARAMETER_PROFILE_PARAMETER_DRAFTS);

export const editParameterProfileParameterDrafts = generateEditDraftsAction(EDIT_PARAMETER_PROFILE_PARAMETER_DRAFTS);

export const updateParameterProfileParametersCollection = createAction(UPDATE_PARAMETER_PROFILE_PARAMETERS_COLLECTION, parameterProfileId => ({ parameterProfileId }));
