import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { List } from 'immutable';

import EK from '../keys';

import selectEntities from '../../modules/entities/selectors';
import SpecSchema from './schema';
import { selectCurrentFilteredPipes } from '../PipeFamilies/selectors';
import { selectisPrioritizedFamilySortingEnabled } from '../../routes/SpecsEditSwitch/selectors';
import { selectCurrentFilteredFittings } from '../FittingFamilies/selectors';

import { convertToSpecPipeFamilyType } from '../../routes/Piping/SpecsPipesEdit/actions';

export const selectNormalizedSpecs = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.SPECS.state)
);

export const selectNormalizedUnarchivedSpecs = () => createSelector(
  selectNormalizedSpecs(),
  specs => {
    return specs.toList().filter(item => !item.archivedFlag);
  }
);

export const selectSpecsCounts = () => createSelector(
  selectNormalizedSpecs(),
  specs => specs.reduce(({ unarchived, total }, spec) => ({
    unarchived: !spec.archivedFlag ? unarchived + 1 : unarchived,
    total: total + 1,
  }), {
    unarchived: 0,
    total: 0,
  })
);

export const selectSpecs = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get(EK.SPECS.state), [SpecSchema], entities.delete(EK.SPEC_PIPE_INSTANCES.state).delete(EK.SPEC_FITTING_INSTANCES.state))
);
export const selectSpecsList = () => createSelector(
  selectSpecs(),
  specs => specs.toList()
);

export const selectCurrentSpec = (specId) => createSelector(
  selectEntities(),
  (entities) => entities.hasIn([EK.SPECS.state, specId]) && denormalize(entities.getIn([EK.SPECS.state, specId]), SpecSchema, entities)
);

export function removeDuplicateFromObjectIdArray(arr) {
  // borrowed this from https://www.geeksforgeeks.org/how-to-get-all-unique-values-remove-duplicates-in-a-javascript-array/, it's pretty slick.
  let outputArray = arr.filter(function (v, i, self) {
    return v?.id && i == self.findIndex((item) => item?.id == v.id);
  });

  return outputArray;
}

export const selectOrderedFilteredUnarchivedPipeFamilies = (specId) =>
  createSelector(
    selectCurrentSpec(specId),
    selectCurrentFilteredPipes(),
    selectisPrioritizedFamilySortingEnabled(),
    (
      spec,
      pipes,
      isPrioritizedFamilySortingEnabled
    ) => {
      let ordered = spec && spec.prioritizedPipeFamilies?.map( (item, index)=> {
        if (item && item.id) {
          return {
            id: item.id,
            isSelectedForSpec: true,
            isPrioritized: true,
            name: item.name,
            material: item.material,
            pipeInstances: item.pipeInstances,
            selectedPipeInstancesForSpec: item.pipeInstances?.count(),
            index: index,
            isEdited: false,
            isCreated: false,
            isDeleted: false,
          };
        }
      }
      ) || []; // list of pipe families from the ordered list
      let pipeArray = pipes && pipes.toList().toArray().map((item) => {
        if (item && item.id) {
          return {
            id: item.id,
            isSelectedForSpec: false,
            isPrioritized: false,
            name: item.name,
            material: item.material,
            pipeInstances: item.pipeInstances,
            selectedPipeInstancesForSpec: item.pipeInstances?.count(),
            index: -1,
            isEdited: false,
            isCreated: false,
            isDeleted: false,
          };
        }
      }); // all pipe families
      let specPipeArray = spec.specPipeInstances && removeDuplicateFromObjectIdArray(spec.specPipeInstances.toArray().map(item => {
        if (item && item.pipeFamilyId?.id) {
          return {
            id: item.pipeFamilyId.id,
            isSelectedForSpec: true,
            isPrioritized: false,
            name: item.pipeFamilyId.name,
            material: item.pipeFamilyId.material,
            pipeInstances: item.pipeFamilyId.pipeInstances,
            selectedPipeInstancesForSpec: item.pipeFamilyId.pipeInstances?.count(),
            index: -1,
            isEdited: false,
            isCreated: false,
            isDeleted: false,
          };
        }
      })) || []; // list of all pipe family ids not in the ordered list
      ordered = removeDuplicateFromObjectIdArray(ordered.concat(specPipeArray));
      return isPrioritizedFamilySortingEnabled ?
        ordered :
        pipeArray && removeDuplicateFromObjectIdArray(ordered.concat(pipeArray)) || ordered;
    }
  );

export const selectOrderedFilteredUnarchivedFittingFamilies = (specId) =>
  createSelector(
    selectCurrentSpec(specId),
    selectCurrentFilteredFittings(),
    selectisPrioritizedFamilySortingEnabled(),
    (
      spec,
      fittings,
      isPrioritizedFamilySortingEnabled
    ) => {
      let ordered = spec && spec.prioritizedFittingFamilies || []; // list of fitting families from the ordered list
      let fittingArray = fittings && fittings.toList().toArray(); // all fitting families
      let specFittingArray = spec.specFittingInstances && removeDuplicateFromObjectIdArray(spec.specFittingInstances.toArray().map(item => item.fittingFamilyId)) || []; // list of all fitting family ids not in the ordered list

      ordered = removeDuplicateFromObjectIdArray(ordered.concat(specFittingArray));

      return isPrioritizedFamilySortingEnabled ?
        ordered :
        fittingArray && removeDuplicateFromObjectIdArray(ordered.concat(fittingArray)) || ordered;
    }
  );
