import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

//Prime React
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';

//Components
import {
  dialogFooter,
  deleteDialogFooter,
  nameBody,
  areFieldsValid,
  doRequiredFieldsExist
} from './components';

import Header from './Header';
import Options from '../../templates/Structures/Options';
import Flex from '../../../components/common/Flex/index';
import Box from '../../../components/common/Box/index';
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import TextCell from '../../../components/grid/TextCell';
import RowEndEntityTools from '../../../components/common/EntityTools/RowEndTools';

//Selectors
import {
  selectSidebarIsFiltering,
  selectStandardizeModulePermissionsAndState,
} from '../../Dashboard/selectors';

import { selectIsShowingArchived } from '../../../modules/query/selectors';
import { selectDenormalizedModelTemplatesList } from '../../../entities/ModelTemplates/selectors';
import { selectParameterProfiles } from '../../../entities/ParameterProfiles/selectors';
import { selectNoteProiles } from '../../../entities/NoteProfiles/selectors';
import { selectOrientations } from '../../../entities/Orientations/selectors.js';
import { selectSimplifiedReps } from '../../../entities/SimplifiedReps/selectors';
import { selectLayers } from '../../../entities/Layers/selectors';
import { selectSections } from '../../../entities/Sections/selectors';

//Actions
import {
  processFetchAllModelTemplates,
  processCreateModelTemplate,
  processCopyModelTemplate,
  processEditModelTemplate,
  processDeleteModelTemplate,
  processArchiveModelTemplates,
  processUnarchiveModelTemplates,
} from '../../../entities/ModelTemplates/actions';

import { processFetchAllNoteProfiles } from '../../../entities/NoteProfiles/actions';
import { processFetchAllOrientations } from '../../../entities/Orientations/actions.js';
import { processFetchAllSimplifiedReps } from '../../../entities/SimplifiedReps/actions';
import { processFetchAllLayers } from '../../../entities/Layers/actions';
import { processFetchAllParameterProfiles } from '../../../entities/ParameterProfiles/actions.js';
import { processFetchAllSections } from '../../../entities/Sections/actions.js';

//Constants
import {
  CAD_MODEL_TYPE_OPTIONS,
  CAD_MODEL_ASSEMBLY_SUBTYPE_OPTIONS,
  CAD_MODEL_PART_SUBTYPE_OPTIONS,
} from '../../../entities/ModelTemplates/model';

const mapStateToProps = createSelector(
  selectStandardizeModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectSections(),
  selectLayers(),
  selectSimplifiedReps(),
  selectOrientations(),
  selectNoteProiles(),
  selectParameterProfiles(),
  selectDenormalizedModelTemplatesList(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isShowingArchived,
    sections,
    layers,
    simplifiedReps,
    orientations,
    noteProfiles,
    parameterProfiles,
    data
  ) => {
    const isLoading =
      isLoadingInitialData || (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      sections: (!isLoading && sections && sections.toList().toArray()) || [],
      layers: (!isLoading && layers && layers.toList().toArray()) || [],
      simplifiedReps: (!isLoading && simplifiedReps && simplifiedReps.toList().toArray()) || [],
      orientations: (!isLoading && orientations && orientations.toList().toArray()) || [],
      noteProfiles: (!isLoading && noteProfiles && noteProfiles.toList().toArray()) || [],
      parameterProfiles:
        (!isLoading && parameterProfiles && parameterProfiles.toList().toArray()) || [],
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense,
    };
  }
);

const BLANK_TEMPLATE = {
  name: '',
  modelTemplateModelName: '',
  cadModelType: '',
  cadModelAssemblySubtype: '',
  cadModelPartSubtype: '',
  modelParameterProfile: '',
  componentParameterProfile: '',
  noteProfile: '',
  orientations: '',
  layers: '',
  sections: '',
  simplifiedReps: '',
};


const SET_VALIDATION_FIELDS = ({cadModelType}) => {
  return {
    name: true,
    modelTemplateModelName: true,
    cadModelType: true,
    ...(cadModelType === 'assembly' ? {cadModelAssemblySubtype: true} : {}),
    ...(cadModelType === 'part' ? {cadModelPartSubtype: true} : {}),
  };
};

export default function (props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const [isDialogOpen, setIsDialogOpen] = useState(false); // controls if the create/edit/copy dialog is open
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false); // used to determine header text and select 'save' action
  const [isCopyDialogOpen, setIsCopyDialogOpen] = useState(false); // used to determine header text and select 'save' action
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false); // controls if the delete Dialog is open
  const [currentEditingRow, setCurrentEditingRow] = useState(BLANK_TEMPLATE);


  const [validation, setValidation] = useState(SET_VALIDATION_FIELDS(currentEditingRow));


  const getDialogHeader = () => {
    if (isCreateDialogOpen) {
      return 'Create Template';
    } else if (isCopyDialogOpen) {
      return `Create Copy of '${currentEditingRow.name}'`;
    } else {
      return `Edit '${currentEditingRow.name}'`;
    }
  };

  const openCreateDialog = () => {
    setCurrentEditingRow(BLANK_TEMPLATE);
    setIsCreateDialogOpen(true);
    setIsDialogOpen(true);
  };

  const cancelDialogs = () => {
    // used to reset the dialogs
    setCurrentEditingRow(BLANK_TEMPLATE);
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsCopyDialogOpen(false);
    setIsDeleteDialogOpen(false);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setCurrentEditingRow(BLANK_TEMPLATE);
  };

  const saveAction = () => {

    setValidation(areFieldsValid(currentEditingRow));
    if (!doRequiredFieldsExist(currentEditingRow)) {
      return;
    }
    const editedRow = {
      id: currentEditingRow?.id,
      name: currentEditingRow?.name,
      modelTemplateModelName: currentEditingRow?.modelTemplateModelName,
      cadModelType: currentEditingRow?.cadModelType,
      cadModelAssemblySubtype: currentEditingRow?.cadModelAssemblySubtype,
      cadModelPartSubtype: currentEditingRow?.cadModelPartSubtype,
      modelParameterProfileId: currentEditingRow?.modelParameterProfile?.id,
      componentParameterProfileId: currentEditingRow?.componentParameterProfile?.id,
      noteProfileId: currentEditingRow?.noteProfile?.id,
      orientationIds: currentEditingRow?.orientations?.map(item=>item?.id),
      layerIds: currentEditingRow?.layers?.map(item=>item?.id),
      sectionIds: currentEditingRow?.sections?.map(item=>item?.id),
      simplifiedRepIds: currentEditingRow?.simplifiedReps?.map(item=>item?.id),
    };

    if (isCreateDialogOpen) {
      dispatch(processCreateModelTemplate(editedRow));
    } else if (isCopyDialogOpen) {
      dispatch(processCopyModelTemplate(editedRow));
    } else {
      dispatch(processEditModelTemplate(editedRow.id, editedRow));
    }
    cancelDialogs();
  };

  const editRowAction = (rowdata) => {
    const currentNonEntityRow = {
      id: rowdata.id,
      name: rowdata.name,
      modelTemplateModelName: rowdata.modelTemplateModelName,
      cadModelType: rowdata.cadModelType,
      cadModelAssemblySubtype: rowdata.cadModelAssemblySubtype,
      cadModelPartSubtype: rowdata.cadModelPartSubtype,
      modelParameterProfile: rowdata.modelParameterProfile,
      componentParameterProfile: rowdata.componentParameterProfile,
      noteProfile: rowdata.noteProfile,
      orientations: rowdata.orientations.toArray(),
      layers: rowdata.layers.toArray(),
      sections: rowdata.sections.toArray(),
      simplifiedReps: rowdata.simplifiedReps.toArray(),
    };
    setCurrentEditingRow(currentNonEntityRow);
    setIsDialogOpen(true);
  };

  const copyRowAction = (rowdata) => {
    const currentNonEntityRow = {
      id: rowdata.id,
      name: rowdata.name,
      modelTemplateModelName: rowdata.modelTemplateModelName,
      cadModelType: rowdata.cadModelType,
      cadModelAssemblySubtype: rowdata.cadModelAssemblySubtype,
      cadModelPartSubtype: rowdata.cadModelPartSubtype,
      modelParameterProfile: rowdata.modelParameterProfile,
      componentParameterProfile: rowdata.componentParameterProfile,
      noteProfile: rowdata.noteProfile,
      orientations: rowdata.orientations.toArray(),
      layers: rowdata.layers.toArray(),
      sections: rowdata.sections.toArray(),
      simplifiedReps: rowdata.simplifiedReps.toArray(),
    };
    setCurrentEditingRow(currentNonEntityRow);
    setIsCopyDialogOpen(true);
    setIsDialogOpen(true);
  };

  const archiveAction = (rowdata) => {
    dispatch(processArchiveModelTemplates([rowdata.id]));
  };

  const unarchiveAction = (rowdata) => {
    dispatch(processUnarchiveModelTemplates([rowdata.id]));
  };

  const deleteAction = () => {
    // this action is PERMANENT, never call directly off of a button, always use the modal
    dispatch(processDeleteModelTemplate(currentEditingRow.id));
    setCurrentEditingRow(BLANK_TEMPLATE);
    setIsDeleteDialogOpen(false);
  };

  const openDeleteDialog = () => {
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsCopyDialogOpen(false);
    setIsDeleteDialogOpen(true);
  };

  const handleEditedRowChange = (newValue, field) => {
    // called on every edit
    let _editedRow = { ...currentEditingRow };
    _editedRow[`${field}`] = newValue;
    setCurrentEditingRow(_editedRow);
  };

  useEffect(() => {
    dispatch(processFetchAllModelTemplates());
    dispatch(processFetchAllParameterProfiles());
    dispatch(processFetchAllNoteProfiles());
    dispatch(processFetchAllOrientations());
    dispatch(processFetchAllSimplifiedReps());
    dispatch(processFetchAllLayers());
    dispatch(processFetchAllSections());
  }, []);

  return (
    <>
      <Flex flexDirection='row' mb={4}>
        <Header/>
        <Options
          isLoading={reduxProps.isLoading}
          createEntity={openCreateDialog}
          shouldHaveLicense={false}
          canCollaborate={reduxProps.canCollaborate}
        />
      </Flex>
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable value={reduxProps.data} tableStyle={{ minWidth: '50rem' }} size='small' stripedRows scrollable scrollHeight='flex' >
          <Column field='name' header='Name' bodyStyle={{fontSize: '14px'}} style={{ width: '20%'}} body={(rowData) => nameBody(rowData, (rowData)=>editRowAction(reduxProps.data.find(template => template.id === rowData)))}></Column>
          <Column field='modelTemplateModelName' header='Model Name' body={(rowData=>TextCell(rowData.modelTemplateModelName))} bodyStyle={{fontSize: '14px'}} style={{ width: '20%'}}></Column>
          <Column field='cadModelType' header='Model Type' body={(rowData=>TextCell(rowData.cadModelType))} bodyStyle={{fontSize: '14px'}} style={{ width: '20%'}}></Column>
          <Column header=''
            bodyStyle={{fontSize: '14px'}}
            style={{textAlign: 'right', width: '20%'}}
            body={(rowData) =>
              <RowEndEntityTools
                rowdata={rowData}
                editAction={editRowAction}
                copyAction={copyRowAction}
                archiveAction={archiveAction}
                unarchiveAction={unarchiveAction}
              />}
          ></Column>
        </DataTable>
      </Flex>
      <Dialog visible={reduxProps.editable && isDialogOpen} style={{ width: '75%' }} header={()=>getDialogHeader()} footer={() => dialogFooter(isCreateDialogOpen, isCopyDialogOpen, openDeleteDialog, saveAction, cancelDialogs)} modal className='p-fluid' closable={false} >
        <label>name</label>
        <InputText value={currentEditingRow.name} onChange={(e) => handleEditedRowChange(e.target.value, 'name')} className={!validation.name && 'p-invalid'} />
        <label>modelTemplateModelName</label>
        <InputText value={currentEditingRow.modelTemplateModelName} onChange={(e) => handleEditedRowChange(e.target.value, 'modelTemplateModelName')} className={!validation.modelTemplateModelName && 'p-invalid'} />
        {!isCopyDialogOpen &&
          <>
            <label>cadModelType</label>
            <Dropdown value={currentEditingRow.cadModelType} options={CAD_MODEL_TYPE_OPTIONS} optionLabel='label' optionValue='value' onChange={(e) => handleEditedRowChange(e.value, 'cadModelType')} className={!validation.cadModelType && 'p-invalid'} />
          </>
        }
        {currentEditingRow.cadModelType === 'assembly' &&
        !isCopyDialogOpen &&
          <>
            <label>cadModelAssemblySubtype</label>
            <Dropdown value={currentEditingRow.cadModelAssemblySubtype} options={CAD_MODEL_ASSEMBLY_SUBTYPE_OPTIONS} onChange={(e) => handleEditedRowChange(e.value, 'cadModelAssemblySubtype')} className={!validation.cadModelType && 'p-invalid'} />
          </>
        }
        {currentEditingRow.cadModelType === 'part' &&
        !isCopyDialogOpen &&
          <>
            <label>cadModelPartSubtype</label>
            <Dropdown value={currentEditingRow.cadModelPartSubtype} options={CAD_MODEL_PART_SUBTYPE_OPTIONS} onChange={(e) => handleEditedRowChange(e.value, 'cadModelPartSubtype')} className={!validation.name && 'p-invalid'} />
          </>
        }
        <Flex justifyContent='space-between'>
          <Box width={'48%'} marginRight={1}>
            <label>modelParameterProfile</label>
            <Dropdown
              value={currentEditingRow.modelParameterProfile}
              options={reduxProps.parameterProfiles}
              optionLabel='name'
              onChange={(e) => handleEditedRowChange(e.value, 'modelParameterProfile')}
              itemTemplate={(item) => <Flex flexDirection='row'><ComplexCellRenderer value={{ primary: item?.primary }} isCapitalized={false} placeholder=''/></Flex>}
              valueTemplate={(item) => <Flex flexDirection='row'><ComplexCellRenderer value={{ primary: item?.primary }} isCapitalized={false} placeholder=''/></Flex>}
            />
          </Box>
          {currentEditingRow.cadModelType !== 'drawing' &&
            <>
              <Box width={'48%'}>
                <label>componentParameterProfile</label>
                <Dropdown
                  value={currentEditingRow.componentParameterProfile}
                  options={reduxProps.parameterProfiles}
                  optionLabel='name'
                  onChange={(e) => handleEditedRowChange(e.value, 'componentParameterProfile')}
                  itemTemplate={(item) => <Flex flexDirection='row'><ComplexCellRenderer value={{ primary: item?.primary }} isCapitalized={false} placeholder=''/></Flex>}
                  valueTemplate={(item) => <Flex flexDirection='row'><ComplexCellRenderer value={{ primary: item?.primary }} isCapitalized={false} placeholder=''/></Flex>}
                />
              </Box>
            </>
          }
        </Flex>
        <label>noteProfile</label>
        <Dropdown
          value={currentEditingRow.noteProfile}
          options={reduxProps.noteProfiles}
          optionDisabled={'archivedFlag'}
          optionLabel='name'
          onChange={(e) => handleEditedRowChange(e.value, 'noteProfile')}
          itemTemplate={(item) => <Flex flexDirection='row'><ComplexCellRenderer value={{ primary: item?.primary }} isCapitalized={false} placeholder=''/></Flex>}
          valueTemplate={(item) => <Flex flexDirection='row'><ComplexCellRenderer value={{ primary: item?.primary }} isCapitalized={false} placeholder=''/></Flex>}
        />
        {currentEditingRow.cadModelType !== 'drawing' &&
          <>
            <label>orientations</label>
            <MultiSelect
              placeholder='select'
              optionLabel='name'
              useOptionAsValue={true}
              value={currentEditingRow.orientations || []}
              options={reduxProps.orientations}
              display='chip'
              onChange={(e) => handleEditedRowChange(e.value, 'orientations')}
            />
          </>
        }
        {currentEditingRow.cadModelType !== 'drawing' &&
          <>
            <label>layers</label>
            <MultiSelect
              placeholder='select'
              optionLabel='name'
              value={currentEditingRow.layers || []}
              options={reduxProps.layers}
              display='chip'
              onChange={(e) => handleEditedRowChange(e.value, 'layers')}
            />
          </>
        }
        {currentEditingRow.cadModelType !== 'drawing' &&
          <>
            <label>sections</label>
            <MultiSelect
              placeholder='select'
              optionLabel='name'
              value={currentEditingRow.sections || []}
              options={reduxProps.sections}
              display='chip'
              onChange={(e) => handleEditedRowChange(e.value, 'sections')}
            />
          </>
        }
        {currentEditingRow.cadModelType === 'assembly' &&
          <>
            <label>simplifiedReps</label>
            <MultiSelect
              placeholder='select'
              optionLabel='name'
              value={currentEditingRow.simplifiedReps || []}
              options={reduxProps.simplifiedReps}
              display='chip'
              onChange={(e) => handleEditedRowChange(e.value, 'simplifiedReps')}
            />
          </>
        }
      </Dialog>
      <Dialog visible={reduxProps.editable && isDeleteDialogOpen} style={{ width: '32rem' }} header={`Delete Note Profile '${currentEditingRow.name}'`} footer={() => deleteDialogFooter(closeDeleteDialog, deleteAction)} closable={false} >
        delete
      </Dialog>
    </>
  );
}