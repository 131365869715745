import { toast } from 'react-toastify';

import EK from '../keys';

import miterClient from './client';

import { REQUEST_TYPES } from '../../modules/api/constants';

import {
  fetchAllMiterFamiliesForPipeFamilyTypes,
  fetchIndividualMiterFamilyForPipeFamilyTypes,
  createMiterFamilyForPipeFamilyTypes,
  copyMiterFamilyForPipeFamilyTypes,
  editMiterFamilyForPipeFamilyTypes,
  deleteMiterFamilyForPipeFamilyTypes,
  archiveMiterFamiliesForPipeFamilyTypes,
  unarchiveMiterFamiliesForPipeFamilyTypes,
} from './constants';

export const processFetchAllMiterFamiliesForPipeFamily = pipeId => miterClient(REQUEST_TYPES.GET, 'plural', fetchAllMiterFamiliesForPipeFamilyTypes, `/piping/pipe_families/${pipeId}/miter_families?all=true`, {
  mutateResponse: ({ miterFamilies = [] }) => ({
    miterFamilies: miterFamilies.map(family => ({ ...family, pipeFamily: pipeId, pipeFamilyId: pipeId })),
  }),
});

export const processFetchIndividualMiterFamilyForPipeFamily = (pipeId, familyId) => miterClient(REQUEST_TYPES.GET, 'single', fetchIndividualMiterFamilyForPipeFamilyTypes, `/piping/miter_families/${familyId}?with_full_detail=true`, {
  mutateResponse: ({ miterFamily: { id, miterInstances = [], ...other } = {} }) => ({
    miterFamily: {
      ...other,
      id,
      pipeFamily: pipeId,
      pipeFamilyId: pipeId,
      miterInstances: miterInstances.map(instance => ({
        ...instance,
        miterFamily: `${id}`,
        miterFamilyId: `${id}`,
      })),
    },
  }),
});

export const processCreateMiterFamilyForPipeFamily = (pipeId, data) => miterClient(REQUEST_TYPES.POST, 'plural', createMiterFamilyForPipeFamilyTypes, `/piping/pipe_families/${pipeId}/miter_families`, {
  data: {
    miterFamilies: [data],
  },
  mutateResponse: ({ miterFamilies = [] }) => ({
    miterFamilies: miterFamilies.map(family => ({ ...family, pipeFamily: pipeId, pipeFamilyId: pipeId, })),
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Miter successfully created',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to create miter',
  },
});

export const processCopyMiterFamilyForPipeFamily = (pipeId, data) =>
  miterClient(
    REQUEST_TYPES.POST,
    'plural',
    copyMiterFamilyForPipeFamilyTypes,
    `/piping/pipe_families/${pipeId}/miter_families/copy`,
    {
      data: {
        [EK.MITERS.plural]: [data],
      },
      mutateResponse: ({ miterFamilies = [] }) => ({
        miterFamilies: miterFamilies.map(family => ({ ...family, pipeFamily: pipeId, pipeFamilyId: pipeId, })),
      }),
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Miter successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy miter',
      },
    }
  );

export const processEditMiterFamilyForPipeFamily = (pipeId, miterId, data) => miterClient(REQUEST_TYPES.PATCH, 'single', editMiterFamilyForPipeFamilyTypes, `/piping/miter_families/${miterId}`, {
  data,
  mutateResponse: ({ miterFamily: { id, miterInstances = [], ...other } = {} }) => ({
    miterFamily: {
      ...other,
      id,
      pipeFamily: pipeId,
      pipeFamilyId: pipeId,
      miterInstances: miterInstances.map(instance => ({
        ...instance,
        miterFamily: `${id}`,
        miterFamilyId: `${id}`,
      })),
    },
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});

export const processDeleteMiterFamilyForPipeFamily = (pipeId, miterId) => miterClient(REQUEST_TYPES.DELETE, 'single', deleteMiterFamilyForPipeFamilyTypes, `/piping/miter_families/${miterId}?with_full_detail=true`, {
  deleteKey: EK.MITERS.state,
  dependentDeletionKeys: [EK.MITER_INSTANCES.state],
  mutateResponse: ({ miterFamily: { id, ...other } = {}, miterInstances = [] }) => ({
    miterFamily: {
      ...other,
      id,
      pipeFamily: pipeId,
      pipeFamilyId: pipeId,
      miterInstances: miterInstances.map(instance => ({
        ...instance,
        miterFamily: `${id}`,
        miterFamilyId: `${id}`,
      })),
    },
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Miter deleted',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to delete miter',
  },
});

export const processArchiveMiterFamiliesForPipeFamily = (pipeId, miterIds) => miterClient(REQUEST_TYPES.ARCHIVE, 'plural', archiveMiterFamiliesForPipeFamilyTypes, `/piping/pipe_families/${pipeId}/miter_families/archive`, {
  mutateResponse: ({ miterFamilies = [] }) => ({
    miterFamilies: miterFamilies.map(({ id, miterInstances = [], ...other }) => ({
      ...other,
      id,
      pipeFamily: pipeId,
      pipeFamilyId: pipeId,
      miterInstances: miterInstances.map(instance => ({
        ...instance,
        miterFamily: `${id}`,
        miterFamilyId: `${id}`,
      })),
    })),
  }),
  data: { ids: miterIds },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Miter archived',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to archive miter',
  },
});

export const processUnarchiveMiterFamiliesForPipeFamily = (pipeId, miterIds) => miterClient(REQUEST_TYPES.UNARCHIVE, 'plural', unarchiveMiterFamiliesForPipeFamilyTypes, `/piping/pipe_families/${pipeId}/miter_families/unarchive`, {
  mutateResponse: ({ miterFamilies = [] }) => ({
    miterFamilies: miterFamilies.map(({ id, miterInstances = [], ...other }) => ({
      ...other,
      id,
      pipeFamily: pipeId,
      pipeFamilyId: pipeId,
      miterInstances: miterInstances.map(instance => ({
        ...instance,
        miterFamily: `${id}`,
        miterFamilyId: `${id}`,
      })),
    })),
  }),
  data: { ids: miterIds },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Miter unarchived',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to unarchive miter',
  },
});
