import { createSelector } from 'reselect';

import EK from '../keys';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';
import sectionSchema from './schema';

import { denormalize } from 'normalizr';

export const selectNormalizedSections = () =>
  createSelector(selectEntities(), (entities) =>
    entities.get(EK.SECTIONS.state)
  );
export const selectNormalizedSectionsList = () =>
  createSelector(selectNormalizedSections(), (sections) =>
    sections.toList()
  );

export const selectNormalizedDraftSections = () =>
  createSelector(selectDrafts(), (drafts) => {
    const state = drafts.get(EK.SECTIONS.state);
    const records = state.get('records');
    return state.get('ids').map((id) => records.get(id));
  });

export const selectSections = () =>
  createSelector(selectEntities(), (entities) =>
    denormalize(
      entities.get(EK.SECTIONS.state),
      [sectionSchema],
      entities
    )
  );