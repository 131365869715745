import { createAction } from 'redux-actions';

import { generateCreateDraftsAction, generateEditDraftsAction } from '../../../modules/drafts/actions';

import { CREATE_PARAMETER_DRAFTS, EDIT_PARAMETER_DRAFTS, UPDATE_PARAMETERS_COLLECTION } from './constants';

export const createParameterDrafts = generateCreateDraftsAction(CREATE_PARAMETER_DRAFTS);

export const editParameterDrafts = generateEditDraftsAction(EDIT_PARAMETER_DRAFTS);

export const updateParametersCollection = createAction(UPDATE_PARAMETERS_COLLECTION);
