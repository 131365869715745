import { getUnitLength } from '../../../entities/UnitSystems/model';

export const convertToPipeInstanceType = ({
  id,
  sizeProperty,
  schedule,
  thickness,
  diameter,
  pipeWidth,
  pipeHeight,
  endTypes,
  stockno,
  mccsCode,
  pipeFamily,
  pipeFamilyId,
  pipeShape,
  isEdited = false,
  isCreated = false,
  isDeleted = false,
}) => {
  return {
    id,
    sizeProperty,
    schedule,
    thickness,
    diameter,
    pipeWidth,
    pipeHeight,
    endTypes,
    stockno,
    mccsCode,
    pipeFamily,
    pipeFamilyId,
    pipeShape,
    isEdited,
    isCreated,
    isDeleted,
  };
};

export const getEndTypesArray = (currentRow) => {
  let endTypeArray = [];
  try {
    endTypeArray = currentRow.endTypes && currentRow.endTypes.toArray();
  } catch (error) {
    endTypeArray = currentRow.endTypes;
  }
  return endTypeArray;
};

export const saveCurrentPipeInstanceChanges = (
  changes,
  saveAction,
  currentUnitSystem,
) => {
  const collectionsdata = [];

  changes.forEach((item) => {
    if (item.isCreated) {
      collectionsdata.push({
        sizeId: item.sizeProperty && item.sizeProperty.id,
        scheduleId: item.schedule && item.schedule.id,
        thickness: item.thickness,
        diameter: item.diameter,
        pipeWidth: item.pipeWidth,
        pipeHeight: item.pipeHeight,
        endTypeIds: item.endTypes && item.endTypes.map(item => item.id),
        stockno: item.stockno,
        mccsCode: item.mccsCode,
      });
    } else if (item.isDeleted) {
      collectionsdata.push({ id: item.id, _destroy: '1' });
    } else if (item.isEdited) {
      collectionsdata.push({
        id: item.id,
        sizeId: item.sizeProperty && item.sizeProperty.id,
        scheduleId: item.schedule && item.schedule.id,
        thickness: item.thickness,
        diameter: getUnitLength(item.diameter, currentUnitSystem),
        pipeWidth: getUnitLength(item.pipeWidth, currentUnitSystem),
        pipeHeight: getUnitLength(item.pipeHeight, currentUnitSystem),
        endTypeIds: item.endTypes && item.endTypes.map(item => item.id),
        stockno: item.stockno,
        mccsCode: item.mccsCode,
      });
    }
  });

  collectionsdata.length > 0 && saveAction(collectionsdata);
};