import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';

import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import DialogFooter from '../../../components/modal/ModalFooters/DialogFooter';
import Flex from '../../../components/common/Flex';
import Icon from '../../../components/common/Icon';

// validation
import { ALLOWANCES } from '../../../entities/FittingFamilies/model';

// actions
import { processFetchAllBoltNutFamilies } from '../../../entities/BoltNutFamilies/actions';
import { processFetchAllMaterials } from '../../../entities/Materials/actions';
import { processFetchAllFittingCategories } from '../../../entities/FittingCategories/actions';
import { processFetchAllFittingCodes } from '../../../entities/FittingCodes/actions';

// selectors
import { selectUnarchivedFittingCategoryOptions } from '../../../entities/FittingCategories/selectors';
import { selectNormalizedMaterials } from '../../../entities/Materials/selectors';
import { selectCurrentUnarchivedBoltNutFamilyOptions } from '../../../entities/BoltNutFamilies/selectors';
import { selectNormalizedFittingCodes } from '../../../entities/FittingCodes/selectors';

const mapStateToProps = createSelector(
  selectCurrentUnarchivedBoltNutFamilyOptions(),
  selectNormalizedMaterials(),
  selectUnarchivedFittingCategoryOptions(),
  selectNormalizedFittingCodes(),
  (boltNuts, materials, fittingCategories, fittingCodes) => ({
    boltNuts: boltNuts?.toList().toArray() || [],
    materials: materials?.toList().toArray() || [],
    fittingCategories: fittingCategories?.toList().toArray() || [],
    fittingCodes: fittingCodes?.toList().toArray() || []
  })
);

const categorySelectorBody = option =>
  <div style={{ maxWidth: '300px', overflow: 'ellipsis', height: '100%' }} >
    <Flex flexDirection='column' justifyContent='center' style={{ float: 'left', height: '100%' }} ><Icon style='ptc' name={option.symbolFile} iconStyle={{ height: '30px', width: '30px' }} /></Flex>
    <ComplexCellRenderer
      value={{ primary: option.categoryName, secondary: option.symbolFile }}
      placeholder={''}
      isCapitalized={false}
      paddingLeft='10px'
      style={{ float: 'right' }}
    />
  </div>;

/**
 * handles data fetching and selecting for the fields it requires, so it isn't necessary to also put fetches for the fitting codes, fitting categories, materials, or bolt nuts if using this dialog in the page
 * @param {fittingFamily} currentEditingRow
 * @param {function} saveAction function to call when save button is clicked
 * @param {function} deleteAction function to call when delete button is clicked
 * @param {function} cancelAction function that closes all dialogs and resets state, used primarily for the cancel dialog button
 * @param {function} handleEditedRowChange
 * @param {boolean} isCreateDialogOpen
 * @param {boolean} isDialogOpen
 * @param {boolean} isCopyDialogOpen
 * @param {boolean} editable determines what buttons are shown
 * @param {fittingFamilyValidation} validation
 * @returns PrimeReact Dialog outfitted with fields for a FittingFamily
 */
export default function ({
  currentEditingRow,
  saveAction, // saves any/all changes
  deleteAction=false, // opens the Delete Dialog
  cancelAction, // closes modals and resets state
  isCreateDialogOpen=false,
  isDialogOpen=false,
  isCopyDialogOpen=false,
  editable=true,
  handleEditedRowChange,
  validation,
}) {
  const {boltNuts, materials, fittingCategories, fittingCodes} = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  console.log(currentEditingRow);

  const getDialogHeader = useMemo(() => {
    if (isCreateDialogOpen) {
      return 'Create Fitting Family';
    } else if (isCopyDialogOpen) {
      return `Create Copy of '${currentEditingRow.name}'`;
    } else {
      return `Edit '${currentEditingRow.name}'`;
    }
  }, [isDialogOpen]);

  useEffect(() => {
    dispatch(processFetchAllBoltNutFamilies());
    dispatch(processFetchAllMaterials());
    dispatch(processFetchAllFittingCategories());
    dispatch(processFetchAllFittingCodes());
  }, []);
  return (
    <>
      <Dialog visible={editable && isDialogOpen} style={{ width: '75%' }} header={getDialogHeader} footer={() => DialogFooter(isCreateDialogOpen, isCopyDialogOpen, deleteAction, saveAction, cancelAction)} modal className='p-fluid' closable={false} >
        <div>
          <label>Name</label>
          <InputText keyfilter={ALLOWANCES.NAME} value={currentEditingRow.name} onChange={(e) => handleEditedRowChange(e.target.value, 'name')} className={!validation.name && 'p-invalid'} />
        </div>
        <div>
          <label>Selection Name</label>
          <InputText keyfilter={ALLOWANCES.SELECTION_NAME} value={currentEditingRow.selectionName} onChange={(e) => handleEditedRowChange(e.target.value, 'selectionName')} className={!validation.selectionName && 'p-invalid'} />
        </div>
        <div style={{ width: '50%', float: 'left', paddingRight: '5px' }}>
          <label>Fitting Category</label>
          <Dropdown
            optionLabel='categoryName'
            value={currentEditingRow.fittingCategory}
            options={fittingCategories}
            onChange={(e) => handleEditedRowChange(e.target.value, 'fittingCategory')}
            itemTemplate={(item) => categorySelectorBody(item)}
            filter
            className={!validation.fittingCategory && 'p-invalid'}
          />
        </div>
        <div style={{ width: '50%', float: 'right' }} >
          <label>Fitting Code</label>
          <Dropdown
            optionLabel='name'
            value={currentEditingRow.fittingCode}
            options={fittingCodes}
            onChange={(e) => handleEditedRowChange(e.target.value, 'fittingCode')}
            itemTemplate={(item) => ComplexCellRenderer({
              value: { primary: item.name, secondary: item.description }, placeholder: '', isCapitalized: false
            })}
            filter
            className={!validation.fittingCode && 'p-invalid'}
          />
        </div>
        <div style={{ width: '50%', float: 'left', paddingRight: '5px' }} >
          <label>Bolt Nut</label>
          <Dropdown
            optionLabel='name'
            value={currentEditingRow.boltNutFamily}
            options={boltNuts}
            onChange={(e) => handleEditedRowChange(e.target.value, 'boltNutFamily')}
            itemTemplate={(item) => ComplexCellRenderer({
              value: { primary: item.name, secondary: item.boltNutCode }, placeholder: '', isCapitalized: false
            })}
            filter
          />
        </div>
        <div style={{ width: '50%', float: 'right' }} >
          <label>Material</label>
          <Dropdown
            optionLabel='code'
            value={currentEditingRow.material}
            options={materials}
            onChange={(e) => handleEditedRowChange(e.target.value, 'material')}
            itemTemplate={(item) => ComplexCellRenderer({
              value: { primary: item.code, secondary: item.description }, placeholder: '', isCapitalized: false
            })}
            filter
          />
        </div>
      </Dialog>
    </>
  );
}