import { toast } from 'react-toastify';

import fittingCategoryClient from './client';

import { REQUEST_TYPES } from '../../modules/api/constants';

import { fetchAllFittingCategoriesTypes, saveFittingCategoriesTypes } from './constants';

export const processFetchAllFittingCategories = () => fittingCategoryClient(REQUEST_TYPES.GET, 'plural', fetchAllFittingCategoriesTypes, '/piping/fitting_categories');

export const processSaveFittingCategories = (data) => fittingCategoryClient(REQUEST_TYPES.PATCH, 'plural', saveFittingCategoriesTypes, '/piping/fitting_categories/collection?diff_result=true', {
  data: { fittingCategoriesAttributes: data },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});
