import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { matchPath } from 'react-router-dom';
import BreadcrumbTemplate from '../../templates/Structures/Breadcrumb';

import { PipingModuleInsulationsEditPath } from '../../../paths';
import { selectCurrentInsulationFamily } from '../../../entities/InsulationFamilies/selectors';

function BendEditBread({ location }) {
  const match = matchPath(location.pathname, { path: PipingModuleInsulationsEditPath.url });
  const insulationId = (match && match.params.id) || null;
  const insulationFamily = useSelector(selectCurrentInsulationFamily(insulationId));

  return <BreadcrumbTemplate title={(insulationFamily && insulationFamily.name) || PipingModuleInsulationsEditPath.defaultTitle} />;
}

export default withRouter(BendEditBread);
