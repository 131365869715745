import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

import EK from '../../../entities/keys';

import Grid from '../../../containers/grid/Grid';

import { selectPipingModulePermissionsAndState } from '../../Dashboard/selectors';

import { selectCurrentSpec } from '../../SpecsEditSwitch/selectors';

import { editSpecPipeInstanceDrafts } from './actions';

import { selectCurrentFilteredSpecPipeInstancesForFamily } from './selectors';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectCurrentSpec(),
  selectCurrentFilteredSpecPipeInstancesForFamily(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    spec,
    data,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      data: !isLoading && data.toList().toArray() || [],
      editable: canCollaborate && hasValidLicense && spec && !spec.archivedFlag,
    };
  },
);

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    { editInstances: editSpecPipeInstanceDrafts },
    (
      { data, editable, isLoading },
      dispatchProps,
    ) => ({
      data,
      columnDefs: [
        ...(editable && [
          { type: ['entitySelectionCell'] },
          { type: ['entitySaveStatusCell'] },
        ] || []),
        {
          headerName: 'Summary',
          field: '',
          type: ['complexCell'],
          valueGetter: ({ data }) => data.summary,
        },
        ...(!isLoading && [
          {
            headerName: 'Size',
            field: 'pipeInstance.sizeProperty',
            type: ['entityCell'],
            cellRendererParams: {
              isDropdown: false,
              placeholder: { primary: 'No Size', secondary: '--' },
              ek: EK.SIZES,
            },
          },
          {
            headerName: 'Schedule',
            field: 'pipeInstance.schedule',
            type: ['entityCell'],
            cellRendererParams: {
              isDropdown: false,
              placeholder: { primary: 'No Schedule', secondary: '--' },
              ek: EK.SCHEDULES,
            },
          },
        ] || []),
        {
          headerName: 'Stock No',
          field: 'editableStockno',
          editable,
          type: ['basicCell', 'basicEditor'],
          valueSetter: ({ data, oldValue, newValue, context }) => {
            oldValue !== newValue &&
              context.editInstances &&
              context.editInstances(data.setEditableStockno(newValue));
            return false;
          },
          cellRendererParams: {
            placeholder: 'Stockno',
          },
        },
        {
          headerName: 'MCCS Code',
          field: 'editableMccsCode',
          editable,
          type: ['basicCell', 'basicEditor'],
          valueSetter: ({ data, oldValue, newValue, context }) => {
            oldValue !== newValue &&
              context.editInstances &&
              context.editInstances(data.setEditableMccsCode(newValue));
            return false;
          },
          cellRendererParams: {
            placeholder: 'MCCS Code',
          },
        },
      ],
      ...(editable && dispatchProps || {}),
    }),
  ),
);

export default enhance(Grid);
