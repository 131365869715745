import { createSelector } from 'reselect';
import { Set } from 'immutable';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../../entities/keys';

import { ENTITY_STATUS } from '../../../modules/entities/constants';

import selectDrafts from '../../../modules/drafts/selectors';

import { selectNormalizedDraftOrientations } from '../../../entities/Orientations/selectors';

import { selectSidebarIsFiltering } from '../../Dashboard/selectors';

import selectEntities from '../../../modules/entities/selectors';

export const selectOrientations = () =>
  createSelector(
    selectEntities(),
    (entities) => entities.get('orientations')
  );

export const selectCurrentDraftOrientationsPendingValidChanges = () =>
  createSelector(
    selectDrafts(),
    (drafts) =>
      drafts.getIn([EK.ORIENTATIONS.state, 'saveable']).size > 0
  );

export const selectCurrentDraftOrientationIds = () =>
  createSelector(selectNormalizedDraftOrientations(), (drafts) =>
    drafts.reduce((ids, draft) => ids.add(draft.id), Set())
  );

export const selectCurrentEditedDraftOrientationsCount = () =>
  createSelector(
    selectNormalizedDraftOrientations(),
    (drafts) =>
      drafts.filter((draft) => draft.status !== ENTITY_STATUS.LOCAL_DRAFT).size
  );

const orientationFilterForm = formValueSelector(
  `filters.${EK.ORIENTATIONS.state}`
);

export const selectCurrentFilteredNormalizedDraftOrientations = () =>
  createSelector(
    selectSidebarIsFiltering(),
    selectNormalizedDraftOrientations(),
    (state) => orientationFilterForm(state, 'name', 'description'),
    (isFiltering, layers, query) =>
      isFiltering
        ? layers.filter((layer) => layer.doesMatchQuery(query))
        : layers
  );
