import React, { useCallback, useMemo } from 'react';
import { compose } from 'redux';
import { Field, reduxForm, reset } from 'redux-form/immutable';
import { Set } from 'immutable';

import EK from '../../../../../entities/keys';

import NoteBlockNoteModel from '../../../../../entities/NoteBlockNotes/model';

import Box from '../../../../../components/common/Box';
import Button from '../../../../../components/common/Button';

import BaseFormSection from '../../../../../components/form/BaseFormSection';
import BaseFormRow from '../../../../../components/form/BaseFormRow';

import RDXSearchSelect from '../../../../../containers/form/RDXSearchSelect';
import RDXTextInput from '../../../../../containers/form/RDXTextInput';

import { validations } from '../../../../../entities/NoteBlockNotes/formValidation';

const NoteBlockAddNoteForm = ({
  handleSubmit,
  isPerforming,
  valid,
  isLocked,
  noteBlockNotes,
}) => {
  const usedNoteIds = useMemo(
    () =>
      noteBlockNotes.reduce(
        (ids, noteBlockNote) => ids.add(noteBlockNote.note),
        Set()
      ),
    [noteBlockNotes]
  );

  const NOTES_EFILTER = useCallback(
    (note) => !usedNoteIds.has(note.id),
    [usedNoteIds]
  );

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <BaseFormSection>
        <BaseFormRow>
          <Field
            component={RDXTextInput}
            name="prefix"
            label="Prefix"
            disabled={isLocked}
            placeholder="#"
            width="20%"
          />
          <Field
            component={RDXSearchSelect}
            name="note"
            label="Note"
            ek={EK.NOTES}
            eFilter={NOTES_EFILTER}
            validate={validations['note']}
            disabled={isLocked}
            clearable
            width="80%"
          />
        </BaseFormRow>
      </BaseFormSection>

      <Button
        primary
        type="submit"
        large
        full
        disabled={isPerforming || !valid || isLocked}
        isPerformingAction={isPerforming}
      >
        Save
      </Button>
    </Box>
  );
};

const emptyNoteBlockNote = new NoteBlockNoteModel({
  prefix: '#',
});

const enhance = compose(
  reduxForm({
    initialValues: emptyNoteBlockNote,
    onSubmit(model, dispatch, props) {
      props.onRDXSubmit(model.serialize());
    },
    onSubmitSuccess(result, dispatch, props) {
      dispatch(reset(props.form));
    },
  })
);

export default enhance(NoteBlockAddNoteForm);
