import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import { formValueSelector } from 'redux-form/immutable';

import EK from '../keys';
import selectEntities from '../../modules/entities/selectors';
import FittingFamilySchema from './schema';

import { selectIsShowingArchived } from '../../modules/query/selectors';
import { selectSidebarIsFiltering } from '../../routes/Dashboard/selectors';

export const selectNormalizedFittingFamilies = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.FITTING_FAMILIES.state)
);
export const selectFittingFamilies = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get(EK.FITTING_FAMILIES.state), [FittingFamilySchema], entities.delete('fittingInstances'))
);
export const selectFittingFamiliesList = () => createSelector(
  selectFittingFamilies(),
  fittingFamilies => fittingFamilies.toList()
);

export const selectFittingFamiliesCounts = () => createSelector(
  selectNormalizedFittingFamilies(),
  fittings => fittings.reduce(({ unarchived, total }, fitting) => ({
    unarchived: !fitting.archivedFlag ? unarchived + 1 : unarchived,
    total: total + 1,
  }), {
    unarchived: 0,
    total: 0,
  })
);

const fittingFilterForm = formValueSelector(`filters.${EK.FITTING_FAMILIES.state}`);

export const fittingFilterSelector = () => createSelector(
  state => fittingFilterForm(state, 'name', 'selectionName', 'fittingCode', 'fittingCategory', 'material', 'boltNutFamily'),
  (filterVals) => filterVals
);

export const selectCurrentFilteredFittings = () => createSelector(
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectFittingFamilies(),
  state => fittingFilterForm(state, 'name', 'selectionName', 'fittingCode', 'fittingCategory', 'material', 'boltNutFamily'),
  (isFiltering, isShowingArchived, fittings, query) => {
    return isFiltering ?
      fittings.filter(fitting => (isShowingArchived || !fitting.archivedFlag) && fitting.doesMatchQuery(query)) :
      fittings.filter(fitting => !fitting.archivedFlag);
  }
);

export const selectCurrentFittingFamily = (familyId) => createSelector(
  selectEntities(),
  (entities) => entities.hasIn([EK.FITTING_FAMILIES.state, familyId]) && denormalize(entities.getIn([EK.FITTING_FAMILIES.state, familyId]), FittingFamilySchema, entities)
);

export const selectCurrentFittingFamilyNumberOfPorts = (familyId) => createSelector(
  selectCurrentFittingFamily(familyId),
  (family) => family?.fittingCode?.endCount || 0
);