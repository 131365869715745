import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../entities/keys';

import selectEntities from '../../modules/entities/selectors';

import { selectIsShowingArchived } from '../../modules/query/selectors';

import { selectCurrentPipeId } from '../../routes/PipesEditSwitch/selectors';

import { selectSidebarIsFiltering } from '../../routes/Dashboard/selectors';

const bendSpringbackFilterForm = formValueSelector(`filters.${EK.BEND_SPRINGBACKS.state}`);

export const selectNormalizedBendSpringbackFamilies = () =>  createSelector(
  selectEntities(),
  entities => entities.get('bendSpringbackFamilies')
);
export const selectNormalizedBendSpringbackFamiliesList = () => createSelector(
  selectNormalizedBendSpringbackFamilies(),
  bendSpringbackFamilies => bendSpringbackFamilies.toList()
);

export const selectCurrentPipesBendSpringbacksCount = () => createSelector(
  selectCurrentPipeId(),
  selectNormalizedBendSpringbackFamiliesList(),
  (pipeId, bendSpringbacks) => bendSpringbacks
    .filter(bendSpringback => bendSpringback.pipeFamilyId === pipeId)
    .reduce(({ unarchived, total }, bendSpringback) => ({
      unarchived: !bendSpringback.archivedFlag ? unarchived + 1 : unarchived,
      total: total + 1,
    }), {
      unarchived: 0,
      total: 0,
    })
);

export const selectCurrentFilteredPipesBendSpringbacks = () => createSelector(
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectCurrentPipeId(),
  selectNormalizedBendSpringbackFamiliesList(),
  state => bendSpringbackFilterForm(state, 'name'),
  (isFiltering, isShowingArchived, pipeId, bendSpringbacks, name) => isFiltering ?
    bendSpringbacks.filter(bendSpringback => (isShowingArchived || !bendSpringback.archivedFlag) && bendSpringback.pipeFamilyId === pipeId && bendSpringback.doesMatchQuery({ name })) :
    bendSpringbacks.filter(bendSpringback => !bendSpringback.archivedFlag && bendSpringback.pipeFamilyId === pipeId)
);

export const selectCurrentBendSpringBack = (springBackId) => createSelector(
  selectEntities(),
  (entities) => entities.hasIn([EK.BEND_SPRINGBACKS.state, springBackId]) && entities.getIn([EK.BEND_SPRINGBACKS.state, springBackId])
);