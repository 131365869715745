import baseSchema from '../../modules/entities/utils/schema';

import EK from '../keys';

import PipeFamilySchema from '../PipeFamilies/schema';
import SizeSchema from '../Sizes/schema';

const bendFamilySchema = baseSchema(EK.BENDS.state, {
  pipeFamily: { schema: PipeFamilySchema, isId: true },
  bendInstances: { isId: true, idField: 'bendInstanceIds', isList: true },
});

export const bendInstanceSchema = baseSchema(EK.BEND_INSTANCES.state, {
  sizeProperty: { schema: SizeSchema, isId: true, originalField: 'size' },
  radius: { isComplexUnit: true },
  minRadius: { isComplexUnit: true },
  maxRadius: { isComplexUnit: true },
  bendFamily: { schema: bendFamilySchema, isId: true },
});

bendFamilySchema.define({ bendInstances: [ bendInstanceSchema ] });

export default bendFamilySchema;
