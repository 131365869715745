import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Notice from '../../../containers/common/Notice';

import { selectIsFetching } from '../../../modules/utility/selectors';

import { selectSpecsList } from '../../../entities/Specs/selectors';

const mapStateToProps = createStructuredSelector({
  isFetching: selectIsFetching(),
  data: selectSpecsList(),
});

const enhance = compose(
  connect(mapStateToProps)
);

const InsulationsNotices = ({ isFetching, data }) => isFetching && data && data.size > 0 && <Notice id='specs' message='Loading data in the background...' /> || null;

export default enhance(InsulationsNotices);
