import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../entities/keys';

import selectEntities from '../../modules/entities/selectors';

import { selectIsShowingArchived } from '../../modules/query/selectors';

import { selectCurrentPipeId } from '../../routes/PipesEditSwitch/selectors';

import { selectSidebarIsFiltering } from '../../routes/Dashboard/selectors';

const miterFilterForm = formValueSelector(`filters.${EK.MITERS.state}`);

export const selectNormalizedMiterFamilies = () =>  createSelector(
  selectEntities(),
  entities => entities.get('miterFamilies')
);
export const selectNormalizedMiterFamiliesList = () => createSelector(
  selectNormalizedMiterFamilies(),
  miterFamilies => miterFamilies.toList()
);

export const selectCurrentPipesMitersCount = () => createSelector(
  selectCurrentPipeId(),
  selectNormalizedMiterFamiliesList(),
  (pipeId, miters) => miters
    .filter(miter => miter.pipeFamilyId === pipeId)
    .reduce(({ unarchived, total }, miter) => ({
      unarchived: !miter.archivedFlag ? unarchived + 1 : unarchived,
      total: total + 1,
    }), {
      unarchived: 0,
      total: 0,
    })
);

export const selectCurrentFilteredPipesMiters = () => createSelector(
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectCurrentPipeId(),
  selectNormalizedMiterFamiliesList(),
  state => miterFilterForm(state, 'name'),
  (isFiltering, isShowingArchived, pipeId, miters, name) => isFiltering ?
    miters.filter(miter => (isShowingArchived || !miter.archivedFlag) && miter.pipeFamilyId === pipeId && miter.doesMatchQuery({ name })) :
    miters.filter(miter => !miter.archivedFlag && miter.pipeFamilyId === pipeId)
);

export const selectCurrentMiterFamily = (familyId) => createSelector(
  selectEntities(),
  (entities) => entities.hasIn([EK.MITERS.state, familyId]) && entities.getIn([EK.MITERS.state, familyId])
);