import generateAPHTypes from '../../utils/constants/generateAPHTypes';

const resource = 'PIPE_INSTANCES';

export const savePipeInstancesTypes = generateAPHTypes(resource, 'COLLECTION');

export const newPipeInstancesArray = [
  { id: 'NEW_ROW_1', isEdited: false, isCreated: false, isDeleted: false, sizeProperty: null, schedule: null, thickness: [], diameter: [], pipeWidth: [], pipeHeight: [], endTypes: [], stockno: '', mccsCode: '', pipeFamily: '', pipeFamilyId: '', pipeShape: '' },
  { id: 'NEW_ROW_2', isEdited: false, isCreated: false, isDeleted: false, sizeProperty: null, schedule: null, thickness: [], diameter: [], pipeWidth: [], pipeHeight: [], endTypes: [], stockno: '', mccsCode: '', pipeFamily: '', pipeFamilyId: '', pipeShape: '' },
  { id: 'NEW_ROW_3', isEdited: false, isCreated: false, isDeleted: false, sizeProperty: null, schedule: null, thickness: [], diameter: [], pipeWidth: [], pipeHeight: [], endTypes: [], stockno: '', mccsCode: '', pipeFamily: '', pipeFamilyId: '', pipeShape: '' },
  { id: 'NEW_ROW_4', isEdited: false, isCreated: false, isDeleted: false, sizeProperty: null, schedule: null, thickness: [], diameter: [], pipeWidth: [], pipeHeight: [], endTypes: [], stockno: '', mccsCode: '', pipeFamily: '', pipeFamilyId: '', pipeShape: '' },
  { id: 'NEW_ROW_5', isEdited: false, isCreated: false, isDeleted: false, sizeProperty: null, schedule: null, thickness: [], diameter: [], pipeWidth: [], pipeHeight: [], endTypes: [], stockno: '', mccsCode: '', pipeFamily: '', pipeFamilyId: '', pipeShape: '' },
  { id: 'NEW_ROW_6', isEdited: false, isCreated: false, isDeleted: false, sizeProperty: null, schedule: null, thickness: [], diameter: [], pipeWidth: [], pipeHeight: [], endTypes: [], stockno: '', mccsCode: '', pipeFamily: '', pipeFamilyId: '', pipeShape: '' },
  { id: 'NEW_ROW_7', isEdited: false, isCreated: false, isDeleted: false, sizeProperty: null, schedule: null, thickness: [], diameter: [], pipeWidth: [], pipeHeight: [], endTypes: [], stockno: '', mccsCode: '', pipeFamily: '', pipeFamilyId: '', pipeShape: '' },
  { id: 'NEW_ROW_8', isEdited: false, isCreated: false, isDeleted: false, sizeProperty: null, schedule: null, thickness: [], diameter: [], pipeWidth: [], pipeHeight: [], endTypes: [], stockno: '', mccsCode: '', pipeFamily: '', pipeFamilyId: '', pipeShape: '' },
  { id: 'NEW_ROW_9', isEdited: false, isCreated: false, isDeleted: false, sizeProperty: null, schedule: null, thickness: [], diameter: [], pipeWidth: [], pipeHeight: [], endTypes: [], stockno: '', mccsCode: '', pipeFamily: '', pipeFamilyId: '', pipeShape: '' },
  { id: 'NEW_ROW_10', isEdited: false, isCreated: false, isDeleted: false, sizeProperty: null, schedule: null, thickness: [], diameter: [], pipeWidth: [], pipeHeight: [], endTypes: [], stockno: '', mccsCode: '', pipeFamily: '', pipeFamilyId: '', pipeShape: '' },
  { id: 'NEW_ROW_11', isEdited: false, isCreated: false, isDeleted: false, sizeProperty: null, schedule: null, thickness: [], diameter: [], pipeWidth: [], pipeHeight: [], endTypes: [], stockno: '', mccsCode: '', pipeFamily: '', pipeFamilyId: '', pipeShape: '' },
  { id: 'NEW_ROW_12', isEdited: false, isCreated: false, isDeleted: false, sizeProperty: null, schedule: null, thickness: [], diameter: [], pipeWidth: [], pipeHeight: [], endTypes: [], stockno: '', mccsCode: '', pipeFamily: '', pipeFamilyId: '', pipeShape: '' },
  { id: 'NEW_ROW_13', isEdited: false, isCreated: false, isDeleted: false, sizeProperty: null, schedule: null, thickness: [], diameter: [], pipeWidth: [], pipeHeight: [], endTypes: [], stockno: '', mccsCode: '', pipeFamily: '', pipeFamilyId: '', pipeShape: '' },
  { id: 'NEW_ROW_14', isEdited: false, isCreated: false, isDeleted: false, sizeProperty: null, schedule: null, thickness: [], diameter: [], pipeWidth: [], pipeHeight: [], endTypes: [], stockno: '', mccsCode: '', pipeFamily: '', pipeFamilyId: '', pipeShape: '' },
  { id: 'NEW_ROW_15', isEdited: false, isCreated: false, isDeleted: false, sizeProperty: null, schedule: null, thickness: [], diameter: [], pipeWidth: [], pipeHeight: [], endTypes: [], stockno: '', mccsCode: '', pipeFamily: '', pipeFamilyId: '', pipeShape: '' },
];