import baseSchema from '../../modules/entities/utils/schema';

import EK from '../keys';

import PipeFamilySchema from '../PipeFamilies/schema';
import SizeSchema from '../Sizes/schema';

const bendSpringbackFamilySchema = baseSchema(EK.BEND_SPRINGBACKS.state, {
  pipeFamily: { schema: PipeFamilySchema, isId: true },
  bendSpringbackInstances: { isId: true, idField: 'bendSpringbackInstanceIds', isList: true },
});

export const bendSpringbackInstanceSchema = baseSchema(EK.BEND_SPRINGBACK_INSTANCES.state, {
  sizeProperty: { schema: SizeSchema, isId: true, originalField: 'size' },
  radius: { isComplexUnit: true },
  springBackLength: { isComplexUnit: true },
  elongationLength: { isComplexUnit: true },
  bendSpringbackFamily: { schema: bendSpringbackFamilySchema, isId: true },
});

bendSpringbackFamilySchema.define({ bendSpringbackInstances: [ bendSpringbackInstanceSchema ] });

export default bendSpringbackFamilySchema;
