import generateAPHTypes from '../../utils/constants/generateAPHTypes';

const resource = 'SECTIONS';

export const fetchAllSectionsTypes = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const createSectionTypes = generateAPHTypes(
  resource,
  'CREATE'
);

export const editSectionTypes = generateAPHTypes(
  resource,
  'edit'
);

export const saveSectionsTypes = generateAPHTypes(
  resource,
  'COLLECTION'
);
