import { createSelector } from 'reselect';

import selectEntities from '../../modules/entities/selectors';

import EK from '../keys';
import { formValueSelector } from 'redux-form/immutable';
import { selectSidebarIsFiltering } from '../../routes/Dashboard/selectors';

import { newFittingCategories } from './constants';

export const selectNormalizedFittingCategories = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.FITTING_CATEGORIES.state)
);

const fittingCategoryFilterForm = formValueSelector(`filters.${EK.FITTING_CATEGORIES.state}`);

export const selectCurrentFilteredNormalizedFittingCategories = () => createSelector(
  selectSidebarIsFiltering(),
  selectNormalizedFittingCategories(),
  state => fittingCategoryFilterForm(state, 'categoryName', 'symbolFile'),
  (isFiltering, fittingCategories, query) => isFiltering ?
    fittingCategories.filter(fittingCategory => fittingCategory.doesMatchQuery(query)).toList().toArray() :
    fittingCategories.toList().toArray().concat(newFittingCategories)
);

export const selectUnfilteredFittingCategoriesCount = () => createSelector(
  selectNormalizedFittingCategories(),
  (fittingCategories) => fittingCategories.toList().size
);

export const selectUnarchivedFittingCategoryOptions = () => createSelector(
  selectNormalizedFittingCategories(),
  cats => cats.filter(cat => !cat.archivedFlag)
);