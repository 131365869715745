import { getUnitLength } from '../../../entities/UnitSystems/model';

export const convertToBendInstanceType = ({
  id,
  sizeProperty,
  ratio,
  radius,
  minAngle,
  maxAngle,
  minRadius,
  maxRadius,
  isCreated=false,
  isEdited=false,
  isDeleted=false,
}) => {
  return {
    id,
    sizeProperty,
    ratio,
    radius,
    minAngle,
    maxAngle,
    minRadius,
    maxRadius,
    isCreated,
    isEdited,
    isDeleted,
  };
};

export const saveCurrentBendInstanceChanges = (
  changes,
  saveAction,
  currentUnitSystem,
) => {
  const collectionsdata = [];

  changes.forEach((item) => {
    if (item.isCreated) {
      collectionsdata.push({
        sizeId: item.sizeProperty && item.sizeProperty.id,
        ratio: item.ratio,
        radius: item.radius,
        minAngle: getUnitLength(item.minAngle, currentUnitSystem),
        maxAngle: getUnitLength(item.maxAngle, currentUnitSystem),
        minRadius: getUnitLength(item.minRadius, currentUnitSystem),
        maxRadius: getUnitLength(item.maxRadius, currentUnitSystem),
      });
    } else if (item.isDeleted) {
      collectionsdata.push({ id: item.id, _destroy: '1' });
    } else if (item.isEdited) {
      collectionsdata.push({
        id: item.id,
        sizeId: item.sizeProperty && item.sizeProperty.id,
        ratio: item.ratio,
        radius: item.radius,
        minAngle: getUnitLength(item.minAngle, currentUnitSystem),
        maxAngle: getUnitLength(item.maxAngle, currentUnitSystem),
        minRadius: getUnitLength(item.minRadius, currentUnitSystem),
        maxRadius: getUnitLength(item.maxRadius, currentUnitSystem),
      });
    }
  });

  collectionsdata.length > 0 && saveAction(collectionsdata);
};