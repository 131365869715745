import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form/immutable';

import selectEntities from '../../modules/entities/selectors';
import { selectSidebarIsFiltering } from '../../routes/Dashboard/selectors';
import EK from '../keys';
import { newSizesRows } from './constants';

export const selectNormalizedSizes = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.SIZES.state)
);

export const selectAllSizesOptions = () => createSelector(
  selectNormalizedSizes(),
  sizes => sizes.map(size => size.toOption()).toList()
);

const sizeFilterForm = formValueSelector(`filters.${EK.SIZES.state}`);

export const selectCurrentFilteredSizes = () => createSelector(
  selectSidebarIsFiltering(),
  selectNormalizedSizes(),
  state => sizeFilterForm(state, 'name', 'code', 'description'),
  (isFiltering, sizes, query) => {
    return isFiltering ? sizes.filter(size => size.doesMatchQuery(query)).toList().toArray() : sizes.toList().toArray().concat(newSizesRows);
  }
);

export const selectUnfilteredSizesCount = () => createSelector(
  selectNormalizedSizes(),
  (sizes) => sizes.toList().size
);