import { compose } from 'redux';
import { List } from 'immutable';

import EntityRecord, { VALIDATORS, FILTERS } from '../../modules/entities/model';
import hasMeasurements from '../../modules/entities/decorators/hasMeasurements';

import { PIPE_SHAPES } from '../PipeFamilies/model';

export const ALLOWANCES = {
  STOCKNO: /^(.{0,255})$/,
  MCCS_CODE: /^(.{0,255})$/,
};

class PipeInstance extends EntityRecord({
  sizeProperty: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'sizeId',
    validate: (entity) => VALIDATORS.IS_REQUIRED_ENTITY(entity.sizeProperty),
    validateError: 'A valid size must be present',
    filter: FILTERS.ENTITY,
  },
  schedule: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'scheduleId',
    validate: (entity) => VALIDATORS.IS_REQUIRED_ENTITY(entity.schedule),
    validateError: 'A valid schedule must be present',
    filter: FILTERS.ENTITY,
  },
  thickness: {
    value: List(),
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    isUnit: true,
    unitType: 'unitLength',
    validate: (entity) => VALIDATORS.IS_REQUIRED_VALID_UNIT(entity.thickness),
    validateError: 'A numeric thickness must be present',
  },
  diameter: {
    value: List(),
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    isUnit: true,
    unitType: 'unitLength',
    validate: (entity) =>
      entity.pipeShape !== PIPE_SHAPES.CIRCULAR ||
      (entity.pipeShape === PIPE_SHAPES.CIRCULAR &&
        VALIDATORS.IS_REQUIRED_VALID_UNIT(entity.diameter)),
    validateError: (entity) => {
      if (entity.pipeShape === PIPE_SHAPES.CIRCULAR) {
        return `A numeric diameter must be present because this pipe is ${PIPE_SHAPES.CIRCULAR}`;
      } else {
        return `A diameter should only be present for ${PIPE_SHAPES.CIRCULAR} pipes`;
      }
    },
  },
  pipeWidth: {
    value: List(),
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    isUnit: true,
    unitType: 'unitLength',
    validate: (entity) =>
      entity.pipeShape !== PIPE_SHAPES.RECTANGULAR ||
      (entity.pipeShape === PIPE_SHAPES.RECTANGULAR &&
        VALIDATORS.IS_REQUIRED_VALID_UNIT(entity.pipeWidth)),
    validateError: (entity) => {
      if (entity.pipeShape === PIPE_SHAPES.RECTANGULAR) {
        return `A numeric pipe width must be present because this pipe is ${PIPE_SHAPES.RECTANGULAR}`;
      } else {
        return `A pipe width should only be present for ${PIPE_SHAPES.RECTANGULAR} pipes`;
      }
    },
  },
  pipeHeight: {
    value: List(),
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    isUnit: true,
    unitType: 'unitLength',
    validate: (entity) =>
      entity.pipeShape !== PIPE_SHAPES.RECTANGULAR ||
      (entity.pipeShape === PIPE_SHAPES.RECTANGULAR &&
        VALIDATORS.IS_REQUIRED_VALID_UNIT(entity.pipeHeight)),
    validateError: (entity) => {
      if (entity.pipeShape === PIPE_SHAPES.RECTANGULAR) {
        return `A numeric pipe height must be present because this pipe is ${PIPE_SHAPES.RECTANGULAR}`;
      } else {
        return `A pipe height should only be present for ${PIPE_SHAPES.RECTANGULAR} pipes`;
      }
    },
  },
  endTypes: {
    value: List(),
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'endTypeIds',
    validate: (entity) => VALIDATORS.IS_REQUIRED_ENTITY_LIST(entity.endTypes),
    validateError: 'There must be at least one valid end type present',
  },
  stockno: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) =>
      !entity.stockno || ALLOWANCES.STOCKNO.test(entity.stockno),
    validateError:
      'Stockno must either be empty or be no longer than 255 characters',
    filter: FILTERS.STRING,
  },
  mccsCode: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) =>
      !entity.mccsCode || ALLOWANCES.MCCS_CODE.test(entity.mccsCode),
    validateError:
      'MCCS Code must either be empty or be no longer than 255 characters',
    filter: FILTERS.STRING,
  },
  pipeFamily: {
    value: null,
    isEntity: true,
    handleCopy: true,
  },
  pipeFamilyId: {
    value: null,
    handleCopy: true,
  },
  // used just to make our lives easier when editing pipe instances
  // this one property greatly helps manage normalization
  // pipeShape: {
  //   value: null,
  //   handleCopy: true,
  // },
}) {}

const enhance = compose(hasMeasurements);

export default enhance(PipeInstance);
