import { createSelector } from 'reselect';
import { Set } from 'immutable';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../../entities/keys';

import { ENTITY_STATUS } from '../../../modules/entities/constants';

import selectDrafts from '../../../modules/drafts/selectors';

import { selectNormalizedDraftParameters } from '../../../entities/Parameters/selectors';

import { selectSidebarIsFiltering } from '../../Dashboard/selectors';

export const selectCurrentDraftParametersPendingValidChanges = () => createSelector(
  selectDrafts(),
  drafts => drafts.getIn([EK.PARAMETERS.state, 'saveable']).size > 0
);

export const selectCurrentDraftParameterIds = () => createSelector(
  selectNormalizedDraftParameters(),
  drafts => drafts.reduce((ids, draft) => ids.add(draft.id), Set())
);

export const selectCurrentEditedDraftParametersCount = () => createSelector(
  selectNormalizedDraftParameters(),
  drafts => drafts.filter(draft => draft.status !== ENTITY_STATUS.LOCAL_DRAFT).size
);

const parameterFilterForm = formValueSelector(`filters.${EK.PARAMETERS.state}`);

export const selectCurrentFilteredNormalizedDraftParameters = () => createSelector(
  selectSidebarIsFiltering(),
  selectNormalizedDraftParameters(),
  state => parameterFilterForm(state, 'name', 'valueType', 'description'),
  (isFiltering, parameters, query) => isFiltering ? parameters.filter(parameter => parameter.doesMatchQuery(query)) : parameters
);