import { take, takeLatest, put, fork, all, select } from 'redux-saga/effects';
import get from 'lodash/get';

import { push } from 'connected-react-router';

import EK from '../../../entities/keys';

import { PipingModuleFittingsEditPath } from '../../../paths';

import {
  createFittingFamilyTypes,
  copyFittingFamilyTypes,
} from '../../../entities/FittingFamilies/constants';
import {
  selectNormalizedFittingFamilies
} from '../../../entities/FittingFamilies/selectors';

export function* handleCreateFittingFamilySuccess({ payload = {} }) {
  const id = `${get(payload, `${EK.FITTINGS.plural}[0].id`)}`;
  if (id) {
    let fittings = yield select(selectNormalizedFittingFamilies());
    let copiedFitting = fittings.get(id);

    while (!copiedFitting) {
      yield take();
      fittings = yield select(selectNormalizedFittingFamilies());
      copiedFitting = fittings.get(id);
    }

    yield put(push(PipingModuleFittingsEditPath.generate({ id })));

    yield put(hideModal());
  }
}

export function* watchCreateFittingSaga() {
  yield takeLatest([
    createFittingFamilyTypes.SUCCESS,
    copyFittingFamilyTypes.SUCCESS,
  ], handleCreateFittingFamilySuccess
  );
}

// final output saga
export default function* main() {
  yield all([
    fork(watchCreateFittingSaga),
  ]);
}