import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';

import Header from '../../templates/Structures/Header';
import Flex from '../../../components/common/Flex/index';
import Options from '../../templates/Structures/Options';
import { StandardizeModuleLayerStatesPath } from '../../../paths';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

import Button from '../../../components/common/Button/index';

import { createSelector } from 'reselect';

import {
  selectSidebarIsFiltering,
  selectStandardizeModulePermissionsAndState,
} from '../../Dashboard/selectors';

import { selectNormalizedLayerStatesList } from '../../../entities/LayerStates/selectors';

import { selectIsShowingArchived } from '../../../modules/query/selectors';
import { useDispatch, useSelector } from 'react-redux';

import {
  processCreateLayerState,
  processEditLayerState,
  processDeleteLayerState
} from '../../../entities/LayerStates/actions';
import Text from '../../../components/common/Text';

const mapStateToProps = createSelector(
  selectStandardizeModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectNormalizedLayerStatesList(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isShowingArchived,
    data,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      data: (!isLoading && data.toArray()) || [],
      editable: canCollaborate && hasValidLicense,
      hasValidLicense,
      canCollaborate,
    };
  },
);

export default function (props) {
  const reduxProps = useSelector(mapStateToProps);

  const dispatch = useDispatch();

  const emptyRow = {
    id: '',
    name: '',
    description: '',
  };

  const [row, setRow] = useState(emptyRow);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const newLayerState = () => {
    setRow(emptyRow);
    setIsCreateDialogOpen(true);
    setIsDialogOpen(true);
  };

  const editRow = (rowdata) => {
    let editRow = { id: rowdata.id, name: rowdata.name, description: rowdata.description };
    setRow(editRow);
    setIsDialogOpen(true);
  };

  const cancelDialog = () => {
    !isCreateDialogOpen || setIsCreateDialogOpen(false);
    setIsDialogOpen(false);
  };

  const submitNewLayerState = () => {
    if (reduxProps.editable) {
      dispatch(processCreateLayerState(row));
      cancelDialog();
    } else {
      cancelDialog();
    }
  };

  const submitRowEdit = () => {
    //dispatches event to update row, the useSelector will update itself if the object it is pointed at changes
    if (reduxProps.editable && row.id) {
      dispatch(processEditLayerState(row.id, row));
      cancelDialog();
    } else {
      cancelDialog();
    }
  };

  const deleteItemDialog = (rowdata) => {
    let deleteRow = { id: rowdata.id, name: rowdata.name, description: rowdata.description };
    setRow(deleteRow);
    setIsDeleteDialogOpen(true);
  };

  const cancelDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const deleteItem = () => {
    dispatch(processDeleteLayerState(row.id));
    setIsDeleteDialogOpen(false);
  };

  const onInputChange = (e, name) => {
    //copies the rowdata, then replaces just the correct field with the changed input value
    const val = (!!e.target && e.target.value) || '';
    let _row = { ...row };
    _row[`${name}`] = val;
    setRow(_row);
  };

  //actions for each row
  const actionBodyTemplate = (rowdata) => {
    return (
      <>
        <Button icon="edit" onClick={() => editRow(rowdata)} />
        <Button icon="list" onClick={() => editRow(rowdata)} />
        <Button icon="delete" onClick={() => deleteItemDialog(rowdata)} />
      </>
    );
  };

  //footers call different methods, two dispatches depending on new/update row
  const newRowFooter = () => (
    <>
      <Button secondary={true} onClick={cancelDialog} >Cancel</Button>
      <Button primary={true} onClick={submitNewLayerState} >Save</Button>
    </>
  );

  const editRowFooter = () => (
    <>
      <Button secondary={true} onClick={cancelDialog} >Cancel</Button>
      <Button primary={true} onClick={submitRowEdit} >Save</Button>
    </>
  );

  //uses state to select the correct save action
  let dialogFooter = !isCreateDialogOpen ? editRowFooter : newRowFooter;

  const deleteDialogFooter = () => (
    <>
      <Button secondary={true} onClick={cancelDeleteDialog} >Cancel</Button>
      <Button error={true} onClick={deleteItem} >Delete</Button>
    </>
  );

  return (
    <>
      <Flex flexDirection="row" mb={4}>
        <Header
          isLoading={reduxProps.isLoading}
          title={StandardizeModuleLayerStatesPath.defaultTitle}
          subtitle={`${reduxProps.data.length || 0} ${(!!reduxProps.data.length && reduxProps.data.length > 1) || !reduxProps.data.length ? 'Layer States' : 'Layer State'} Total`}
        />
        <Options
          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
          isLoading={reduxProps.isLoading}
          createEntity={newLayerState}
        />
      </Flex>
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable value={reduxProps.data} tableStyle={{ minWidth: '50rem' }} size='small' scrollable scrollHeight='flex' >
          <Column field='name' header="Name" style={{ width: '25%' }} ></Column>
          <Column field='description' header='Description' style={{ width: '55%' }} ></Column>
          <Column body={actionBodyTemplate} style={{ width: '20%', textAlign: 'right' }} ></Column>
        </DataTable>
      </Flex>
      <Dialog visible={ reduxProps.editable && isDialogOpen} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={isCreateDialogOpen ? 'Create Layer State' : 'Edit Layer State'} footer={dialogFooter} modal className="p-fluid" onHide={cancelDialog} closable={false} >
        <div className="field">
          <label htmlFor="name" className="font-bold">Name</label>
          <InputText id="name" value={row.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus />
        </div>
        <div className="field">
          <label htmlFor="description" className="font-bold">Description</label>
          <InputTextarea id="description" value={row.description} onChange={(e) => onInputChange(e, 'description')} required rows={3} cols={20} />
        </div>
      </Dialog>
      <Dialog visible={ reduxProps.editable && isDeleteDialogOpen} style={{ width: '32rem' }} header="Delete Layer State" footer={deleteDialogFooter} modal className='p-fluid' onHide={cancelDeleteDialog} closable={false} >
        <div>
          <Text>Are you sure you want to delete {row.name}?</Text>
          <Text style={{ color: 'red' }}>This action will be PERMANENT and CANNOT BE UNDONE.</Text>
        </div>
      </Dialog>
    </>
  );
}