import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { List } from 'immutable';

import Flex from '../../common/Flex';

import MultiSearchSelect from '../../form/MultiSearchSelect';
import MultiEntitySearchSelect from '../../../containers/form/MultiEntitySearchSelect';

import renderMultiOptionForSelect from '../../form/BaseOption/utils/renderMultiOptionForSelect';

const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;

const MultiDropdownEditor = React.forwardRef(({
  isNullable,
  keyPress,
  value,
  placeholder,
  options,
  ek,
  eFilter,
  stopEditing,
  column,
  renderOption,
  renderValue,
  defaultValue = List(),
  isCapitalized,
  preventFiltering,
}, ref) => {
  const inputRef = useRef();

  useImperativeHandle(ref, () => ({
    isPopup: () => false,
    afterGuiAttached: () => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    },
    getValue: () => currentValue === null || currentValue === undefined || currentValue.size === 0 ? defaultValue : currentValue,
  }));

  const initialValue = useMemo(() => {
    let startValue = List();

    if (isNullable && (keyPress === KEY_BACKSPACE || keyPress === KEY_DELETE)) {
      // if backspace or delete pressed, clear the cell
      startValue = List();
    } else {
      startValue = ek !== undefined ? value.map(v => v.id) : value.map(v => v.value);
    }

    return startValue;
  }, []);

  const [isReady, setIsReady] = useState(false);

  const [currentValue, setCurrentValue] = useState(initialValue);

  // prevent initial render from ruining life
  useEffect(() => {
    if (!isReady) { setIsReady(true); }
  }, []);

  const onOuterClick = useCallback(_ => stopEditing(), [stopEditing]);

  const SearchComponent = ek !== undefined ? MultiEntitySearchSelect : MultiSearchSelect;

  return (
    <Flex flexDirection='column' justifyContent='center' height='100%' cursor='pointer' pl={3} pr={3}>
      <SearchComponent
        ref={inputRef}
        placeholder={placeholder}
        value={currentValue}
        options={options}
        ek={ek}
        eFilter={eFilter}
        onChange={setCurrentValue}
        width={column.actualWidth}
        itemHeight={56}
        renderOption={renderOption || renderMultiOptionForSelect}
        renderValue={renderValue}
        onOuterClick={onOuterClick}
        isCapitalized={isCapitalized}
        preventFiltering={preventFiltering}
      />
    </Flex>
  );
});

export default MultiDropdownEditor;
