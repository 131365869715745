import { toast } from 'react-toastify';

import EK from '../keys';

import api from '../../modules/api/actions';
import { REQUEST_TYPES } from '../../modules/api/constants';

import {
  fetchAllOrientationsTypes,
  createOrientationTypes,
  saveOrientationsTypes,
} from './constants';
import schema from './schema';

const client = api(schema, EK.ORIENTATIONS);

export const processFetchAllOrientations = () =>
  client(
    REQUEST_TYPES.GET,
    'plural',
    fetchAllOrientationsTypes,
    '/standardize/orientations'
  );

export const processCreateOrientation = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    createOrientationTypes,
    '/standardize/orientations',
    {
      data: {
        orientations: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Orientation successfully created',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to create orientation',
      },
    }
  );

export const processSaveOrientations = (data) =>
  client(
    REQUEST_TYPES.PATCH,
    'plural',
    saveOrientationsTypes,
    '/standardize/orientations/collection?diff_result=true',
    {
      data: { orientationsAttributes: data },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Changes saved',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to save changes',
      },
    }
  );
