import React from 'react';

import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';

import EK from '../../../entities/keys';

import { PARAMETER_VALUE_TYPE_OPTIONS } from '../../../entities/Parameters/model';

import BaseFormRow from '../../../components/form/BaseFormRow';

import FilterFormTemplate from '../../templates/Structures/FilterFormTemplate';

import RDXTextInput from '../../../containers/form/RDXTextInput';
import RDXRadioSelect from '../../../containers/form/RDXRadioSelect';

const FilterForm = props => (
  <FilterFormTemplate { ...props }>
    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name='name'
        label='Parameter Name'
        type='text'
      />
    </BaseFormRow>
    <BaseFormRow>
      <Field
        component={RDXRadioSelect}
        name='valueType'
        label='Value Type'
        options={PARAMETER_VALUE_TYPE_OPTIONS}
        clearable
      />
    </BaseFormRow>
    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name='description'
        label='Parameter Description'
        type='text'
      />
    </BaseFormRow>
  </FilterFormTemplate>
);

const enhance = compose(
  reduxForm({ form: `filters.${EK.PARAMETER_PROFILE_PARAMETERS.state}` })
);

export default enhance(FilterForm);
