import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

// datatable imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import MaterialIconFolder from '../../../components/icons/material/Folder';
import MaterialIconFolderOpen from '../../../components/icons/material/FolderOpen';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

// dialog imports
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import Text from '../../../components/common/Text';

// header imports
import Header from '../../templates/Structures/Header';
import Flex from '../../../components/common/Flex/index';
import Options from '../../templates/Structures/Options';
import { FrameworkModuleProfileLibrariesPath } from '../../../paths';

// misc imports
import Button from '../../../components/common/Button/index';

// selector and redux imports
import { createSelector } from 'reselect';
import {
  selectSidebarIsFiltering,
  selectFrameworkModulePermissionsAndState,
} from '../../Dashboard/selectors';
import { selectNormalizedProfileLibrariesList } from '../../../entities/ProfileLibraries/selectors';
import { selectNormalizedProfileForLibraryList } from '../../../entities/Profiles/selectors';
import { selectIsShowingArchived } from '../../../modules/query/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { selectUnitSystemsOptions } from '../../../entities/UnitSystems/selectors';

//small components (they tend to cause clutter)
import {
  rowExpansionTemplate
} from './components';

//entity crud actions, all require framework catalog ID
import {
  processCreateProfileLibrary, // requires name, folderName?
  processDeleteProfileLibrary, // requires profile library id
  processEditProfileLibrary,   // requires id, all changed fields?
} from '../../../entities/ProfileLibraries/actions';

import {
  processCreateProfile, // requires profileLibraryID and profiles to be created in a list of profile objects
  processFetchProfiles,   // requires profileLibraryID
  processDeleteProfile, // requires profileID, profileLibraryID
  processEditProfile,   // requires profileID, profileLibraryID
} from '../../../entities/Profiles/actions';

const mapStateToProps = createSelector(
  selectFrameworkModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectNormalizedProfileLibrariesList(),
  selectUnitSystemsOptions(),
  selectNormalizedProfileForLibraryList(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isShowingArchived,
    data,
    unitSystems,
    profiles,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      data: (!isLoading && data.toArray()) || [],
      unitSystems: unitSystems.toArray() || [],
      editable: canCollaborate && hasValidLicense,
      hasValidLicense,
      canCollaborate,
      profiles: profiles.toArray() || []
    };
  },
);

function ProfileLibrariesBody(props) {
  const reduxProps = useSelector(mapStateToProps);

  const dispatch = useDispatch();

  const blankLibrary = {
    id: '',
    name: '',
    folderName: '',
    description: '',
    unitSystemId: '',
  };

  const blankProfile = {
    profileLibraryId: '',
    id: '',
    name: '',
    fileName: '',
    description: '',
    unitSystemId: null,
  };

  //dialog state variables
  const [row, setRow] = useState(blankProfile);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false);
  const [isProfileLibraryDialogOpen, setIsProfileLibraryDialogOpen] = useState(false);
  const [isDeleteProfile, setIsDeleteProfile] = useState(false);

  const [expandedRows, setExpandedRows] = useState(null);

  const editProfileLibrary = (rowdata) => {
    let editRow = {
      id: rowdata.id,
      name: rowdata.name,
      folderName: rowdata.folderName,
      description: rowdata.description,
      unitSystemId: rowdata.unitSystem,
    };
    setRow(editRow);
    setIsProfileLibraryDialogOpen(true);
  };

  const editProfile = (rowdata) => {

    let editRow = {
      profileLibrary: rowdata.profileLibrary,
      id: rowdata.id,
      name: rowdata.name,
      fileName: rowdata.fileName,
      defaultValue: rowdata.defaultValue,
      description: rowdata.description,
      unitSystemId: rowdata.unitSystem,
    };

    setRow(editRow);
    setIsProfileDialogOpen(true);
  };

  const openNewProfileDialog = (libraryId) => {
    let newBlankProfile = blankProfile;
    newBlankProfile.profileLibraryId = libraryId;
    setRow(newBlankProfile);
    setIsCreateDialogOpen(true);
    setIsProfileDialogOpen(true);
  };

  const openNewLibraryDialog = () => {
    setRow(blankLibrary);
    setIsCreateDialogOpen(true);
    setIsProfileLibraryDialogOpen(true);
  };

  const cancelDialog = () => {
    !isCreateDialogOpen || setIsCreateDialogOpen(false);
    !isProfileDialogOpen || setIsProfileDialogOpen(false);
    !isProfileLibraryDialogOpen || setIsProfileLibraryDialogOpen(false);
  };

  const deleteItemDialog = (rowdata) => {
    let deleteRow = { id: rowdata.id, name: rowdata.name, description: rowdata.description };
    !rowdata.folderName ? setIsDeleteProfile(true) : deleteRow.folderName = rowdata.folderName;
    setRow(deleteRow);
    setIsDeleteDialogOpen(true);
  };

  const cancelDeleteDialog = () => {
    isDeleteProfile && setIsDeleteProfile(false);
    setIsDeleteDialogOpen(false);
  };

  const deleteItem = () => {
    row.folderName ? dispatch(processDeleteProfileLibrary(row.id)) : dispatch(processDeleteProfile(row.id));
    setIsDeleteProfile(false);
    setIsDeleteDialogOpen(false);
  };

  const onInputChange = (e, name) => {
    //copies the rowdata, then replaces just the correct field with the changed input value
    const val = (!!e.target && e.target.value) || '';
    let _row = { ...row };
    _row[`${name}`] = val;
    setRow(_row);
  };

  //actions for each row
  const actionBodyTemplate = (rowdata) => {
    return (
      <>
        <Button icon="edit" onClick={() => editProfileLibrary(rowdata)} />
        <Button icon="delete" onClick={() => deleteItemDialog(rowdata)} />
      </>
    );
  };

  const deleteDialogFooter = () => (
    <>
      <Button secondary={true} onClick={cancelDeleteDialog} >Cancel</Button>
      <Button error={true} onClick={deleteItem} >Delete</Button>
    </>
  );

  const profileDialogFooter = () => (
    <>
      <Button secondary={true} onClick={cancelDialog} >Cancel</Button>
      <Button primary={true} onClick={() => { dispatch(isCreateDialogOpen ? processCreateProfile(row.profileLibraryId, [row]) : processEditProfile(row)); cancelDialog(); } } >Save</Button>
    </>
  );

  const libraryDialogFooter = () => (
    <>
      <Button secondary={true} onClick={cancelDialog} >Cancel</Button>
      <Button primary={true} onClick={() => { dispatch(isCreateDialogOpen ? processCreateProfileLibrary(row) : processEditProfileLibrary(row.id, row)); cancelDialog(); }} >Save</Button>
    </>
  );

  let dialogFooter = isProfileDialogOpen ? profileDialogFooter : libraryDialogFooter;

  const checkForExpandedRows = (data) => {
    data.id && !reduxProps.profiles.find(({ profileLibrary }) => profileLibrary == data.id) && dispatch(processFetchProfiles(data.id));
  };

  const getExpandedRows = (data) => {
    return ({
      id: data.id,
      name: data.name,
      profiles: reduxProps.profiles.filter( ({profileLibrary}) => profileLibrary == data.id )
    });
  };

  return (
    <>
      <Flex flexDirection="row" mb={4}>
        <Header
          isLoading={reduxProps.isLoading}
          title={FrameworkModuleProfileLibrariesPath.defaultTitle}
          subtitle={`${reduxProps.data.length || 0} ${(!!reduxProps.data.length && reduxProps.data.length > 1) || !reduxProps.data.length ? 'Profile Libraries' : 'Profile Library'} Total`}
        />
        <Options
          isLoading={reduxProps.isLoading}
          createEntity={openNewLibraryDialog}
          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
        />
      </Flex>
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable
          reorderableColumns
          value={reduxProps.data}
          tableStyle={{ minWidth: '50rem' }}
          size='small'
          scrollable
          scrollHeight="flex"

          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data) }
          onRowExpand={(e) => checkForExpandedRows(e.data)}
          rowExpansionTemplate={(data) => rowExpansionTemplate(getExpandedRows(data), dispatch, openNewProfileDialog, editProfile, deleteItemDialog )}
          collapsedRowIcon={<MaterialIconFolder size='24' style={{ fill: 'rgb(134, 142, 150)'}} />}
          expandedRowIcon={<MaterialIconFolderOpen size='24' style={{ fill: 'rgb(134, 142, 150)' }} />}
          dataKey='id'
        >
          <Column expander={true} style={{ width: '5rem', textAlign: 'left' }}></Column>
          <Column field='name' header="Name" style={{ width: '20%' }} ></Column>
          <Column field='folderName' header='Folder' style={{ width: '20%' }} ></Column>
          <Column field='description' header='Description' style={{ width: '40%' }} ></Column>
          <Column body={actionBodyTemplate} style={{ textAlign: 'right' }} ></Column>
        </DataTable>
      </Flex>
      <Dialog visible={reduxProps.editable && isProfileLibraryDialogOpen} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={isCreateDialogOpen ? 'Create Profile Library' : 'Edit Profile Library'} footer={dialogFooter} modal className="p-fluid" onHide={cancelDialog} closable={false} >
        <div className="field">
          <label htmlFor="name" className="font-bold">Name</label>
          <InputText id="name" value={row.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus />
        </div>
        <div className="field">
          <label htmlFor={'folderName'} className="font-bold">Folder Name</label>
          <InputText id={'folderName'} onChange={(e) => onInputChange(e, 'folderName')} value={row.folderName} required />
        </div>
        <div className='field'>
          <label htmlFor='unitSystemId' className='font-bold'>Unit System</label>
          <Dropdown value={row.unitSystemId} onChange={(e) => onInputChange(e, 'unitSystemId')} options={reduxProps.unitSystems} />
        </div>
        <div className="field">
          <label htmlFor="description" className="font-bold">Description</label>
          <InputTextarea id="description" value={row.description} onChange={(e) => onInputChange(e, 'description')} required rows={3} cols={20} />
        </div>
      </Dialog>
      <Dialog visible={reduxProps.editable && isProfileDialogOpen} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={isCreateDialogOpen ? 'Create Profile' : 'Edit Profile'} footer={profileDialogFooter} modal className="p-fluid" onHide={cancelDialog} closable={false} >
        <div className="field">
          <label htmlFor="name" className="font-bold">Name</label>
          <InputText id="name" value={row.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus />
        </div>
        <div className="field">
          <label htmlFor={'fileName'} className="font-bold">File Name</label>
          <InputText id={'fileName'} onChange={(e) => onInputChange(e, 'fileName')} value={row.fileName} required />
        </div>
        <div className='field'>
          <label htmlFor='unitSystemId' className='font-bold'>Unit System</label>
          <Dropdown value={row.unitSystemId} onChange={(e) => onInputChange(e, 'unitSystemId')} options={reduxProps.unitSystems || []} />
        </div>
        <div className="field">
          <label htmlFor="description" className="font-bold">Description</label>
          <InputTextarea id="description" value={row.description} onChange={(e) => onInputChange(e, 'description')} required rows={3} cols={20} />
        </div>
      </Dialog>
      <Dialog visible={reduxProps.editable && isDeleteDialogOpen} style={{ width: '32rem' }} header={isDeleteProfile ? 'Delete Profile' : 'Delete Profile Library' } footer={() => deleteDialogFooter('library')} modal className='p-fluid' onHide={cancelDeleteDialog} closable={false} >
        <div>
          <Text>Are you sure you want to delete {row.name}?</Text>
          <Text style={{ color: 'red' }}>This action will be PERMANENT and CANNOT BE UNDONE.</Text>
        </div>
      </Dialog>
    </>
  );
}

export default withRouter(ProfileLibrariesBody);