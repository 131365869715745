/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

// Grid Imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

// cell bodies/editors
import Text from '../../../components/common/Text';
import colors from '../../../assets/themes/base/colors';
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import EntityRowTools from '../../../components/common/EntityTools/RowStartTools';

// Header
import Flex from '../../../components/common/Flex';
import { PipingModuleSpecsPipesEditPath } from '../../../paths';
import Header from '../../templates/Structures/Header';
import Options from '../../SpecsEditSwitch/Options';
import Skeleton from 'react-loading-skeleton';
import { convertToSpecPipeFamilyType, saveCurrentPipingSpecPipeFamiliesChanges } from './actions';
import { pluralize } from '../../../components/common/Header';

// validation
import { rowStyles } from '../../../components/primeGrid/RowStyles';

// actions
import { processSaveSpecPipeInstances } from '../../../entities/SpecPipeInstances/actions';
import { processFetchAllPipeFamilies, processFetchPipeFamiliesForSpec } from '../../../entities/PipeFamilies/actions';
import { processFetchAllMaterials } from '../../../entities/Materials/actions';
import { processEditSpec, processFetchAllSpecs, processFetchIndividualSpec } from '../../../entities/Specs/actions';

// selectors
import { selectPipingModulePermissionsAndState, selectSidebarIsFiltering } from '../../Dashboard/selectors';
import { removeDuplicateFromObjectIdArray, selectCurrentSpec, selectOrderedFilteredUnarchivedPipeFamilies } from '../../../entities/Specs/selectors';
import Button from '../../../components/common/Button';
import { selectisPrioritizedFamilySortingEnabled } from '../../SpecsEditSwitch/selectors';
import Icon from '../../../components/common/Icon';
import { selectDenormalizedPipeInstances } from '../../../entities/PipeInstances/selectors';

const mapStateToProps = (specId) => createSelector(
  selectPipingModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectOrderedFilteredUnarchivedPipeFamilies(specId),
  selectCurrentSpec(specId),
  selectisPrioritizedFamilySortingEnabled(),
  selectDenormalizedPipeInstances(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    data,
    spec,
    isPrioritizedFamilySortingEnabled,
    instances,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.length == 0));
    return {
      ...rest,
      isFiltering,
      isLoading,
      canCollaborate,
      hasValidLicense,
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense,
      count: spec?.specPipeInstances && removeDuplicateFromObjectIdArray(spec.specPipeInstances.toArray().map(item => item.pipeFamilyId)).length || 0,
      spec,
      isPrioritizedFamilySortingEnabled,
      instances,
    };
  },
);

function SpecPipeFamiliesGrid({ match, location }) {
  const reduxProps = useSelector(mapStateToProps(match.params.id));
  const dispatch = useDispatch();

  // editing state
  const [editedRows, setEditedRows] = useState({});
  const [editedOrder, setEditedOrder] = useState([]);
  const [orderedRows, setOrderedRows] = useState(reduxProps.data);
  const [pendingValidChanges, setPendingValidChanges] = useState(true);

  // reorder funcs
  const handleOnRowReorder = (e) => {
    setEditedOrder(e.value);
    setOrderedRows(e.value);
  };

  const rowCanReorder = (rowdata) => {
    const curRow = rowdata && (editedRows && editedRows[rowdata.id] || rowdata);
    return !curRow.isPrioritized && 'hide-row-reorder-button';
  };

  //Cell displays
  const materialBody = (rowdata, field, placeholder) => {
    const curRow = rowdata && (editedRows && editedRows[rowdata.id] || rowdata);

    return curRow && curRow[field] ?
      <ComplexCellRenderer value={curRow[field]} isCapitalized={true} paddingLeft='0px' /> :
      <Text className='text-cell-body' style={{ color: colors.gray[5] }}>{placeholder}</Text>;
  };

  const textValueBody = (rowdata, field, placeholder = '') => {
    const curRow = rowdata && (editedRows && editedRows[rowdata.id] || rowdata);

    return curRow && curRow[`${field}`] ?
      <Text >{curRow[`${field}`]}</Text> :
      <Text style={{ color: colors.gray[5], whiteSpace: 'nowrap' }} >{placeholder}</Text>;
  };

  const instanceBody = () => <Text>placeholder</Text>;

  // cell actions
  const handlePrioritizedRowsChange = (pipeFamily) => {
    let _orderedRows = [...orderedRows];
    const curItem = _orderedRows.findIndex(item => item.id == pipeFamily.id);
    const _curRow = _orderedRows[curItem];
    switch (pipeFamily.isPrioritized) {
    case true:
      curItem == -1 && _orderedRows.push(pipeFamily);
      break;
    case false:
      curItem != -1 && _orderedRows.splice(curItem, 1);
      _orderedRows.push(_curRow);
      break;
    }
    setOrderedRows(_orderedRows);
  };

  // this editedRow uses an {id: pipeFamily} structure
  const toggleForPriority = (pipeFamily) => {
    let _editedRows = {...editedRows};
    const newFamily = { ...convertToSpecPipeFamilyType(pipeFamily), isPrioritized: !pipeFamily.isPrioritized, isEdited: true };
    _editedRows[pipeFamily.id] = newFamily;
    setEditedRows(_editedRows);
    handlePrioritizedRowsChange(newFamily);
    setPendingValidChanges(false);
  };

  const toggleForSpec = (pipeFamily) => {
    if (pipeFamily.pipeInstances.size <= 0) {
      console.log('no instances');
      return;
    }

    let _editedRows = { ...editedRows };
    const newFamily = { ...pipeFamily, isSelectedForSpec: pipeFamily.pipeInstances.size > 0 && !pipeFamily.isSelectedForSpec, isEdited: true };
    _editedRows[pipeFamily.id] = newFamily;

    setEditedRows(_editedRows);
    handlePrioritizedRowsChange(newFamily);
    setPendingValidChanges(false);
  };

  // Cell Editors
  const SelectItemForPriorityCellBody = ({ pipeFamily }) => <Button icon='star' iconFillColor={pipeFamily.isPrioritized ? 'primary.4' : 'gray.4'} onClick={() => toggleForPriority(pipeFamily)} ></Button>;
  const SelectItemForSpecCellBody = ({ pipeFamily }) => <Button icon={pipeFamily.isSelectedForSpec ? 'checkbox' : 'checkbox-outline'} iconFillColor={pipeFamily.isSelectedForSpec ? 'primary.4' : 'gray.6'} onClick={() => toggleForSpec(pipeFamily)} ></Button>;

  const PipingSpecsRowTool = (rowdata) => {
    const curRow = rowdata && (editedRows && editedRows[rowdata.id] || rowdata);

    if (curRow) {
      return reduxProps.isPrioritizedFamilySortingEnabled ?
        // eslint-disable-next-line react/no-unknown-property
        <SelectItemForPriorityCellBody pipeFamily={curRow} /> :
        <SelectItemForSpecCellBody pipeFamily={curRow} />;
    }
  };

  const handleRowAction = (item, iconName) => {
    console.log(item, iconName);
    let _rows = {...editedRows};
    switch (iconName) {
    case 'undo':
      delete _rows[item.id];
      break;
    }
    setEditedRows(_rows);
  };

  const getRowTool = (rowdata) => {
    const data = rowdata && (editedRows && editedRows[rowdata.id] || rowdata);
    return (
      <EntityRowTools rowdata={data} handleRowAction={handleRowAction} />
    );
  };

  // save action

  const saveAction = (collectionsData) => {
    editedOrder != orderedRows && dispatch(processEditSpec(match.params.id, { id: match.params.id, prioritized_pipe_families: JSON.stringify(editedOrder.filter(fam => fam.isPrioritized))}));
    collectionsData.length > 0 && dispatch(processSaveSpecPipeInstances(match.params.id, collectionsData));
  };

  console.log(reduxProps.data);

  useEffect(() => {
    dispatch(processFetchAllMaterials());
    dispatch(processFetchPipeFamiliesForSpec());
    dispatch(processFetchAllSpecs());
    dispatch(processFetchIndividualSpec(match.params.id));
  }, []);


  useEffect(() => {
    let _data = removeDuplicateFromObjectIdArray(editedOrder.concat(reduxProps.data));
    if (editedRows) {
      for (const [key, value] of Object.entries(editedRows)) {
        const dataItemIndex = _data.findIndex(item => item.id == key);
        if(dataItemIndex != -1) {
          if (value.isSelectedForSpec) {
            _data.splice(dataItemIndex, 1, value);
          }else {
            _data.splice(dataItemIndex, 1);
          }
        } else {
          _data.push(value);
        }
      }
    }
    setOrderedRows(_data || reduxProps.data);
  }, [reduxProps.isLoading, reduxProps.isPrioritizedFamilySortingEnabled]);

  return (
    <>
      {!reduxProps.isLoading && <Flex flexDirection="row" mb={4} >
        <Header
          isLoading={reduxProps.isLoading}
          title={(reduxProps.spec?.specName && `${reduxProps.spec.specName} // ${PipingModuleSpecsPipesEditPath.defaultTitle}`) || 'Loading...'}
          subtitle={`${reduxProps.data.length} ${pluralize('Selected Pipe', reduxProps.data.length)} ${reduxProps.isFiltering ? `Showing ${reduxProps.data.length}/${reduxProps.spec.prioritizedPipeFamilies.count} ${pluralize('filtered Pipe', reduxProps.data.length)}` : '' }`}
        />
        <Options
          location={location}
          specId={(match.params.id)}
          updateEntities={() => saveCurrentPipingSpecPipeFamiliesChanges(editedRows, reduxProps.instances, reduxProps.spec.specPipeInstances.toArray(), saveAction)}
          pendingValidChanges={Object.keys(editedRows)?.length > 0}
        />
      </Flex> || <Skeleton style={{ height: '2rem', marginBottom: '1rem' }} />
      }
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable
          reorderableRows={reduxProps.isPrioritizedFamilySortingEnabled}
          onRowReorder={handleOnRowReorder}
          value={orderedRows}
          tableStyle={{ minWidth: '50rem' }}
          size='normal'

          scrollable
          scrollHeight='flex'

          removableSort
        >
          <Column header='' field='id' body={getRowTool} ></Column>
          {reduxProps.isPrioritizedFamilySortingEnabled && <Column rowReorder bodyClassName={rowCanReorder} ></Column>}
          <Column header='' style={{ width: '2%' }} body={PipingSpecsRowTool} ></Column>
          <Column field='name' style={{ width: '32%' }} header='Name' body={(rowdata) => textValueBody(rowdata, 'name', '')} ></Column>
          <Column field='instances' header={'Instances'} body={(rowdata) => instanceBody(rowdata)} style={{ width: '32%' }} ></Column>
          <Column field='material' header={'Material'} body={(rowdata) => materialBody(rowdata, 'material', 'Material')} style={{ width: '32%' }} ></Column>
        </DataTable>
      </Flex>
    </>
  );
}

// { !reduxProps.isPrioritizedFamilySortingEnabled && <Column header='' style={{ width: '3rem' }} body={(rowdata) => getRowTool(rowdata)} ></Column> }
// { reduxProps.isPrioritizedFamilySortingEnabled && <Column rowReorder={(e) => console.log(e)} style={{ width: '3rem' }} ></Column> }

//rowReorderIcon={({ props, element, iconProps }) => { console.log(props, iconProps, element); return props.rowData.isPrioritized && React.createElement(Icon, {...props, ...iconProps, name: 'list', color: 'gray.5', className: 'p-icon p-datatable-reorderablerow-handle'}) || ''; }}

export default withRouter(SpecPipeFamiliesGrid);