import { List } from 'immutable';
import { compose } from 'redux';
import { pluralize } from '../../components/common/Header';

import EntityRecord, { FILTERS } from '../../modules/entities/model';
import hasMeasurements from '../../modules/entities/decorators/hasMeasurements';

export const ALLOWANCES = {
  NAME: /^([a-zA-Z0-9_\- ]{0,255})$/,
};

export const VALIDATION_FIELDS = {
  name: true,
  material: true,
  crossSectionShape: true,
  lineType: true
};

// used for default values and options list
export const PIPE_SHAPES = {
  circular: { value: 'circular', label: 'Circular' },
  rectangular: { value: 'rectangular', label: 'Rectangular' },
};

export const LINE_TYPES = {
  flexible: {value: 'flexible', label: 'Flexible'},
  rigid: {value: 'rigid', label: 'Rigid'},
};

export const CROSS_SECTION_SHAPE_OPTIONS = [
  {
    id: PIPE_SHAPES.circular.value,
    ...PIPE_SHAPES.circular
  },
  {
    id: PIPE_SHAPES.rectangular.value,
    ...PIPE_SHAPES.rectangular
  },
];

export const LINE_TYPE_OPTIONS = [
  {
    id: LINE_TYPES.flexible.value,
    ...LINE_TYPES.flexible
  },
  {
    id: LINE_TYPES.rigid.value,
    ...LINE_TYPES.rigid
  },
];

export const BlankPipe = {
  name: '',
  material: '',
  crossSectionShape: '',
  lineType: '',
};

class PipeFamily extends EntityRecord({
  name: {
    value: null,
    handleCopy: (entity) => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  archivedFlag: {
    value: false,
  },
  minLength: {
    value: List(),
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    isUnit: true,
    unitType: 'unitLength',
  },
  maxLength: {
    value: List(),
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    isUnit: true,
    unitType: 'unitLength',
  },
  maxOverallLength: {
    value: List(),
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    isUnit: true,
    unitType: 'unitLength',
  },
  minBends: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
  },
  maxBends: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
  },
  crossSectionShape: {
    value: PIPE_SHAPES.circular.value,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  lineType: {
    value: LINE_TYPES.rigid.value,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  unitSystem: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'unitSystemId',
  },
  material: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'materialId',
    filter: FILTERS.ENTITY,
  },
  pipeInstances: {
    value: List(),
    isEntity: true,
    handleCopy: true,
    handleDeepCopy: true,
    handleCompare: true,
    handleSerializeCollection: true,
    handleDeepSerialize: true,
    serializeOutputField: 'pipeInstancesAttributes',
  },
  selectedPipeInstancesForSpec: {
    value: 0,
  },
  isSelectableForPriority: {
    value: false,
  },
  isSelectedForPriority: {
    value: false,
  },
  prioritizationState: {
    value: null,
  },
}) {
  get primary() {
    return this.code;
  }
  get primaryPlaceholder() {
    return 'No Name';
  }
  get secondary() {
    return `${this.pipeInstances.size} ${
      pluralize(
        'instance',
        this.pipeInstances.size,
      )
    }`;
  }
  get secondaryPlaceholder() {
    return '--';
  }

  get instancesCount() {
    const count = (this.pipeInstances && this.pipeInstances.size) || 0;
    return `${count} ${pluralize('instance', count)}`;
  }

  get summary() {
    return {
      primary: this.name,
      tertiary: this.instancesCount,
    };
  }

  isCircular() {
    return this.crossSectionShape === PIPE_SHAPES.circular.value;
  }
  isRectangular() {
    return this.crossSectionShape === PIPE_SHAPES.rectangular.value;
  }
}

const enhance = compose(hasMeasurements);

export default enhance(PipeFamily);
