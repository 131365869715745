import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';

import Text from '../../../components/common/Text';
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import DialogFooter from '../../../components/modal/ModalFooters/DialogFooter';
import DeleteDialogFooter from '../../../components/modal/ModalFooters/DeleteDialogFooter';

import {
  ALLOWANCES,
  CROSS_SECTION_SHAPE_OPTIONS,
  LINE_TYPE_OPTIONS,
} from '../../../entities/PipeFamilies/model';
import { processFetchAllUnitSystems } from '../../../entities/UnitSystems/actions';
import { processFetchAllMaterials } from '../../../entities/Materials/actions';

import { selectNormalizedMaterials } from '../../../entities/Materials/selectors';
import { selectUnitSystems } from '../../../entities/UnitSystems/selectors';

const mapStateToProps = createSelector(
  selectNormalizedMaterials(),
  selectUnitSystems(),
  (materials, unitSystems) => ({
    unitSystems: unitSystems?.toList().toArray() || [],
    materials: materials?.toList().toArray() || []
  })
);

export const PipeDialog = ({ currentEditingRow, editable, defaultUnitSystem, getDialogHeader, handleEditedRowChange, isCreateDialogOpen = false, isCopyDialogOpen = false, isDialogOpen, openDeleteDialog=false, saveAction, cancelDialogs, validation, getUnitLength }) => {
  const {materials, unitSystems} = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  useEffect( () => {
    dispatch(processFetchAllUnitSystems());
    dispatch(processFetchAllMaterials());
  }, []);

  return (
    <Dialog visible={editable && isDialogOpen} style={{ width: '75%' }} header={() => getDialogHeader()} footer={() => DialogFooter(isCreateDialogOpen, isCopyDialogOpen, openDeleteDialog, saveAction, cancelDialogs)} modal className='p-fluid' closable={false} >
      <div>
        <label>Name</label>
        <InputText keyfilter={ALLOWANCES.NAME} value={currentEditingRow.name} onChange={(e) => handleEditedRowChange(e.target.value, 'name')} className={!validation.name && 'p-invalid'} />
      </div>
      <div style={{ width: '50%', float: 'left', paddingRight: '5px' }} >
        <label>Cross Section Shape</label>
        <Dropdown
          optionLabel='label'
          value={currentEditingRow.crossSectionShape}
          options={CROSS_SECTION_SHAPE_OPTIONS}
          onChange={(e) => handleEditedRowChange(e.target.value, 'crossSectionShape')}
          itemTemplate={(item) => ComplexCellRenderer({
            value: { primary: item.label }, placeholder: '', isCapitalized: false
          })}
          filter
          className={!validation.crossSectionShape && 'p-invalid'}
        />
      </div>
      <div style={{ width: '50%', float: 'right' }} >
        <label>Line Type</label>
        <Dropdown
          optionLabel='label'
          value={currentEditingRow.lineType}
          options={LINE_TYPE_OPTIONS}
          onChange={(e) => handleEditedRowChange(e.target.value, 'lineType')}
          itemTemplate={(item) => ComplexCellRenderer({
            value: { primary: item.label }, placeholder: '', isCapitalized: false
          })}
          filter
          className={!validation.lineType && 'p-invalid'}
        />
      </div>
      <div style={{ width: '50%', float: 'left', paddingRight: '5px' }} >
        <label>Material</label>
        <Dropdown
          optionLabel='code'
          value={currentEditingRow.material}
          options={materials}
          onChange={(e) => handleEditedRowChange(e.target.value, 'material')}
          itemTemplate={(item) => ComplexCellRenderer({
            value: { primary: item.code, secondary: item.description }, placeholder: '', isCapitalized: false
          })}
          filter
          className={!validation.material && 'p-invalid'}
        />
      </div>
      <div style={{ width: '50%', float: 'right' }} >
        <label>Unit System</label>
        <Dropdown
          optionLabel='name'
          value={currentEditingRow.unitSystem || defaultUnitSystem}
          options={unitSystems}
          onChange={(e) => handleEditedRowChange(e.target.value, 'unitSystem')}
          itemTemplate={(item) => ComplexCellRenderer({
            value: { primary: item.primary, secondary: item.secondary }, placeholder: '', isCapitalized: false
          })}
          filter
        />
      </div>
      <div style={{ width: '100%', display: 'flex' }} >
        <div style={{ width: '33%', float: 'left', paddingRight: '5px' }} >
          <label>Min Segment Length</label>
          <span className='p-input-icon-right' >
            <Text as='i' >{currentEditingRow.unitSystem?.unitLength?.abbreviation || defaultUnitSystem.unitLength?.abbreviation || ''}</Text>
            <InputNumber value={getUnitLength(currentEditingRow.minLength)} onChange={(e) => handleEditedRowChange(e.value, 'minLength')} minFractionDigits={1} maxFractionDigits={5} />
          </span>
        </div>
        <div style={{ width: '33%' }} >
          <label>Max Segment Length</label>
          <span className='p-input-icon-right' >
            <Text as='i' >{currentEditingRow.unitSystem?.unitLength?.abbreviation || defaultUnitSystem.unitLength?.abbreviation || ''}</Text>
            <InputNumber value={getUnitLength(currentEditingRow.maxLength)} onChange={(e) => handleEditedRowChange(e.value, 'maxLength')} minFractionDigits={1} maxFractionDigits={5} />
          </span>
        </div>
        <div style={{ width: '33%', float: 'right', paddingLeft: '5px' }} >
          <label>Max Overall Length</label>
          <span className='p-input-icon-right' >
            <Text as='i' >{currentEditingRow.unitSystem?.unitLength?.abbreviation || defaultUnitSystem.unitLength?.abbreviation || ''}</Text>
            <InputNumber value={getUnitLength(currentEditingRow.maxOverallLength)} onChange={(e) => handleEditedRowChange(e.value, 'maxOverallLength')} minFractionDigits={1} maxFractionDigits={5} />
          </span>
        </div>
      </div>
      <div style={{ width: '50%', float: 'left', paddingRight: '5px' }} >
        <label>Minimum Number of Bends</label>
        <InputNumber value={currentEditingRow.minBends} onChange={(e) => handleEditedRowChange(e.value, 'minBends')} maxFractionDigits={0} />
      </div>
      <div style={{ width: '50%', float: 'right' }} >
        <label>Maximum Number of Bends</label>
        <InputNumber value={currentEditingRow.maxBends} onChange={(e) => handleEditedRowChange(e.value, 'maxBends')} maxFractionDigits={0} />
      </div>
    </Dialog>
  );
};

export const DeletePipeDialog = ({ editable, isDeleteDialogOpen, currentEditingRow, setIsDeleteDialogOpen, deleteAction }) =>
  <Dialog visible={editable && isDeleteDialogOpen} style={{ width: '32rem' }} header={`Delete Pipe Family '${currentEditingRow.name}'`} footer={() => DeleteDialogFooter(() => setIsDeleteDialogOpen(false), deleteAction)} closable={false} >
    <div>
      <Text>Are you sure you want to delete {currentEditingRow.name}?</Text>
      <Text style={{ color: 'red' }}>This action will be PERMANENT and CANNOT BE UNDONE.</Text>
      <Text><strong>Only delete this if you are certain that it needs to be removed from everywhere</strong></Text>
    </div>
  </Dialog>;