import { List } from 'immutable';

import EntityRecord, { VALIDATORS, FILTERS } from '../../modules/entities/model';

export const MATERIAL_OPTIONS = [
  {
    id: 'pipe',
    value: 'pipe',
    label: 'Pipe',
    primary: 'Pipe',
    disabled: false,
  },
  {
    id: 'fitting',
    value: 'fitting',
    label: 'Fitting',
    primary: 'Fitting',
    disabled: false,
  },
  {
    id: 'insulation',
    value: 'insulation',
    label: 'Insulation',
    primary: 'Insulation',
    disabled: false,
  },
];

export const ALLOWANCES = {
  CODE: /^(.{0,255})$/,
  FILENAME: /^(.{0,2048})$/,
};

class Material extends EntityRecord(
  {
    code: {
      value: null,
      handleCopy: (entity) => `${entity.code} COPY`,
      handleCompare: true,
      handleSerialize: true,
      validate: (entity) =>
        VALIDATORS.IS_REQUIRED_STRING(entity.code) &&
        ALLOWANCES.CODE.test(entity.code),
      validateError: 'A valid code must be present',
      filter: FILTERS.STRING,
    },
    description: {
      value: null,
      handleCopy: true,
      handleCompare: true,
      handleSerialize: true,
      filter: FILTERS.STRING,
    },
    filename: {
      value: null,
      handleCopy: true,
      handleCompare: true,
      handleSerialize: true,
      validate: (entity) =>
        !entity.filename || ALLOWANCES.FILENAME.test(entity.filename),
      validateError:
        'Filename must either be empty or be no longer than 2048 characters',
      filter: FILTERS.STRING,
    },
    // TODO: move this over to the new converted unit standard
    density: {
      value: null,
      handleCopy: true,
      handleCompare: true,
      handleSerialize: true,
      validate: (entity) =>
        VALIDATORS.IS_NUMERIC(entity.density) &&
        VALIDATORS.IS_GREATER_THAN_ZERO(entity.density),
      validateError: 'A valid density must be present',
    },
    defaultUnitSystem: {
      value: false,
      handleCopy: true,
    },
    unitSystem: {
      value: null,
      isEntity: true,
      handleCopy: true,
      // handleCompare: true,
      handleSerialize: true,
      serializeOutputField: 'unitSystemId',
    },
    isValidForPipe: {
      value: false,
      handleCopy: true,
      handleCompare: true,
      handleSerialize: true,
    },
    isValidForFitting: {
      value: false,
      handleCopy: true,
      handleCompare: true,
      handleSerialize: true,
    },
    isValidForInsulation: {
      value: false,
      handleCopy: true,
      handleCompare: true,
      handleSerialize: true,
    },
  },
  {
    multiFieldValidations: {
      materialTypes: {
        validate: (entity) =>
          entity.isValidForPipe ||
          entity.isValidForFitting ||
          entity.isValidForInsulation,
        validateError:
          'A material must be useable for a pipe, fitting, or insulation',
      },
    },
  }
) {
  get primary() {
    return this.code;
  }
  get primaryPlaceholder() {
    return 'No Code';
  }
  get secondary() {
    return this.description;
  }
  get secondaryPlaceholder() {
    return '--';
  }

  get materialOptions() {
    const pipeUseCount = this.warnDeleteCounts.pipeFamilies;
    const fittingUseCount = this.warnDeleteCounts.fittingFamilies;
    const insulationUseCount = this.restrictDeleteCounts.insulationInstances;

    return [
      {
        id: 'pipe',
        value: 'pipe',
        label: `Pipe${(pipeUseCount && ' (in use)') || ''}`,
        primary: `Pipe${(pipeUseCount && ' (in use)') || ''}`,
        disabled: !!pipeUseCount,
      },
      {
        id: 'fitting',
        value: 'fitting',
        label: `Fitting${(fittingUseCount && ' (in use)') || ''}`,
        primary: `Fitting${(fittingUseCount && ' (in use)') || ''}`,
        disabled: !!fittingUseCount,
      },
      {
        id: 'insulation',
        value: 'insulation',
        label: `Insulation${(insulationUseCount && ' (in use)') || ''}`,
        primary: `Insulation${(insulationUseCount && ' (in use)') || ''}`,
        disabled: !!insulationUseCount,
      },
    ];
  }

  get materialTypes() {
    return [
      ...(this.isValidForPipe
        ? [
          {
            id: 'pipe',
            value: 'pipe',
            label: 'Pipe',
            primary: 'Pipe',
            disabled: !!this.warnDeleteCounts.pipeFamilies,
          },
        ]
        : []),
      ...(this.isValidForFitting
        ? [
          {
            id: 'fitting',
            value: 'fitting',
            label: 'Fitting',
            primary: 'Fitting',
            disabled: !!this.warnDeleteCounts.fittingFamilies,
          },
        ]
        : []),
      ...(this.isValidForInsulation
        ? [
          {
            id: 'insulation',
            value: 'insulation',
            label: 'Insulation',
            primary: 'Insulation',
            disabled: !!this.restrictDeleteCounts.insulationInstances,
          },
        ]
        : []),
    ];
  }

  setMaterialTypes(types) {
    return this.set('isValidForPipe', types.includes('pipe'))
      .set('isValidForFitting', types.includes('fitting'))
      .set('isValidForInsulation', types.includes('insulation'));
  }
}

export const MATERIAL_FILTERS = {
  PIPE: (material) => material.isValidForPipe,
  FITTING: (material) => material.isValidForFitting,
  INSULATION: (material) => material.isValidForInsulation,
};

export default Material;
