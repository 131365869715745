import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Notice from '../../../containers/common/Notice';

import { selectIsFetching } from '../../../modules/utility/selectors';

import { selectNormalizedModelTemplatesList } from '../../../entities/ModelTemplates/selectors';

const mapStateToProps = createStructuredSelector({
  isFetching: selectIsFetching(),
  data: selectNormalizedModelTemplatesList(),
});

const enhance = compose(connect(mapStateToProps));

const ModelTemplatesNotices = ({ isFetching, data }) =>
  (isFetching && data && data.size > 0 && (
    <Notice
      id="modelTemplates"
      message="Loading data in the background..."
    />
  )) ||
  null;

export default enhance(ModelTemplatesNotices);
