import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { matchPath } from 'react-router-dom';
import BreadcrumbTemplate from '../../templates/Structures/Breadcrumb';

import { PipingModuleFittingsEditPath } from '../../../paths';
import { selectCurrentFittingFamily } from '../../../entities/FittingFamilies/selectors';

function BendEditBread({ location }) {
  const match = matchPath(location.pathname, { path: PipingModuleFittingsEditPath.url });
  const fittingId = (match && match.params.id) || null;
  const fittingFamily = useSelector(selectCurrentFittingFamily(fittingId));

  return <BreadcrumbTemplate title={(fittingFamily && fittingFamily.name) || PipingModuleFittingsEditPath.defaultTitle} />;
}

export default withRouter(BendEditBread);
